import React from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { button } from '../../helpers/Classes';
export default function BreadcrumbBack(props: {
  title: string;
  children?: any;
  loading?: boolean;
  key?: string;
  HideArrow?: boolean;
}) {
  const navigate = useNavigate();
  return (
    <div className="p-4 dark:bg-dark-purple1 bg-gray-200 gap-5  rounded-lg flex flex-wrap flex-row sm:flex-row md:flex-row lg:items-center justify-between ">
      <div className="flex gap-3">
        {props.HideArrow ? null : (
          <IoArrowBack
            onClick={() => navigate(-1)}
            className="text-3xl hover:cursor-pointer bg-gray-500 rounded-full p-1 shadow-lg text-white "
          />
        )}
        <p className={`text-2xl font-bold`}>
          {props.title} <sub className="text-md"></sub>
        </p>
      </div>
      <div className="gap-2 flex flex-row  flex-wrap justify-end  ">
        {props.children}
      </div>
    </div>
  );
}
