import React, { useEffect, useState } from 'react';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteEmptyAdminBatchStudents,
  deleteMultipleAdminBatchStudents,
  getAllBatchStudents,
  showAdminBatch,
} from '../../../service/admin/adminBatch.service';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';
import TableButton from '../../../components/button/TableButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Models from '../../../components/models/Models';
import AdminStudentCE from '../students/AdminStudentCE';
import { CSVDownload } from '../../../helpers/FileDownloader';
import { StudentSampleData } from '../../../helpers/sampleData';
import ExcelUpload from '../../../components/uploads/ExcelUpload';
import { generateRandomString } from '../../../helpers/commonHelper';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';

export default function AdminBatchStudents() {
  const [pageLoad, setpageLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [batchData, setBatchData] = useState<any>();
  const [refresh, setRefresh] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const id: any = useParams();

  const navigate = useNavigate();

  const fetchBatchData = async (id: string) => {
    const get: any = await showAdminBatch(id);
    console.log(get);
    if (get?.status === 'success') {
      setBatchData(get?.message);
    }
  };
  useEffect(() => {
    fetchBatchData(id?.id);
  }, []);
  const handleItems = (data: any) => {
    if (data.length > 0) {
      setSelectedRow(data);
    } else {
      setSelectedRow([]);
    }
  };
  const handleMultipleDelete = () => {
    if (selectedRow.length > 0) {
      Swal.fire({
        title: 'Are you sure want to remove all selected students?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del: any = await deleteMultipleAdminBatchStudents(
            id.id,
            selectedRow
          );
          if (del.status === 'success') {
            setRefresh(!refresh);
            setSelectedRow([]);
            toast.success(del.message);
          }
        }
      });
    } else {
      toast.error('Please Select  student');
    }
  };
  const handleEmptyBatch = async () => {
    if (selectedRow.length > 0) {
      Swal.fire({
        title: 'Are you sure want to remove all students  from this batch?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del: any = await deleteEmptyAdminBatchStudents(id.id);
          if (del.status === 'success') {
            setRefresh(!refresh);
            setSelectedRow([]);
            toast.success(del.message);
          }
        }
      });
    } else {
      toast.error('Please Select  student');
    }
  };
  const handleSampleDownload = () => {
    let data = [];
    for (let i = 0; i <= 1000; i++) {
      data.push({
        S_no: i + 1,
        Name: i + 'Sample User',
        Phone: generateRandomString(10, true),
        Email: i + 'sample@demo.in',
        DOB: '2002-12-12',
        Gender: 'Male',
      });
    }
    CSVDownload('student-sample-download', data);
  };
  const handleFileUpload = () => {
    setOpenUpload(!openUpload);
  };
  const handleDownload = async () => {
    let get: any = await getAllBatchStudents(id.id);
    if (get?.status === 'success') {
      if (get?.message.length > 0) {
        let newData: any = [];
        get?.message.map((e: any, i: number) => {
          newData.push({
            'S No': i + 1,
            Name: e.student.name,
            Email: e.student.email,
            Phone: e.student.phone,
            DOB: moment(e.student.dob).format('YYYY-MM-DD'),
            Gender: e.student?.gender?.name,
          });
        });
        CSVDownload(`${batchData?.name}'s students`, newData);
      } else {
        return toast.error('No students found for download');
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>{`Batch Students | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`${batchData?.name}'s Students`}>
        <CustomButton
          title="Add Students"
          onClick={() => navigate(`/admin/batches/students/add/${id?.id}`)}
          icon={true}
          iconName="create"
        />
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create New Student"
          icon={true}
          iconName="create"
          bg="bg-purple-800"
        />
        {selectedRow.length > 0 ? (
          <>
            <CustomButton
              onClick={handleMultipleDelete}
              title="Delete Students"
              icon={true}
              iconName="delete"
              bg="bg-red-600"
            />
            <CustomButton
              onClick={handleEmptyBatch}
              title="Empty Batch"
              icon={true}
              iconName="delete"
              bg="bg-red-600"
            />
          </>
        ) : null}
      </BreadcrumbBack>
      <div className="my-2">
        <div className="my-2 'p-4 dark:bg-dark-purple1  gap-5  rounded-lg flex flex-wrap flex-row sm:flex-row md:flex-row lg:items-center justify-start lg:ml-5">
          <div className="gap-2 flex flex-row  flex-wrap justify-end mr-5  ">
            <TableButton
              onClick={handleDownload}
              icon={true}
              title="download"
              text="Batch Student"
              toottip="Download Excel"
            />
            <TableButton
              onClick={handleFileUpload}
              text="Upload Student"
              icon={true}
              title="upload"
              toottip="Upload Excel"
            />
            <TableButton
              onClick={handleSampleDownload}
              text="Download Sample"
              icon={true}
              title="excel"
              toottip="Download Sample Excel"
            />
          </div>
        </div>
        <PaginationDataGrid
          name="AdminbatchStudents"
          refresh={refresh}
          onSubmit={handleItems}
          checkbox={true}
          id={id.id}
        />
      </div>
      <Models name="Create New Student " isOpen={open} isClose={setOpen}>
        <AdminStudentCE type="create" isOpen={setOpen} />
      </Models>
      <Models
        name="Upload Batch Student"
        isOpen={openUpload}
        isClose={setOpenUpload}
      >
        <ExcelUpload
          name="AdminBatchStudentUpload"
          accept=".csv"
          data={id}
          open={setOpenUpload}
        />
      </Models>
    </>
  );
}
