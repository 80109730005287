import React, { useEffect, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { Spinner } from '@material-tailwind/react';
export default function VimeoPlayer(props: {
  id: any;
  lastWatch?: any;
  watchTime?: any;
}) {
  const [loading, setLoading] = useState(true);
  const [uri, setUri] = useState<any>(null);
  const handleLoading = (data: any) => {
    if (data.id) {
      setLoading(false);
    }
  };
  useEffect(() => {
    setUri(props.id.split('/')[props.id.split('/').length - 1]);
  }, []);
  const handleError = (data: any) => {
    setLoading(false);
  };
  console.log(uri);

  return (
    <div className="flex flex-row items-center w-full justify-center ">
      <div className={`${loading === false ? ' hidden' : 'block'} `}>
        <Spinner />
      </div>
      {uri !== null ? (
        <Vimeo
          onError={handleError}
          className={`${loading ? ' hidden' : 'block'} w-full h-auto`}
          video={uri}
          autoplay
          responsive
          onLoaded={handleLoading}
        />
      ) : null}
    </div>
  );
}
