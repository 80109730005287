import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormButton from '../../../../components/button/FormButton';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import { editAdminWhatsappCredentials, showAdminWhatsappCredentials } from '../../../../service/admin/adminSetting.service';
import Loaders from '../../../../components/loaders/Loaders';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';


export default function AdminSettingWhatsappEdit(props:{open:any, data:any}) {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [fetchData,setFetchData] = useState<any>();

  const fetch = async()=>{
      setPageLoading(true);
      let get:any = await showAdminWhatsappCredentials(props.data);
      if(get?.status === 'success'){
        setPageLoading(false);
        setFetchData(get?.message);
      }
      setPageLoading(false);
  }
  useEffect(() =>{
    fetch();
  },[])
  const dispatch = useDispatch()
  const { handleSubmit, control } = useForm<any>({
    values:{
      phone_number: fetchData?.phone_number,
      pid: fetchData?.p_id,
      phone: fetchData?.phone_id,
      token: fetchData?.token
    }
  });
    const onSubmit: SubmitHandler<any> = async (
      data: any
    ) => {
 setLoading(true);
      let cr:any = await editAdminWhatsappCredentials(props.data,data.phone_number,data.pid,data.phone,data.token)
      if(cr?.status === "success"){
        setLoading(false);
        props.open(false)
           dispatch(
                      setContentUpdate({
                        id: Math.random() * (100000 - 111111),
                      })
                    );  
        return toast.success(cr?.message)
       
      }
      setLoading(false);
    };

  return (
    pageLoading ?
      <Loaders type='spinner' />
    :
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
      <Input
            name="phone_number"
            type="tel"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="Whatsapp Phone"
            placeholder="phone"
          />
            <Input
            name="pid"
            type="text"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="maytapi pid"
            placeholder="maytapi pid"
          />
            <Input
            name="phone"
            type="text"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="maytapi Phone Id"
            placeholder="maytapi phone id"
          />
          <Input
            name="token"
            type="text"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="maytapi Token"
            placeholder="maytapi token"
          />
        <FormButton loading={loading} title={"Submit"} />
      </form>
    </div>
  )
}
 