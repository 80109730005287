import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../components/button/CustomButton';
import Models from '../../../../components/models/Models';
import AdminCouponMasterCreate from './AdminCouponMasterCreate';
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid';

export default function AdminCouponMaster() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>{`Coupon Master | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`Coupon Master `}>
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create coupon master"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <PaginationDataGrid name="adminCouponMaster" />
      </div>
      <Models name="Create Coupon Master" isOpen={open} isClose={setOpen}>
        <AdminCouponMasterCreate
          type="create"
          open={setOpen}
          data={{ id: '', master: '' }}
        />
      </Models>
    </>
  );
}
