import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createAdminTestSeriesModules,
  editAdminTestSeriesModules,
  showAdminTestSeriesModules,
} from '../../../service/admin/adminTestSeries.service';
import { SubmitHandler, useForm } from 'react-hook-form';
import { statusList, toBoolean } from '../../../helpers/helpers';
import { setContentUpdate } from '../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import RadioButton from '../../../components/form/RadioButton';
import FormButton from '../../../components/button/FormButton';

export default function AdminTestSeriesModulesCE(props: {
  type: string;
  data?: any;
  open: any;
}) {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [url, setUrl] = useState<any>();
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] =
    useState<any>(false);
  const [pageContent, setPageContent] = useState({
    button: 'Create Course Menu',
  });

  const fetchBatchDetail = async (id: string) => {
    const get: any = await showAdminTestSeriesModules(id);
    if (get?.status === 'success') {
      setUrl(get.message?.image);
      setFetchData(get.message);
    }
  };
  useEffect(() => {
    setPageLoad(true);
    if (props.type === 'edit') {
      fetchBatchDetail(props.data.id);
      setPageContent({
        button: 'Update Course Master',
      });
    }
    // fetchInstituteCenter()
    setPageLoad(false);
  }, []);

  const { handleSubmit, control } = useForm<any>({
    values: {
      name: fetchData?.name,
      status: `${fetchData?.status ? fetchData?.status : true}`,
    },
  });
  const onSubmit: SubmitHandler<any> = async (data: any) => {
    setLoading(true);
    let status_: any = toBoolean(data.status);

    let fetch: any;
    data.status = status_;
    if (props.type === 'create') {
      fetch = await createAdminTestSeriesModules(data.name, status_);
    }
    if (props.type === 'edit') {
      fetch = await editAdminTestSeriesModules(
        props.data.id,
        data.name,
        status_
      );
    }
    if (fetch?.status === 'success') {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <Input
          name="name"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Name"
          placeholder="Name"
        />
        <div className="">
          <RadioButton
            name="status"
            control={control}
            rules={{ ...formValidationRules.required() }}
            options={statusList}
            label="status"
          />
        </div>
        <br />
        <FormButton loading={loading} title={pageContent.button} />
      </form>
    </>
  );
}
