import React, { useEffect, useState } from 'react';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';
import Models from '../../../components/models/Models';
import AdminBatchCE from './AdminBatchCE';
import { showAdminBatch } from '../../../service/admin/adminBatch.service';
import { Helmet } from 'react-helmet-async';

export default function AdminBatch() {
  const [pageLoad, setPageLoad] = useState(false);
  const [showData, setShowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Helmet>
        <title>{`Batches | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title="Batches">
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create New Batch"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <PaginationDataGrid name="adminBatches" />
      </div>
      <Models name="Create New Center" isOpen={open} isClose={setOpen}>
        <AdminBatchCE type="create" open={setOpen} />
      </Models>
    </>
  );
}
