import { createSlice } from '@reduxjs/toolkit';

const initialState: any = [
  {
    id: null,
    name: null,
    section: null,
    status: null,
    class: null,
    param1: null,
    param2: null,
    param3: null,
    param4: null,
    param5: null,
  },
];

const courseUpdateSlice: any = createSlice({
  name: 'courseUpdateslice',
  initialState,
  reducers: {
    setUpdatedCourseData: (state: any, action: any) => {
      return action.payload;
    },
  },
});
export const { setUpdatedCourseData } = courseUpdateSlice.actions;
export default courseUpdateSlice.reducer;
