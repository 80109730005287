import { Suspense, lazy, useEffect } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Login from './pages/Login';

import { useSelector } from 'react-redux';
import SuperAdmin from './pages/superAdmin/SuperAdmin';
import SuperAdminDashboard from './pages/superAdmin/SuperAdminDashboard';
import SuperAdminCenters from './pages/superAdmin/centers/SuperAdminCenters';
import SuperAdminCenterCreate from './pages/superAdmin/centers/SuperAdminCenterCreate';
import SuperAdminCenterCE from './pages/superAdmin/centers/SuperAdminCenterCE';
import SuperAdmin_admin from './pages/superAdmin/admins/SuperAdmin_admin';
import AdminLogin from './pages/admins/AdminLogin';
import Admins from './pages/admins/Admins';
import AdminDashboard from './pages/admins/AdminDashboard';
import AdminLoginPasswordUpdate from './pages/admins/AdminLoginPasswordUpdate';
import AdminBatch from './pages/admins/batch/AdminBatch';
import AdminCourses from './pages/admins/courses/AdminCourses';
import AdminStudents from './pages/admins/students/AdminStudents';
import AdminStudentCE from './pages/admins/students/AdminStudentCE';
import AdminBatchStudents from './pages/admins/batch/AdminBatchStudents';
import AdminBatchAddStudentToBatch from './pages/admins/batch/AdminBatchAddStudentToBatch';
import AdminCourseMaster from './pages/admins/courses/AdminCourseMaster';
import AdminCourseCategory from './pages/admins/courses/courseCategory/AdminCourseCategory';
import AdminCourseCE from './pages/admins/courses/AdminCourseCE';
import AdminVideos from './pages/admins/videos/AdminVideos';
import AdminVideoCE from './pages/admins/videos/AdminVideoCE';
import AdminVideoVimeo from './pages/admins/videos/vimeo/AdminVideoVimeo';
import AdminVimeoAccounts from './pages/admins/videos/vimeo/adminVimeoAccounts/AdminVimeoAccounts';
import VimeoModules from './pages/admins/videos/videoModules/VimeoModules';
import VideoSubModules from './pages/admins/videos/videoSubModules/VideoSubModules';
import VimeoSubModules from './pages/admins/videos/videoModules/subModules/VimeoSubModules';
import AdminVideoList from './pages/admins/videos/AdminVideoList';
import AdminVideoSubMOduleAdd from './pages/admins/videos/AdminVideoSubMOduleAdd';
import AdminTestSeriesModules from './pages/admins/testSeries/AdminTestSeriesModules';
import AdminTestSubModules from './pages/admins/testSeries/submodules/AdminTestSubModules';
import AdminQuestions from './pages/admins/testSeries/questions/AdminQuestions';
import AdminQuestionCE from './pages/admins/testSeries/questions/AdminQuestionCE';
import AdminLiveClass from './pages/admins/liveClass/AdminLiveClass';
import AdminLiveClassAccount from './pages/admins/liveClass/liveClassAccounts/AdminLiveClassAccount';
import AdminLiveClassSubMod from './pages/admins/liveClass/liveClassSubModule/AdminLiveClassSubMod';
import AdminLiveClassCE from './pages/admins/liveClass/AdminLiveClassCE';
import AdminInstallments from './pages/admins/installments/AdminInstallments';
import AdminExamAlert from './pages/admins/examAlert/AdminExamAlert';
import AdminFeeds from './pages/admins/feeds/AdminFeeds';
import AdminPromotional from './pages/admins/promotional/AdminPromotional';
import IndexPublic from './pages/public/IndexPublic';
import LoginPublic from './pages/public/LoginPublic';
import StudentLoginPasswordUpdate from './pages/students/StudentLoginPasswordUpdate';
import StudentForgetPassword from './pages/students/StudentForgetPassword';
import StudentRegister from './pages/students/StudentRegister';
import Student from './pages/students/Student';
import StudentDashboard from './pages/students/StudentDashboard';
import StuCourseMaster from './pages/students/courses/StuCourseMaster';
import StuCourses from './pages/students/courses/StuCourses';
import VideoNSubModules from './pages/admins/videos/videoSubModules/videoNsubModules/VideoNSubModules';
import CourseDetails from './pages/students/courses/CourseDetails';
import StudentSubscription from './pages/students/subscription/StudentSubscription';
import StudentLiveClass from './pages/students/liveClass/StudentLiveClass';
import StudentVideo from './pages/students/videos/StudentVideo';
import StudentVideoModules from './pages/students/videos/StudentVideoModules';
import StudentVideoSubModules from './pages/students/videos/StudentVideoSubModules';
import StudentVideosList from './pages/students/videos/StudentVideosList';
import StudentVideoPlay from './pages/students/videos/StudentVideoPlay';
import StudentTestSeriesCourse from './pages/students/testSeries/StudentTestSeriesCourse';
import StudentTestSeries from './pages/students/testSeries/StudentTestSeries';
import StudentTestSeriesModules from './pages/students/testSeries/StudentTestSeriesModules';
import StudentTestSeriesNmodules from './pages/students/testSeries/StudentTestSeriesNmodules';
import StudentResultBasic from './pages/students/testSeries/StudentResultBasic';
import AdminGroupTestSeries from './pages/admins/testSeries/submodules/groupTestSeries/AdminGroupTestSeries';
import AdminGroupTestQuestions from './pages/admins/testSeries/submodules/groupTestSeries/AdminGroupTestQuestions';
import AdminGroupTestQuestionsCE from './pages/admins/testSeries/submodules/groupTestSeries/AdminGroupTestQuestionsCE';
import StudentExamAlert from './pages/students/examAlert/StudentExamAlert';
import StudentProfile from './pages/students/StudentProfile';
import AdminProfile from './pages/admins/profile/AdminProfile';
import AdminStudentProfile from './pages/admins/students/AdminStudentProfile';
import PhonePayTest from './pages/public/PhonePayTest';
import CourseCategoryTab from './pages/admins/courses/courseCategoryTab/CourseCategoryTab';
import CourseCategoryTab_Courses from './pages/admins/courses/courseCategoryTab/CourseCategoryTab_Courses';
import AdminCouponMaster from './pages/admins/courses/couponMaster/AdminCouponMaster';
import AdminCouponCourses from './pages/admins/courses/couponMaster/couponCourses/AdminCouponCourses';
import AdminPayments from './pages/admins/payments/AdminPayments';
import CourseCheckout from './pages/students/courses/CourseCheckout';
import PaymentConfirmation from './pages/payments/PaymentConfirmation';
import HoldPayments from './pages/students/courses/HoldPayments';
import AdminPendingPayments from './pages/admins/payments/AdminPendingPayments';
import PluginError from './pages/error/PluginError';
import AdminSetting from './pages/admins/setting/AdminSetting';
import AdminSettingWhatsapp from './pages/admins/setting/whatsappSetup/AdminSettingWhatsapp';
import StudentPayInstallments from './pages/students/subscription/StudentPayInstallments';
import PaymentInstallmentConfirmation from './pages/payments/PaymentInstallmentConfirmation';
import Error409 from './pages/error/Error409';

const TeacherZoomMeeting = lazy(
  () => import('./components/zoomMeeting/TeacherZoom')
);
const StudentZoomMeeting = lazy(
  () => import('./components/zoomMeeting/StudentZoom')
);
function App() {
  const isAdmin = useSelector((state: any) => state.loginSlice.isSchoolLogin);
  const isInstitte = useSelector(
    (state: any) => state.loginSlice.isInstituteLogin
  );
  const isStudent = useSelector(
    (state: any) => state.loginSlice.isStudentLogin
  );

  const checkForIDM = (): void => {
    const videos = document.getElementsByTagName('video');
    if (videos.length > 0 && videos[0].hasAttribute('__idm_id__')) {
      window.location.href = '/error-idm';
    }
  };
  useEffect(() => {
    checkForIDM();
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense>
        <Routes>
          <Route path="error-idm" element={<PluginError />} />
          <Route
            path="/payments/status/:method/:txtId"
            element={<PaymentConfirmation />}
          />
            <Route
            path="/error/already-login"
            element={<Error409/>}
          />
           <Route
            path="/payments/installments/status/:method/:txtId"
            element={<PaymentInstallmentConfirmation />}
          />
          <Route path="/phonepay" element={<PhonePayTest />} />
          <Route path="/" element={<LoginPublic />} />
          <Route
            path="/super-admin"
            element={isInstitte ? <SuperAdmin /> : <LoginPublic />}
          >
            <Route path="dashboard" element={<SuperAdminDashboard />} />
            <Route path="centers" element={<SuperAdminCenters />} />
            <Route path="admins" element={<SuperAdmin_admin />} />
          </Route>
          <Route
            path="/admin/login-password/update"
            element={<AdminLoginPasswordUpdate />}
          />
          <Route path="/admin" element={isAdmin ? <Admins /> : <LoginPublic />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="batches" element={<AdminBatch />} />
            <Route
              path="batches/students/:id"
              element={<AdminBatchStudents />}
            />
            <Route
              path="batches/students/add/:id"
              element={<AdminBatchAddStudentToBatch />}
            />
            <Route path="courses/master" element={<AdminCourseMaster />} />
            <Route path="courses/master/:slug" element={<AdminCourses />} />
            <Route
              path="courses/master/:slug/category"
              element={<AdminCourseCategory />}
            />
            <Route
              path="courses/master/:slug/create"
              element={<AdminCourseCE />}
            />
            <Route
              path="courses/master/:slug/edit"
              element={<AdminCourseCE />}
            />
            <Route path="courses/master/tab" element={<CourseCategoryTab />} />
            <Route
              path="courses/master/tab/:id"
              element={<CourseCategoryTab_Courses />}
            />
            <Route
              path="courses/master/coupon/master"
              element={<AdminCouponMaster />}
            />
            <Route
              path="courses/master/coupon/master/:id"
              element={<AdminCouponCourses />}
            />
            {/* <Route path="courses/master/coupon/master" element={<AdminCourseMaster />} /> */}
            <Route path="videos" element={<AdminVideos />} />
            <Route path="videos/create" element={<AdminVideoCE />} />
            <Route path="videos/edit" element={<AdminVideoCE />} />
            <Route path="videos/list/:id" element={<AdminVideoList />} />
            <Route
              path="videos/submodule/add/:id"
              element={<AdminVideoSubMOduleAdd />}
            />
            <Route path="videos/vimeo" element={<AdminVideoVimeo />} />
            <Route
              path="videos/vimeo/account"
              element={<AdminVimeoAccounts />}
            />
            <Route path="videos/modules" element={<VimeoModules />} />
            <Route path="videos/modules/:id" element={<VimeoSubModules />} />
            <Route path="videos/submodules" element={<VideoSubModules />} />
            <Route
              path="videos/submodules/nmod/:id/:sub"
              element={<VideoNSubModules />}
            />
            <Route path="test-series" element={<AdminTestSeriesModules />} />
            <Route
              path="test-series/submodules/:id"
              element={<AdminTestSubModules />}
            />
            <Route
              path="test-series/submodules-group-test/:id"
              element={<AdminGroupTestSeries />}
            />
            <Route
              path="test-series/nsubmodules/:id/:smod"
              element={<AdminTestSubModules />}
            />
            <Route
              path="test-series/nsubmodules-group-test/:id/:smod"
              element={<AdminGroupTestSeries />}
            />
            <Route
              path="test-series/submodules/questions/:id"
              element={<AdminQuestions />}
            />
            <Route
              path="test-series/submodules-group-test/questions/:id"
              element={<AdminGroupTestQuestions />}
            />
            <Route
              path="test-series/submodules/questions/create/:id"
              element={<AdminQuestionCE />}
            />
            <Route
              path="test-series/submodules-group-test/questions/create/:id"
              element={<AdminGroupTestQuestionsCE />}
            />
            <Route
              path="test-series/submodules/questions/edit/:id/:quest"
              element={<AdminQuestionCE />}
            />
            <Route
              path="test-series/submodules-group-test/questions/edit/:id"
              element={<AdminGroupTestQuestionsCE />}
            />
            <Route path="live-class" element={<AdminLiveClass />} />
            <Route
              path="live-class/account"
              element={<AdminLiveClassAccount />}
            />
            <Route
              path="live-class/submodule"
              element={<AdminLiveClassSubMod />}
            />
            <Route
              path="live-class/join/:meeting"
              element={<TeacherZoomMeeting />}
            />
            <Route path="students" element={<AdminStudents />} />
            <Route
              path="students/profile/:id"
              element={<AdminStudentProfile />}
            />
            <Route path="students/create" element={<AdminStudentCE />} />
            <Route path="students/edit" element={<AdminStudentCE />} />
            <Route path="teachers" element={<AdminDashboard />} />
            <Route path="installments" element={<AdminInstallments />} />
            <Route path="exam-alert" element={<AdminExamAlert />} />
            <Route path="feeds" element={<AdminFeeds />} />
            <Route path="promotions" element={<AdminPromotional />} />
            <Route path="fees-invoice" element={<AdminDashboard />} />

            <Route path="payments" element={<AdminPayments />} />
            <Route path="payment/pending" element={<AdminPendingPayments />} />
            <Route
              path="setting"
              element={isAdmin ? <AdminSetting /> : <LoginPublic />}
            ></Route>
          </Route>
          <Route path="/student/register" element={<StudentRegister />} />
          <Route
            path="/student/login-password/update"
            element={<StudentLoginPasswordUpdate />}
          />
          <Route
            path="/student/forget-password"
            element={<StudentForgetPassword />}
          />
          <Route
            path="/student/testseries/question/:id"
            element={isStudent ? <StudentTestSeries /> : <LoginPublic />}
          /> 
          <Route
            path="/student"
            element={isStudent ? <Student /> : <LoginPublic />}
          >
            <Route path="dashboard" element={<StudentDashboard />} />
            <Route path="profile" element={<StudentProfile />} />
            <Route path="courses" element={<StuCourseMaster />} />
            <Route path="courses/:id" element={<StuCourses />} />
            <Route path="courses/installments/:course/:installment" element={<StudentPayInstallments />} />
            <Route path="courses/detail/:id" element={<CourseDetails />} />
            <Route path="courses/checkout/:id" element={<CourseCheckout />} />
            <Route path="videos" element={<StudentVideo />} />
            <Route
              path="videos/modules/:id"
              element={<StudentVideoModules />}
            />
            <Route
              path="videos/modules/:id/submodules"
              element={<StudentVideoSubModules />}
            />
            <Route
              path="videos/modules/:id/submodules/:id"
              element={<StudentVideosList />}
            />
            <Route path="videos/play/:id" element={<StudentVideoPlay />} />
            <Route path="live-class" element={<StudentLiveClass />} />
            <Route path="subscription" element={<StudentSubscription />} />
            <Route path="exam-alert" element={<StudentExamAlert />} />
            <Route
              path="live-class/join/:meeting"
              element={<StudentZoomMeeting />}
            />
            <Route path="payment/hold" element={<HoldPayments />} />
            <Route path="test-series" element={<StudentTestSeriesCourse />} />
            <Route
              path="test-series/result/basic/:id"
              element={<StudentResultBasic />}
            />
            <Route
              path="test-series/modules/:id"
              element={<StudentTestSeriesModules />}
            />
            <Route
              path="test-series/submodules/:id"
              element={<StudentTestSeriesNmodules />}
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
