import Box from '@mui/material/Box';
import { DataGrid, GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

import { FaMinus } from 'react-icons/fa';
import { getStudentsWithOutBatch } from '../../service/admin/adminBatch.service';

export default function SelectPaginationDateGrid(props: {
  name: string;
  onSubmit: any;
  height?: number;
  dateRange?: boolean;
  id?: string;
}) {
  const [columnWidths, setColumnWidths] = useState({});

  const handleColumnWidthChange = (newColumnWidths: any) => {
    setColumnWidths(newColumnWidths);
  };
  const [loading, setloading] = useState<boolean>(false);
  const [query, setQuery] = useState<any>(null);
  let columns: any = [];
  let [tableRow, settableRow] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [rowCountState, setRowCountState] = useState(totalRow || 0);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [selectedRows, setSelectedRows] = useState<any>();

  const [rowid, setRowid] = useState<any>(1);
  const AddStudentToBatchAdmin = async () => {
    setloading(true);
    let get;
    if (query === null || query === undefined) {
      get = await getStudentsWithOutBatch(props.id!, page);
    } else {
      get = await getStudentsWithOutBatch(props.id!, page, query);
    }
    setTotalPages(get?.message.totalPage * pageSize);
    setTotalRow(get?.message.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: any) => {
          dt.push({
            id: item.id,
            ids: index + rowid,
            uuid: item.id,
            name: item.name,
            email: item.email,
            phone: item.phone,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AddStudentToBatchAdmin') {
    columns = [
      {
        field: 'ids',
        headerName: 'ID',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
      },
    ];
  }
  useEffect(() => {
    if (props.name === 'AddStudentToBatchAdmin') {
      AddStudentToBatchAdmin();
    }
  }, [page, pageSize, query]);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRow !== undefined ? totalRow : prevRowCountState
    );
  }, [totalRow]);
  useEffect(() => {
    // console.log(rowSelectionModel)
    // const selectedData = rowSelectionModel.map((id:any) => tableRow.find((item:any, index:number) => item.id === id));
    props.onSubmit(rowSelectionModel);
  }, [rowSelectionModel]);

  const onPaginationModelChange = (paginationModel: any) => {
    if (rowSelectionModel.length > 0) {
      setRowSelectionModel([...rowSelectionModel]);
    }
    // if(rowSelectionModel){

    // }
    // localStorage.setItem('batchAddUser', JSON.stringify(rowSelectionModel));
    setPage(paginationModel.page + 1);
    setRowid(10 * paginationModel.page + 1);
    setPaginationModel(paginationModel);
  };
  const onFilterChange = (filterModel: any) => {
    if (filterModel.quickFilterValues.length > 0) {
      setQuery(filterModel.quickFilterValues.join(' '));
    } else {
      setQuery(null);
    }
  };
  return (
    <>
      {/* { loading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{ background: "gray", width: 1 }}
          height={props.height ? props.height : 400}
        />
      ) : ( */}

      <Box
        sx={{ height: props.height ? props.height : 450, width: 1, flex: 1 }}
        className={` bg-white p-3 `}
      >
        <DataGrid
          sx={{ border: 0, height: 400 }}
          rows={tableRow}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
            },
          }}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel: any) => {
            // const seletedId:any = tableRow.filter((row:any)=> row.id === newRowSelectionModel[0])
            //    setRowSelectionModel(seletedId);
            if (newRowSelectionModel.length > 0) {
              setRowSelectionModel([...newRowSelectionModel]);
            } else {
              setRowSelectionModel([]);
            }
          }}
          keepNonExistentRowsSelected
          rowCount={rowCountState}
          columns={columns}
          loading={loading}
          paginationMode="server"
          paginationModel={paginationModel}
          // onPaginationModelChange={setPaginationModel}
          onPaginationModelChange={onPaginationModelChange}
          rowSelectionModel={rowSelectionModel}
          className="no-export-button"
          // Set the total row count
        />
      </Box>
      {/* // ) */}
      {/* } */}
    </>
  );
}
