import { Outlet } from 'react-router-dom';
import SettingTopNav from '../../../components/nav/SettingTopNav';
export default function AdminSetting() {
  return (
    <>
      <SettingTopNav />
      <Outlet />
    </>
  );
}
