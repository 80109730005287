import React, { useEffect, useState } from 'react';
import { getStudentTestSeriesCourses } from '../../../service/student/student.service';
import Loaders from '../../../components/loaders/Loaders';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { BreadCrumRoutes } from '../../../helpers/helpers';
import { useNavigate } from 'react-router-dom';
import DataNotFound from '../../../components/errors/DataNotFound';
import { Helmet } from 'react-helmet-async';

export default function StudentTestSeriesCourse() {
  const [pageLoading, setPageLoading] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const [bradCrumData, setBreadcrumbData] = useState<any>();
  const navigate = useNavigate();
  const fetchCourse = async () => {
    setPageLoading(true);
    let get: any = await getStudentTestSeriesCourses();
    if (get?.status === 'success') {
      setPageLoading(false);
      setFetchData(get?.message);
    }
  };

  useEffect(() => {
    fetchCourse();
    setBreadcrumbData(
      BreadCrumRoutes(
        'Test Series',
        '/student/test-series',
        'studentTestSeries',
        true
      )
    );
  }, []);

  return pageLoading ? (
    <Loaders type="spinner" />
  ) : (
    <>
      <Helmet>
        <title>{`Test Series | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadCrumb routes={bradCrumData} />
      <div className="my-2 grid grid-cols-12 gap-3">
        {fetchData?.length > 0 ? (
          fetchData.map((item: any, index: number) => (
            <div
              onClick={() =>
                navigate(
                  `/student/test-series/modules/${item?.id}?name=${item?.name}`
                )
              }
              key={index}
              className="lg:col-span-3 hover:cursor-pointer col-span-12 sm:col-span-12 md:col-span-4  bg-gray-200 rounded-xl"
            >
              <div className="flex flex-col  gap-2 ">
                <img
                  src={item?.image}
                  className="h-[200px] w-auto rounded-lg"
                  alt=""
                />
                <p className="text-2xl text-center font-semibold">
                  {item?.name}
                </p>
                <ul className="text-left">
                  <li className="flex justify-between  p-2">
                    <p> Total Test Series</p>
                    <p>
                      {' '}
                      {item?.attempts}/{item?.totalTest}
                    </p>
                  </li>
                  {/* <li className='flex justify-between p-2'> 
                                    <p>Total Duration</p>
                                    <p>{item?.totalLength}</p>
                                </li> */}
                </ul>
                <button
                  onClick={() =>
                    navigate(
                      `/student/test-series/modules/${item?.id}?name=${item?.name}`
                    )
                  }
                  className="bg-gradient p-2 text-white rounded-lg"
                >
                  Test Series
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-12">
            <DataNotFound title="No Test Series Found" />
          </div>
        )}
      </div>
    </>
  );
}
