import { CircularProgress, LinearProgress, Tooltip } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useDropzone,
  DropzoneRootProps,
  DropzoneInputProps,
} from 'react-dropzone';
import { FaTimes } from 'react-icons/fa';
import { fileUploadBannerDesktop, fileUploadCard, mediaEndPoint } from '../../service/instace';
import { toast } from 'react-toastify';

interface FileProgress {
  [filename: string]: number;
}

interface FileEntry {
  filename: string;
  size: number;
  file: any;
  type: any;
  controller: AbortController;
}

export default function DropZoneUploadImages(props: {
  status: any;
  data: any;
  maxFile?: number;
  title?: string;
  url?: string;
  isRequired?: boolean;
  heigth?: string;
  imageSize?: any;
  type?:any;
}) {
  const [fileEntries, setFileEntries] = useState<FileEntry[]>([]);
  const [fileUploaded, setUploaded] = useState({
    status: false,
    url: '',
    name: '',
  });
  const [startUploading, setStartUploading] = useState(false);
  const [files, setFiles] = useState<any>(null);
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const selectedFile: any = acceptedFiles[0];
    setFiles(
      Object.assign(selectedFile, {
        preview: URL.createObjectURL(selectedFile),
      })
    );
    const newEntries = acceptedFiles.map((file) => ({
      filename: file.name,
      size: file.size,
      file: file,
      type: file.type,
      controller: new AbortController(),
    }));
    setFileEntries((prevEntries) => [...prevEntries, ...newEntries]);
    handleUpload(acceptedFiles[0]);
  }, []);
  useEffect(() => {
    console.log(props.type)
    if (props.url) {
      let len: number = props.url?.split('/').length;
      setUploaded({
        status: true,
        url: props.url,
        name: props.url?.split('/')[len - 1],
      });
    }
  }, [props.url]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.gif', '.jpeg', '.jpg', '.webp', '.png'],
    },
    maxFiles: 1,
    multiple: false,
  });
  const clearFiles = async (filename: string) => {
    try {
      setFiles(null);
      setStartUploading(false);
      setFileEntries([]);
      props.data(null);
      setUploaded({
        status: false,
        url: '',
        name: '',
      });
    } catch (e: any) {}
  };
  const handleUpload = async (entries: any) => {
    try {
      let urls:any = `${mediaEndPoint}/${fileUploadCard}`;
      if(props.type === "bannerDesktop"){
        urls = `${mediaEndPoint}/${fileUploadBannerDesktop}`;
      }
      console.log(props.type);
      console.log(urls);
      setStartUploading(true);
      const formData = new FormData();
      formData.append('image', entries);
      const upload: any = await axios.post(
        urls,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (upload.data?.status === 'success') {
        setStartUploading(false);
        props.data(upload.data?.message);
        let len: number = upload.data?.message.split('/').length;
        setUploaded({
          status: true,
          url: upload.data?.message,
          name: upload.data?.message.split('/')[len - 1],
        });
      }
      setStartUploading(false);
    } catch (error: any) {
      setStartUploading(false);
      clearFiles('filename');
      if (error.response?.status === 404) {
        return toast.error('Api not found, contact to administrator');
      }
      if (error.response?.status !== 200) {
        return toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <label className={`text-gray-600 dark:text-gray-400 ml-1`}>
        {props.title}{' '}
        {props.isRequired ? <sup className="text-red-500">*</sup> : null}
        {props.imageSize ? (
          <small className=" block text-gray-600 ml-1">
            preferred size : {props.imageSize}
          </small>
        ) : (
          <small className=" block text-gray-600 ml-1">
            preferred size : 600*400
          </small>
        )}
      </label>
      <div className="p-2 rounded-xl w-full border overflow-auto border-gray-500 border-dashed bg-gray-200 dark:bg-gray-700">
        <div className={`${props.heigth ? props.heigth : 'min-h-[10vh]'} overflow-auto`}>
          {fileUploaded.status === false ? (
            fileEntries.length > 0 ? (
              fileEntries.map((e: any,index:number) => (
               
                  <div key={index} className=" flex gap-3 items-center">
                    <p>{e.filename} </p>
                    <CircularProgress size={26} />
                  </div>
               
              ))
            ) : (
              <div
                className={`${props.heigth ? props.heigth : 'min-h-[10vh]'}  grid place-content-center  ${startUploading ? 'hidden' : ' block'}`}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <p className="text-gray-500">
                  Drag 'n' drop some files here, or click to select files{' '}
                </p>
              </div>
            )
          ) : (
            <div className="grid place-content-center relative overflow-auto ">
              <img src={fileUploaded.url} className=" h-full rounded-md" alt="" />
              <Tooltip title="Remove Image">
                <button
                  type="button"
                  className="bg-red-500 absolute right-1 p-1 rounded-full  mt-1 text-white font-normal"
                  onClick={() => clearFiles(fileUploaded.name)}
                >
                  <FaTimes />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
