import instance from '../instace';

export const SuperAdminLogin = async (email: string, password: string) => {
  try {
    const get: any = await instance.post('/api/institute/login', {
      email,
      password,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
