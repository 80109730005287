import React, { useEffect, useState } from 'react';
import { showAdminFeed } from '../../service/admin/adminFeed.service';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import parse from 'html-react-parser';
import Cookies from 'js-cookie';
import { StudentshowAdminFeed } from '../../service/student/student.service';

export default function FeedModels(props: { data?: any; open: any }) {
  const [fetchData, setFetchData] = useState<any>();
  const [pageLoad, setPageLoad] = useState(false);
  const [progress, setProgress] = useState(0);
  const fetchBatchDetail = async (id: string) => {
    let get: any;
    if (JSON.parse(Cookies.get('login')!).role === 'admin') {
      get = await showAdminFeed(id);
    } else if (JSON.parse(Cookies.get('login')!).role === 'student') {
      get = await StudentshowAdminFeed(id);
    }
    if (get?.status === 'success') {
      // setUrl(get.message?.image)
      setFetchData(get.message);
    }
  };
  useEffect(() => {
    setPageLoad(true);
    fetchBatchDetail(props.data.id);
    setPageLoad(false);
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          color="inherit"
          value={progress}
        />
        <div className="flex items-center justify-center p-3 bg-gray-950 flex-col">
          {fetchData?.url ? (
            <img src={fetchData?.url} alt="admin feed" className="" />
          ) : null}
          {fetchData?.name ? (
            <div className="text-xl font-bold text-white">
              {parse(fetchData?.name)}
            </div>
          ) : null}
        </div>
      </Box>
    </>
  );
}
