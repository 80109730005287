import React, { useEffect, useState } from 'react';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import RadioButton from '../../../../components/form/RadioButton';
import { statusList, toBoolean } from '../../../../helpers/helpers';
import FormButton from '../../../../components/button/FormButton';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AdminCourseCategoryTypes } from '../../../../types/AdminTypes';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';
import {
  createAdminCourseCategory,
  editAdminCourseCategory,
  showAdminCourseCategory,
} from '../../../../service/admin/adminCourses.service';
import DropZoneUploadImages from '../../../../components/dropzone/DropZoneUploadImages';

export default function AdminCourseCategoryCE(props: {
  type: string;
  data?: any;
  open: any;
}) {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [url, setUrl] = useState<any>();
  const [open, setOpen] = useState(false);
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] =
    useState<any>();
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState({
    button: 'Create Course Sub Menu',
  });

  const dispatch = useDispatch();

  const fetchCourseCategoryDetail = async (id: string) => {
    const get: any = await showAdminCourseCategory(id);
    if (get?.status === 'success') {
      setFetchData(get.message);
      setUrl(get.message?.image);
    }
  };
  useEffect(() => {
    setPageLoad(true);
    if (props.type === 'edit') {
      fetchCourseCategoryDetail(props.data.id);
      setPageContent({
        button: 'Update Course Sub Menu',
      });
    }
    setPageLoad(false);
  }, []);

  const { handleSubmit, control } = useForm<AdminCourseCategoryTypes>({
    values: {
      fk_courseMaster_id: 'sad',
      name: fetchData?.name,
      status: `${fetchData?.status ? fetchData?.status : true}`,
      image: '',
    },
  });
  const onSubmit: SubmitHandler<AdminCourseCategoryTypes> = async (data) => {
    setLoading(true);
    let status_: any = toBoolean(data.status);
    let fetch: any;
    let datas: AdminCourseCategoryTypes = {
      name: data.name,
      status: status_,
      fk_courseMaster_id: props.data?.master,
      image: url,
    };
    if (props.type === 'create') {
      fetch = await createAdminCourseCategory(datas);
    }
    if (props.type === 'edit') {
      fetch = await editAdminCourseCategory(fetchData?.id, datas);
    }
    if (fetch?.status === 'success') {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  return (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <Input
          name="name"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Name"
          placeholder="Name"
        />
        <DropZoneUploadImages
          heigth="min-h-[20vh]"
          imageSize="400*400"
          maxFile={1}
          url={url}
          data={setUrl}
          status={setCheckUploadedFilesStatus}
        />
        <RadioButton
          name="status"
          control={control}
          rules={{ ...formValidationRules.required() }}
          options={statusList}
          label="status"
        />
        <br />
        <FormButton loading={loading} title={pageContent.button} />
      </form>
    </div>
  );
}
