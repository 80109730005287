import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import Models from '../../../components/models/Models';
import AdminPaymentMaster from './AdminPaymentMaster';
import {
  deleteAdminPaymethodMethodMaster,
  getAdminPaymethodMethodMaster,
  updateStatusAdminPaymethodMethodMaster,
} from '../../../service/admin/adminpayments.service';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AdminPaymentMethodSetup from './AdminPaymentMethodSetup';

export default function AdminPayments() {
  const [open, setOpen] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [modelData, setModelData] = useState<any>();
  const fetchApi = async () => {
    let get: any = await getAdminPaymethodMethodMaster();
    if (get?.status === 'success') {
      setFetchData(get?.message);
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);
  const handleChange = async (id: string, status: boolean) => {
    setBtnLoading(true);
    const handle: any = await updateStatusAdminPaymethodMethodMaster(
      id,
      status
    );
    if (handle?.status === 'success') {
      setBtnLoading(false);
      fetchApi();
      return toast.success(handle.message);
    }
    setBtnLoading(false);
  };
  const handleDelete = async (id: string) => {
    setBtnLoading(true);
    Swal.fire({
      icon: 'question',
      title: `are you sure you want to delete`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
    }).then(async (result: any) => {
      const handle: any = await deleteAdminPaymethodMethodMaster(id);
      if (handle?.status === 'success') {
        setBtnLoading(false);
        fetchApi();
        return toast.success(handle.message);
      }
    });

    setBtnLoading(false);
  };
  const handleOpen = async (e: any) => {
    setOpen(false);
    fetchApi();
  };
  const handleSetup = async (id: any, name: string) => {
    setOpen1(!open1);
    setModelData({
      name: name,
      id: id,
    });
  };
  return (
    <>
      <Helmet>
        <title>{` Payments   | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`Payments`}>
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Payment Methods"
          icon={true}
          iconName="bank"
        />
      </BreadcrumbBack>

      <div className="relative overflow-x-auto rounded-lg my-3  max-h-[300px] min-h-[100px]">
        <table className="w-full   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-2 px-2">
                S.no
              </th>
              <th scope="col" className="py-2 px-2 ">
                Name
              </th>
              <th scope="col" className="py-2 px-2 text-center">
                Status
              </th>
              <th scope="col" className="py-2 px-2 text-center">
                Setup
              </th>
              <th scope="col" className="py-2 px-2 text-center"></th>
            </tr>
          </thead>
          <tbody>
            {fetchData.length > 0
              ? fetchData.map((row: any, index: number) => (
                  <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 text-center even:dark:bg-gray-800 border-b dark:border-gray-700">
                    <td className="px-2 py-2 text-center">{index + 1}</td>
                    <td className="px-2 py-2 text-center">
                      <p className="flex flex-row gap-2 items-center">
                        <img
                          src={row.paymentMethodMaster.icon}
                          className="w-[30px] h-[30px] rounded-full "
                          alt=""
                        />
                        <b>{row.name}</b>
                      </p>
                    </td>
                    <td className="px-2 py-2 text-center">
                      <Switch
                        checked={row.status}
                        onChange={() => handleChange(row.id, !row.status)}
                        size="medium"
                      />
                    </td>
                    <td className="px-2 py-2 text-center">
                      <button
                        onClick={() => handleSetup(row.id, row.name)}
                        className="bg-purple-800 text-white font-semibold px-4 py-2 rounded-xl"
                      >
                        setup
                      </button>
                    </td>
                    <td className="px-2 py-2 text-center gap-3 flex justify-end mr-3 ">
                      {/* <Tooltip title="update payment method" ><button  onClick={(()=>setOpen1(!open1))}><FaEdit     className="text-2xl text-blue-500" /></button></Tooltip> */}
                      <Tooltip title="delete payment method">
                        <button>
                          <FaTrash
                            onClick={() => handleDelete(row.id)}
                            className="text-2xl text-red-500"
                          />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>

      <Models name="Add Payment method" isOpen={open} isClose={setOpen}>
        <AdminPaymentMaster open={handleOpen} />
      </Models>
      <Models
        name={`${modelData?.name} Payment Method Setup`}
        isOpen={open1}
        isClose={setOpen1}
      >
        <AdminPaymentMethodSetup data={modelData} open={setOpen1} />
      </Models>
    </>
  );
}
