import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AdminLoginService,
  updateAdminPasswordService,
} from '../../service/admin/admin.service';
import { setAuthToken } from '../../service/AuthConfig';
import { logout, schoolLogin } from '../../redux/loginSlice';
import Logo from '../../assets/icons/logo.jpg';
import { inputField } from '../../helpers/Classes';
import LoginButton from '../../components/button/LoginButton';
import {
  checkStudentEmail,
  studentForgotPasswordVerifyOTP,
  studentResetPasswordVerifyOTP,
} from '../../service/student/student.service';
import { Helmet } from 'react-helmet-async';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
export default function StudentForgetPassword() {
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [userid, setUserid] = useState<any>(null);
  const [newpassword, setNewPassword] = useState(undefined);
  const [confirmPassword, setConfirmPassword] = useState(undefined);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.set('role', 'admin');
    document.title = 'Login | LMS';
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (step === 0) {
      if (email === undefined) {
        setLoading(false);
        return Swal.fire({
          icon: 'error',
          text: 'email required',
        });
      }
      let checkEmail = await checkStudentEmail(email);
      if (checkEmail.status === 'success') {
        setEmailStatus(true);
        setLoading(false);
        setUserid(checkEmail.message.id);
        setStep(1);
        return toast.success(checkEmail.message.data);
      } else {
        setEmailStatus(false);
      }
    }
    if (step === 1) {
      if (userid === null) {
        setLoading(false);
        setStep(0);
        setEmailStatus(false);
        return toast.error('internal error');
      }
      if (!password || password === undefined) {
        setLoading(false);
        return Swal.fire({
          icon: 'error',
          text: 'OTP required',
        });
      }
      let verfiy = await studentForgotPasswordVerifyOTP(userid, password);
      if (verfiy.status === 'success') {
        setLoading(false);
        setStep(2);
        setPasswordStatus(true);
        return toast.success('OTP verified successfully, update your password');
      } else {
        setLoading(false);
        setStep(1);
      }
    }
    if (step === 2) {
      if (userid === null) {
        setLoading(false);
        setStep(0);
        setEmailStatus(false);
        return toast.error('internal error');
      }
      console.log(newpassword);
      if (!newpassword || newpassword === undefined) {
        setLoading(false);
        return Swal.fire({
          icon: 'error',
          text: 'Password required',
        });
      }
      if (!confirmPassword || confirmPassword === undefined) {
        setLoading(false);
        return Swal.fire({
          icon: 'error',
          text: 'Confirm Password required',
        });
      }
      if (newpassword !== confirmPassword) {
        setLoading(false);
        return Swal.fire({
          icon: 'error',
          text: 'Password and Confirm Password does not match',
        });
      }
      let update = await studentResetPasswordVerifyOTP(
        userid,
        password,
        newpassword,
        confirmPassword
      );
      if (update.status === 'success') {
        setLoading(false);
        toast.success('Password updated successfully');
        navigate('/');
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{`Forget Password | ${window.location.hostname}`}</title>
      </Helmet>
      <div className="container-login100">
        <div className=" lg:min-w-[25vw]">
          <div className="bg-white rounded-3xl shadow-lg p-5 sm:p-5 md:p-10 lg:p-10 w-full">
            <div className="flex justify-center mb-4">
              <img src={Logo} alt="chahal Academy Logo" />
            </div>
            <p className=" text-2xl font-bold text-center">Reset Password </p>
            {/* <p className=' font-semibold text-gray-500 mt-5 text-center'>Login to continue..</p> */}
            <form className="my-5" onSubmit={handleSubmit}>
              <div className="flex mb-2 flex-row gap-3 ">
                <div className="flex flex-col mb-3 w-[100%]">
                  <label className="text-gray-600 dark:text-gray-400 ml-1 ">
                    email <sup className="text-red-600">*</sup>
                  </label>
                  <input
                    type="email"
                    required
                    onChange={(e: any) => setEmail(e.target.value)}
                    className={`${inputField} `}
                    placeholder="email"
                  />
                </div>
              </div>
              {emailStatus ? (
                <div className="flex mb-2 flex-row gap-3 ">
                  <div className="flex flex-col mb-3 w-[100%]">
                    <label className="text-gray-600 dark:text-gray-400 ml-1 ">
                      Otp <sup className="text-red-600">*</sup>
                    </label>
                    <input
                      type="text"
                      required
                      onChange={(e: any) => setPassword(e.target.value)}
                      className={`${inputField} `}
                      placeholder="otp"
                    />
                  </div>
                </div>
              ) : null}
              {passwordStatus ? (
                <>
                  <div className="flex mb-2 flex-row gap-3 ">
                    <div className="flex flex-col mb-3 w-[100%] relative">
                      <label className="text-gray-600 dark:text-gray-400 ml-1 ">
                        New Password <sup className="text-red-600">*</sup>
                      </label>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        required
                        onChange={(e: any) => setNewPassword(e.target.value)}
                        className={`${inputField} `}
                        placeholder="new password"
                      />
                      <div
                        className=" absolute  text-gray-600 right-5 bottom-4 hover:cursor-pointer "
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <FaEye />
                        ) : (
                          <FaEyeSlash className=" " />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex mb-3 flex-row gap-3 ">
                    <div className="flex flex-col mb-3 w-[100%] relative">
                      <label className="text-gray-600 dark:text-gray-400 ml-1 ">
                        Confirm Password <sup className="text-red-600">*</sup>
                      </label>
                      <input
                        type={showPassword1 ? 'text' : 'password'}
                        required
                        onChange={(e: any) =>
                          setConfirmPassword(e.target.value)
                        }
                        className={`${inputField} `}
                        placeholder="confirm password"
                      />
                      <div
                        className=" absolute  text-gray-600 right-5 bottom-4 hover:cursor-pointer "
                        onClick={() => setShowPassword1(!showPassword1)}
                      >
                        {showPassword1 ? (
                          <FaEye />
                        ) : (
                          <FaEyeSlash className=" " />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="flex mb-3 flex-row gap-3 ">
                <div className="flex flex-col mb-3 w-[100%] ">
                  <LoginButton icon={true} title="Submit" loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
