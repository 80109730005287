import React, { useState } from 'react';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import { useNavigate } from 'react-router-dom';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';
import Models from '../../../components/models/Models';
import AdminLiveClassCE from './AdminLiveClassCE';
import { Helmet } from 'react-helmet-async';

export default function AdminLiveClass() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Helmet>
        <title>{` Live Class   | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`Live Class`}>
        <CustomButton
          onClick={() => navigate(`/admin/live-class/submodule`)}
          title="Manage Submodule"
          icon={true}
          iconName="folderTree"
        />
        <CustomButton
          onClick={() => navigate(`/admin/live-class/account`)}
          bg="bg-purple-600"
          title="Manage Accounts"
          icon={true}
          iconName="star"
        />
        <CustomButton
          onClick={() => setOpen(!open)}
          bg="bg-purple-800"
          title="Create Live Class"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>

      <div className="my-2">
        <PaginationDataGrid name="adminLiveClass" />
      </div>
      <Models name="Create New Live Class" isOpen={open} isClose={setOpen}>
        <AdminLiveClassCE type="create" open={setOpen} />
      </Models>
    </>
  );
}
