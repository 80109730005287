import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AdminVideoRelationTypes } from '../../../types/AdminTypes';
import { toast } from 'react-toastify';
import FormButton from '../../../components/button/FormButton';
import SearchableSelect from '../../../components/form/SearchableSelect';
import formValidationRules from '../../../helpers/formvalidationRules';
import MultiSearchableSelect from '../../../components/form/MultiSearchableSelect';
import {
  createAdminVideosRelation,
  getAdminVideosRelation,
  getAdminVideosSubModuleActive,
  getVideosActiveModule,
  getVideosActiveSubModuleByModule,
} from '../../../service/admin/adminVideoes.service';

export default function AdminVideoRelation(props: { data?: any; isOpen: any }) {
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [subModules, setSubModules] = useState<any>([]);
  const [modules, setModules] = useState([]);
  const [subId, setSubId] = useState<any>([]);
  const fetchDetail = async () => {
    const get: any = await getVideosActiveModule();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setModules(data);
      //   fetchSubModule();
    }
  };
  const fetchSubModule = async (id: string) => {
    const get: any = await getVideosActiveSubModuleByModule(id);
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({
          value: item.id,
          label: item.name,
          module: item.fk_videoModule_id,
        });
      });
      let newarray = [...subModules, ...data];
      setSubModules(newarray);
    }
  };
  const fetchSubmodules = async () => {
    const get: any = await getAdminVideosSubModuleActive();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setSubId(data);
    }
  };
  const fetchCreatedRelation = async (id: string) => {
    const get: any = await getAdminVideosRelation(id);
    if (get?.status === 'success') {
      // setFetchData(get.message)
      let data = get?.message;
      let mod: any = [];
      let subMod: any = [];
      let subDir: any = [];
      if (data.length > 0) {
        data.map((item: any) => {
          if (item.fk_videoModule_id !== null) {
            fetchSubModule(item.fk_videoModule_id);
            mod.push(item.fk_videoModule_id);
            subMod.push(item.fk_videoSubModule_id);
          } else {
            subDir.push(item.fk_videoSubModule_id);
          }
        });
        setFetchData({
          mod: mod,
          subMod: subMod,
          subDir: subDir,
        });
      }
    }
  };
  const handleModuleChange = async (data: any) => {
    if (data?.selected.length > 0) {
      if (data?.removed === undefined) {
        fetchSubModule(data?.selected[data?.selected.length - 1]);
      } else {
        console.log(data?.removed);
        let updated = subModules.filter(
          (item: any) => item.module !== data?.removed
        );
        setSubModules(updated);
      }
    } else {
      setSubModules([]);
    }
  };
  useEffect(() => {
    fetchDetail();
    fetchSubmodules();
    fetchCreatedRelation(props.data.id);
  }, []);
  const { handleSubmit, control } = useForm<AdminVideoRelationTypes>({
    values: {
      fk_module_id: fetchData?.mod,
      fk_submodule_id: fetchData?.subMod,
      fk_sub_id: fetchData?.subDir,
    },
  });

  const onSubmit: SubmitHandler<AdminVideoRelationTypes> = async (
    data: any
  ) => {
    setLoading(true);
    let fetch: any;
    let datam: any = [];
    if (data?.fk_submodule_id?.length > 0) {
      data.fk_submodule_id.map((item: any) => {
        let find: any = subModules.find((i: any) => i.value === item);
        datam.push({
          fk_module_id: find.module,
          fk_submodule_id: find.value,
        });
      });
    }
    if (data?.fk_sub_id?.length > 0) {
      data.fk_sub_id.map((item: any) => {
        datam.push({
          fk_module_id: null,
          fk_submodule_id: item,
        });
      });
    }
    if (datam.length === 0) {
      setLoading(false);
      return toast.error('Please select at least one submodule');
    }
    // console.log(datam)
    fetch = await createAdminVideosRelation(props.data.id, datam);
    if (fetch?.status === 'success') {
      setLoading(false);
      props.isOpen(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <div>
          <p className=" text-xl font-semibold text-gray-800 ">
            Link To Module And SubModule
          </p>
          <div className="grid grid-cols-12 gap-2">
            <div className=" col-span-12 md:col-span-12">
              <MultiSearchableSelect
                name="fk_module_id"
                control={control}
                options={modules}
                label="Select Module"
                onFetch={handleModuleChange}
              />
            </div>
            <div className=" col-span-12 md:col-span-12">
              <MultiSearchableSelect
                name="fk_submodule_id"
                control={control}
                options={subModules}
                label="Select Sub Module"
              />
            </div>
          </div>
        </div>
        <div>
          <p className="text-xl font-semibold text-gray-800">
            Link To SubModule Only
          </p>
          <div className="grid grid-cols-12 gap-1">
            <div className=" col-span-12 md:col-span-12">
              <MultiSearchableSelect
                name="fk_sub_id"
                control={control}
                options={subId}
                label="Select Sub Module"
              />
            </div>
          </div>
        </div>
        <FormButton loading={loading} title={'submit'} />
      </form>
    </>
  );
}
