import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CoursesType, liveClassesTypes } from '../../../types/AdminTypes';
import {
  SearchURLParams,
  statusList,
  toBoolean,
} from '../../../helpers/helpers';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import SearchableSelect from '../../../components/form/SearchableSelect';
import {
  createAdminCourse,
  editAdminCourse,
  getActiveCourseCategory,
  showAdminCourse,
} from '../../../service/admin/adminCourses.service';
import { getActiveBatchs } from '../../../service/admin/adminBatch.service';
import FormButton from '../../../components/button/FormButton';
import MultiSearchableSelect from '../../../components/form/MultiSearchableSelect';
import {
  createAdminLiveClasses,
  editAdminLiveClasses,
  getActiveAdminLiveClassAccount,
  getActiveAdminLiveClassSubmodule,
  showAdminLiveClasses,
} from '../../../service/admin/adminLiveClass.service';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../redux/contentUpdateSlice';
import moment from 'moment';
export default function AdminLiveClassCE(props: {
  type: string;
  data?: any;
  open: any;
}) {
  const dispatch = useDispatch();
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [liveClassAccount, setLiveClassAccount] = useState<any>([]);
  const [liveClassSubmodule, setLiveClassSubmodule] = useState([]);
  const [defaultAccount, setDefaultAccount] = useState<any>([]);
  const [defaultSubmodule, setDefaultSubModule] = useState<any>([]);
  const [pageContent, setPageContent] = useState({
    button: 'Create New Live CLass',
    title: 'Create New Live CLass',
  });
  const fetchCourseData = async (id: string) => {
    const get: any = await showAdminLiveClasses(id);
    if (get?.status === 'success') {
      setFetchData(get.message);
      setDefaultAccount({
        label: get?.message?.liveClassAccount?.email,
        value: get?.message?.liveClassAccount?.id,
      });
      let newData: any = [];
      if (get?.message?.liveClassSubmoduleRelation.length > 0) {
        get?.message?.liveClassSubmoduleRelation.map((e: any) => {
          newData.push(e?.liveClassSubmodule?.id);
        });
      }
      setDefaultSubModule(newData);
    }
  };
  const fetchLiveClassSubModules = async () => {
    const get: any = await getActiveAdminLiveClassSubmodule();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setLiveClassSubmodule(data);
    }
  };
  const fetchLiveClassAccount = async () => {
    const get: any = await getActiveAdminLiveClassAccount();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.email });
      });
      setLiveClassAccount(data);
    }
  };
  useEffect(() => {
    if (props.type === 'edit') {
      fetchCourseData(props.data.id);
      setPageContent({
        button: 'Update Live Class',
        title: 'Update Live Class',
      });
    }
    fetchLiveClassAccount();
    fetchLiveClassSubModules();
  }, []);
  const { handleSubmit, control } = useForm<liveClassesTypes>({
    values: {
      title: fetchData?.title,
      datetime: moment(fetchData?.datetime).format('YYYY-MM-DD HH:mm'),
      duration: fetchData?.duration,
      passowrd: fetchData?.passowrd,
      fk_liveClassAccount_id: fetchData?.liveClassAccount?.id,
      fk_liveCLassSubModule_id: defaultSubmodule,
    },
  });

  const onSubmit: SubmitHandler<liveClassesTypes> = async (
    data: liveClassesTypes
  ) => {
    setLoading(true);
    data.fk_liveClassAccount_id = data.fk_liveClassAccount_id.value;
    let fetch: any;
    if (props.type === 'create') {
      fetch = await createAdminLiveClasses(data);
    }
    if (props.type === 'edit') {
      data.fk_liveClassAccount_id = data.fk_liveClassAccount_id
        ? data.fk_liveClassAccount_id
        : fetchData?.liveClassAccount?.id;
      data.fk_liveCLassSubModule_id = data.fk_liveCLassSubModule_id
        ? data.fk_liveCLassSubModule_id
        : defaultSubmodule;
      fetch = await editAdminLiveClasses(props.data.id, data);
    }
    if (fetch?.status === 'success') {
      setLoading(false);
      props.open(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="my-2">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
          <div className="grid grid-cols-12 gap-2 ">
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="title"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                  ...formValidationRules.minLength(3),
                }}
                label="Title"
                placeholder="title"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="datetime"
                type="datetime-local"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Date Time"
                placeholder=""
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="duration"
                type="number"
                rules={{
                  ...formValidationRules.required(),
                  ...formValidationRules.min(1),
                }}
                control={control}
                label="Duration"
                placeholder="duration"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="passowrd"
                type="number"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                  ...formValidationRules.min(1),
                }}
                label="Password"
                placeholder="password"
              />
            </div>
          </div>

          <div className="my-2">
            <div className=" grid grid-cols-12 gap-2">
              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
                <SearchableSelect
                  name="fk_liveClassAccount_id"
                  control={control}
                  options={liveClassAccount}
                  rules={formValidationRules.required()}
                  label="Select Zoom Account"
                  placeholder="account"
                  defaultValue={defaultAccount}
                />
              </div>
              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
                <MultiSearchableSelect
                  name="fk_liveCLassSubModule_id"
                  control={control}
                  options={liveClassSubmodule}
                  rules={formValidationRules.required()}
                  label="Select Sub Module"
                  placeholder="submodule"
                  defaultValue={defaultSubmodule}
                />
              </div>
            </div>
          </div>
          <div className="my-3">
            <FormButton loading={loading} title={pageContent.button} />
          </div>
        </form>
      </div>
    </>
  );
}
