import React, { useState } from 'react';
import BreadcrumbBack from '../../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../../components/button/CustomButton';
import Models from '../../../../../components/models/Models';
import AdminVimeoAccountCE from './AdminVimeoAccountCE';
import PaginationDataGrid from '../../../../../components/tables/PaginationDataGrid';
import { Helmet } from 'react-helmet-async';

export default function AdminVimeoAccounts() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>{`Vimeo  Videos Account  | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title="Manage Vimeo Account">
        <CustomButton
          title="Create Vimeo Account"
          icon={true}
          iconName="create"
          onClick={() => setOpen(!open)}
        />
      </BreadcrumbBack>

      <div className="my-2">
        <PaginationDataGrid name="adminVimeoAccount" />
      </div>

      <Models name="Create New Account" isOpen={open} isClose={setOpen}>
        <AdminVimeoAccountCE type="create" isOpen={setOpen} />
      </Models>
    </>
  );
}
