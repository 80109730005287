import React, { useState } from 'react';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';
import { useNavigate } from 'react-router-dom';
import Models from '../../../components/models/Models';
import VimeoModuleCE from './videoModules/VimeoModuleCE';
import { Helmet } from 'react-helmet-async';

export default function AdminVideos() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  // getAllVideoModules
  return (
    <>
      <Helmet>
        <title>{`Videos  | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title="Videos">
        <CustomButton
          title="Create Video Module"
          icon={true}
          onClick={() => setOpen(!open)}
          bg="bg-purple-800"
          iconName="create"
        />
        <CustomButton
          title="Manage Vimeo "
          icon={true}
          onClick={() => navigate('/admin/videos/vimeo')}
          bg="bg-blue-500"
          iconName="vimeo"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <PaginationDataGrid name="AdminVideoModules" />
      </div>
      <Models name="Create New Module" isOpen={open} isClose={setOpen}>
        <VimeoModuleCE type="create" isOpen={setOpen} />
      </Models>
    </>
  );
}
