import Box from '@mui/material/Box';
import { DataGrid, GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { ThemeProvider, Tooltip, createTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  FaClock,
  FaEdit,
  FaEye,
  FaFile,
  FaFileAlt,
  FaFolder,
  FaPlay,
  FaRupeeSign,
  FaSpinner,
  FaStopwatch,
  FaTrash,
  FaUsers,
  FaVideo,
  FaVideoSlash,
  FaVimeo,
  FaYoutube,
} from 'react-icons/fa';
import Swal from 'sweetalert2';
import {
  SuperAdminDeleteCenters,
  SuperAdminStatusCenters,
} from '../../service/superAdmin/superAdminCenters.service';
import Models from '../models/Models';
import SuperAdminCenterCE from '../../pages/superAdmin/centers/SuperAdminCenterCE';
import {
  deleteAdminService,
  getAdminService,
  updateStatusAdminService,
} from '../../service/admin/admin.service';
import SuperAdmin_adminCE from '../../pages/superAdmin/admins/SuperAdmin_adminCE';
import {
  deleteAdminBatch,
  deleteSingleAdminBatchStudents,
  getAdminBatchService,
  getStudentsWithBatch,
  updateStatusAdminBatch,
} from '../../service/admin/adminBatch.service';
import AdminBatchCE from '../../pages/admins/batch/AdminBatchCE';
import TableButton from '../button/TableButton';
import SwitchButton from '../button/SwitchButton';
import {
  adminResetStudentPassword,
  deleteAdminStudent,
  getAdminFreeStudents,
  getAdminPaidStudents,
  getAdminStudents,
  updateStatusAdminStudent,
} from '../../service/admin/adminStudent.service';
import moment, { duration } from 'moment';
import CustomButton from '../button/CustomButton';
import { FaBookAtlas } from 'react-icons/fa6';
import AdminStudentCE from '../../pages/admins/students/AdminStudentCE';
import {
  deleteAdminCourse,
  deleteAdminCourseCategory,
  deleteAdminCourseMaster,
  getAdminCourse,
  getAdminCourseCategory,
  getAdminCourseMaster,
  updateOnAppAdminCourseMaster,
  updateOnWebAdminCourseMaster,
  updateStatusAdminCourse,
  updateStatusAdminCourseCategory,
  updateStatusAdminCourseMaster,
} from '../../service/admin/adminCourses.service';
import AdminCourseMasterCE from '../../pages/admins/courses/AdminCourseMasterCE';
import AdminCourseCategoryCE from '../../pages/admins/courses/courseCategory/AdminCourseCategoryCE';
import AdminBatchCourseCE from '../../pages/admins/batch/AdminBatchCourseCE';
import AdminBatchSubscription from '../../pages/admins/batch/AdminBatchSubscription';
import AdminBatchPayments from '../../pages/admins/batch/AdminBatchPayments';
import {
  deleteAdminSubMOdVideosRelation,
  deleteAdminVideo,
  deleteAdminVideoModule,
  deleteAdminVideoSubModule,
  getAdminNSubMOdules,
  getAdminVideos,
  getAdminVideosModule,
  getAdminVideosSubModule,
  getSubModuleVideosList,
  getSubModuleVideosNotVideos,
  updateStatusAdminVideo,
  updateStatusAdminVideoModule,
  updateStatusAdminVideoSubModule,
} from '../../service/admin/adminVideoes.service';
import {
  deleteAdminVimoeAccount,
  getAdminVimoeAccount,
} from '../../service/admin/adminVimeo.service';
import AdminVimeoAccountCE from '../../pages/admins/videos/vimeo/adminVimeoAccounts/AdminVimeoAccountCE';
import axios from 'axios';
import VimeoModuleCE from '../../pages/admins/videos/videoModules/VimeoModuleCE';
import VideoSubModuleCE from '../../pages/admins/videos/videoSubModules/VideoSubModuleCE';
import AdminVideoRelation from '../../pages/admins/videos/AdminVideoRelation';
import VimeoPlayer from '../player/VimeoPlayer';
import YoutubePlayer from '../player/YoutubePlayer';
import {
  deleteAdminTestSeriesModules,
  deleteAdminTestSeriesQuestion,
  deleteAdminTestSeriesSubModules,
  getAdminTestSeriesModules,
  getAdminTestSeriesNSubModules,
  getAdminTestSeriesSubModules,
  getAdminTestSeriesSubQuestion,
  getSubModuleQuestionDetails,
  updateStatusAdminTestSeriesModules,
  updateStatusAdminTestSeriesQuestion,
  updateStatusAdminTestSeriesSubModules,
} from '../../service/admin/adminTestSeries.service';
import AdminTestSeriesModulesCE from '../../pages/admins/testSeries/AdminTestSeriesModulesCE';
import { truncateString } from '../../helpers/helpers';
import AdminTestSubModulesCE from '../../pages/admins/testSeries/submodules/AdminTestSubModulesCE';
import parse from 'html-react-parser';
import {
  deleteAdminLiveClassAccount,
  deleteAdminLiveClasses,
  deleteAdminLiveClassSubmodule,
  getAdminLiveClassAccount,
  getAdminLiveClasses,
  getAdminLiveClassSubmodule,
  updateStatusAdminLiveClassAccount,
  updateStatusAdminLiveClassSubmodule,
} from '../../service/admin/adminLiveClass.service';
import AdminLiveClassSubModCE from '../../pages/admins/liveClass/liveClassSubModule/AdminLiveClassSubModCE';
import AdminLiveClassAccountCE from '../../pages/admins/liveClass/liveClassAccounts/AdminLiveClassAccountCE';
import AdminLiveClassCE from '../../pages/admins/liveClass/AdminLiveClassCE';
import AdminPaymentInstallments from '../../pages/admins/installments/AdminPaymentInstallments';
import {
  deleteAdminExamAlert,
  getAdminExamAlert,
  updateStatusAdminExamAlert,
} from '../../service/admin/adminExamAlert.service';
import AdminExamAlertCE from '../../pages/admins/examAlert/AdminExamAlertCE';
import WordUpload from '../uploads/WordUpload';
import {
  deleteAdminFeed,
  getAdminFeed,
  updateStatusAdminFeed,
} from '../../service/admin/adminFeed.service';
import AdminFeedsCE from '../../pages/admins/feeds/AdminFeedsCE';
import FeedModels from '../models/FeedModels';
import {
  deleteAdminpromotional,
  getAdminpromotional,
  updateStatusAdminpromotional,
} from '../../service/admin/adminPromotional.service';
import Player from '../player/Player';
import AdminCourseRelation from '../../pages/admins/courses/courseRelation/AdminCourseRelation';
import { encryptCryptoData, encryptUUID } from '../../helpers/encryptionKey';
import {
  deleteAdminCouponMaster,
  getAdminCouponMaster,
  updateStatusAdminCouponMaster,
} from '../../service/admin/adminCoupon.service';
import AdminCouponMasterEdit from '../../pages/admins/courses/couponMaster/AdminCouponMasterEdit';
import {
  courseOrderApprove,
  courseOrderReject,
  getAdminPendingPayments,
} from '../../service/admin/adminpayments.service';
import { button } from '@material-tailwind/react';
import AdminCourseEMI from '../../pages/admins/courses/AdminCourseEMI';

export default function PaginationDataGrid(props: {
  name: String;
  id?: any;
  refresh?: any;
  height?: any;
  dataId?: any;
  dataObject?: any;
  checkbox?: boolean;
  onSubmit?: any;
  otherId?: any;
}) {
  const themeSlice = useSelector((state: any) => state.themeSlice);
  const theme: any = createTheme({
    palette: {
      mode: themeSlice ? themeSlice : 'light',
    },
  });
  const [open, setOpen] = useState<any>(false);
  const [open1, setOpen1] = useState<any>(false);
  const [btnLoading, setBtnLoading] = useState<any>(false);
  const navigate = useNavigate();
  const [columnWidths, setColumnWidths] = useState({});

  const handleColumnWidthChange = (newColumnWidths: any) => {
    setColumnWidths(newColumnWidths);
  };
  const [loading, setloading] = useState<boolean>(false);
  let columns: any = [];
  let [tableRow, settableRow] = useState<any>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [updateModelData, setUpdateModelData] = useState<any>();
  const [rowLoad, setRowLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [modelData, setModelDate] = useState<any>([]);
  const [rowid, setRowid] = useState<any>(1);
  const [rowCountState, setRowCountState] = useState(totalRow || 0);
  const courseUpdateData = useSelector((state: any) => state.courseUpdateSlice);
  const contentUpdate = useSelector((state: any) => state.contentUpdateSlice);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [filterModel, setFilterModel] = useState<any>({
    items: [],
    quickFilterValues: [],
  });
  let superAdminCenterList = async () => {
    const {
      SuperAdminAllCenters,
    } = require('./../../service/superAdmin/superAdminCenters.service');
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await SuperAdminAllCenters(page);
    } else {
      get = await SuperAdminAllCenters(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            state: item.state.name,
            city: item.city.name,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'superAdminCenterList') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'state',
        headerName: 'State ',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'city',
        headerName: 'City',
        flex: 0.1,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await SuperAdminStatusCenters(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            } else {
              return toast.error(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <Switch
                checked={classtatus}
                onChange={handleChange}
                size="small"
              />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'action',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              html: `<p>type <span style="color:red">${classname}</span> to delete student</p>`,
              input: 'text',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                if (classname === value) {
                  const del: any = await SuperAdminDeleteCenters(
                    params.row.uuid
                  );
                  if (del.status === 'success') {
                    const newData = tableRow.filter(
                      (item: any) => item.uuid !== params.row.uuid
                    );
                    settableRow(newData);
                    setTotalPages(totalPages - 1);
                    setTotalRow(totalRow - 1);
                    return toast.success(del.message);
                  }
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Incorrect value',
                    text: `you have entered wrong text`,
                  });
                }
                return false;
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              {/* <Tooltip title="student detail" ><button  > <IoFileTrayFullSharp  className="text-lg text-green-500" /></button></Tooltip> */}
              <Tooltip title="update student">
                <button onClick={handleChange}>
                  <FaEdit className="text-lg text-blue-500" />
                </button>
              </Tooltip>
              <Tooltip title="delete student">
                <button>
                  <FaTrash
                    onClick={handleDelete}
                    className="text-lg text-red-500"
                  />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }
  const superAdmin_admins = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminService(page);
    } else {
      get = await getAdminService(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            email: item.email,
            phone: item.phone,
            center: item.instituteCenter?.name,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'superAdmin_admins') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email ',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'center',
        headerName: 'Center',
        flex: 0.1,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminService(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            } else {
              return toast.error(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <Switch
                checked={classtatus}
                onChange={handleChange}
                size="small"
              />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'action',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              html: `<p>type <span style="color:red">${classname}</span> to delete student</p>`,
              input: 'text',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                if (classname === value) {
                  const del: any = await deleteAdminService(params.row.uuid);
                  if (del.status === 'success') {
                    const newData = tableRow.filter(
                      (item: any) => item.uuid !== params.row.uuid
                    );
                    settableRow(newData);
                    setTotalPages(totalPages - 1);
                    setTotalRow(totalRow - 1);
                    return toast.success(del.message);
                  }
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Incorrect value',
                    text: `you have entered wrong text`,
                  });
                }
                return false;
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              {/* <Tooltip title="student detail" ><button  > <IoFileTrayFullSharp  className="text-lg text-green-500" /></button></Tooltip> */}
              <Tooltip title="update student">
                <button onClick={handleChange}>
                  <FaEdit className="text-lg text-blue-500" />
                </button>
              </Tooltip>
              <Tooltip title="delete student">
                <button>
                  <FaTrash
                    onClick={handleDelete}
                    className="text-lg text-red-500"
                  />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }
  const adminBatches = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminBatchService(page);
    } else {
      get = await getAdminBatchService(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminBatches') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.05,
        minWidth: 50,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminBatch(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: '',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              html: `<p>type <span style="color:red">${classname}</span> to delete student</p>`,
              input: 'text',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                if (classname === value) {
                  const del: any = await deleteAdminBatch(params.row.uuid);
                  if (del.status === 'success') {
                    const newData = tableRow.filter(
                      (item: any) => item.uuid !== params.row.uuid
                    );
                    settableRow(newData);
                    setTotalPages(totalPages - 1);
                    setTotalRow(totalRow - 1);
                    return toast.success(del.message);
                  }
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Incorrect value',
                    text: `you have entered wrong text`,
                  });
                }
                return false;
              },
            });
          };
          const handelCourseModel = () => {
            setOpen1(!open1);
            setModelDate({
              id: params.row.uuid,
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="users"
                icon={true}
                onClick={() =>
                  navigate(`/admin/batches/students/${params.row.uuid}`)
                }
                toottip="batch students"
              />
              <TableButton
                title="course"
                icon={true}
                onClick={handelCourseModel}
                toottip="batch course"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit batch"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete batch"
              />
              {/* <Tooltip title="delete student" >
              <TableButton title="delete" icon={true}   onClick={handleChange} toottip="edit batch" />
                <button ><FaTrash    onClick={handleDelete} className="text-lg text-red-500" /></button>
                </Tooltip> */}
            </div>
          );
        },
      },
    ];
  }
  const AdminAllStudents = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminStudents(page);
    } else {
      get = await getAdminStudents(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            phone: item.phone,
            email: item.email,
            dob: item.dob,
            gender: item.gender,
            profile: item?.profileImg,
            subscription: item.studentSubscription,
            created: moment(item?.createAt, 'YYYY-MM-DD HH:mm:ss').format(
              'YYYY-MM-DD'
            ),
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminAllStudents') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.08,
        minWidth: 120,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 0.15,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'dob',
        headerName: 'DOB',
        flex: 0.08,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          let dob: any = moment(params.row.dob, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD'
          );

          return <p>{dob}</p>;
        },
      },
      {
        field: 'created',
        headerName: 'Register On',
        flex: 0.08,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.05,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminStudent(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: 'types',
        headerName: 'Type',
        flex: 0.05,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus = params.row.subscription;

          return (
            <div className="flex gap-4 flex-row">
              {params.row.subscription.length > 0 ? (
                <>
                  <p className=" bg-purple2 text-white px-3 py-1 rounded-xl">
                    Paid
                  </p>
                </>
              ) : (
                <>
                  <p className=" bg-gray-600 text-white px-3 py-1 rounded-xl">
                    Free
                  </p>
                </>
              )}
            </div>
          );
        },
      },
      {
        field: '',
        headerName: '',
        flex: 0.15,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              html: `<p>type <span style="color:red">${classname}</span> to delete student</p>`,
              input: 'text',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                if (classname === value) {
                  const del: any = await deleteAdminStudent(params.row.uuid);
                  if (del.status === 'success') {
                    const newData = tableRow.filter(
                      (item: any) => item.uuid !== params.row.uuid
                    );
                    settableRow(newData);
                    setTotalPages(totalPages - 1);
                    setTotalRow(totalRow - 1);
                    return toast.success(del.message);
                  }
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Incorrect value',
                    text: `you have entered wrong text`,
                  });
                }
                return false;
              },
            });
          };
          const handleResetPassword = () => {
            // adminResetStudentPassword
            Swal.fire({
              title: 'Are you sure want to Reset Password ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                let reset: any = await adminResetStudentPassword(
                  params.row.uuid
                );
                if (reset?.status === 'success') {
                  return toast.success(reset.message);
                }
              },
            });
          };
          const handleStudentAnalysis = () => {};
          return (
            <div className="flex gap-2 flex-row">
              <TableButton
                title="file"
                icon={true}
                onClick={() =>
                  navigate(
                    `/admin/students/profile/${params.row.uuid}?name=${params.row.name}`
                  )
                }
                toottip="student details"
              />
              <TableButton
                title="key"
                icon={true}
                onClick={handleResetPassword}
                toottip="reset password"
              />
              {/* <Tooltip title="student detail" ><button  > <IoFileTrayFullSharp  className="text-lg text-green-500" /></button></Tooltip> */}
              {/* <TableButton title="start" icon={true}   onClick={handleStudentAnalysis} toottip="Student Analysis" /> */}
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit batch"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="edit batch"
              />
              {/* <Tooltip title="delete student" ><button ><FaTrash    onClick={handleDelete} className="text-lg text-red-500" /></button></Tooltip> */}
            </div>
          );
        },
      },
    ];
  }

  const AdminPaidStudents = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminPaidStudents(page);
    } else {
      get = await getAdminPaidStudents(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            phone: item.phone,
            email: item.email,
            dob: item.dob,
            gender: item.gender,
            profile: item?.profileImg,
            subscription: item.studentSubscription,
            created: moment(item?.createAt, 'YYYY-MM-DD HH:mm:ss').format(
              'YYYY-MM-DD'
            ),
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminPaidStudents') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.12,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 0.15,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'dob',
        headerName: 'DOB',
        flex: 0.08,
        minWidth: 50,
        sortable: false,
        renderCell: (params: any) => {
          let dob: any = moment(params.row.dob, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD'
          );

          return <p>{dob}</p>;
        },
      },
      {
        field: 'created',
        headerName: 'Register On',
        flex: 0.08,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.05,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminStudent(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: 'types',
        headerName: 'Type',
        flex: 0.05,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus = params.row.subscription;

          return (
            <div className="flex gap-4 flex-row">
              {params.row.subscription.length > 0 ? (
                <>
                  <p className=" bg-purple2 text-white px-3 py-1 rounded-xl">
                    Paid
                  </p>
                </>
              ) : (
                <>
                  <p className=" bg-gray-600 text-white px-3 py-1 rounded-xl">
                    Free
                  </p>
                </>
              )}
            </div>
          );
        },
      },
      {
        field: '',
        headerName: '',
        flex: 0.15,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              html: `<p>type <span style="color:red">${classname}</span> to delete student</p>`,
              input: 'text',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                if (classname === value) {
                  const del: any = await deleteAdminStudent(params.row.uuid);
                  if (del.status === 'success') {
                    const newData = tableRow.filter(
                      (item: any) => item.uuid !== params.row.uuid
                    );
                    settableRow(newData);
                    setTotalPages(totalPages - 1);
                    setTotalRow(totalRow - 1);
                    return toast.success(del.message);
                  }
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Incorrect value',
                    text: `you have entered wrong text`,
                  });
                }
                return false;
              },
            });
          };
          const handleResetPassword = () => {
            // adminResetStudentPassword
            Swal.fire({
              title: 'Are you sure want to Reset Password ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                let reset: any = await adminResetStudentPassword(
                  params.row.uuid
                );
                if (reset?.status === 'success') {
                  return toast.success(reset.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="key"
                icon={true}
                onClick={handleResetPassword}
                toottip="reset password"
              />
              {/* <Tooltip title="student detail" ><button  > <IoFileTrayFullSharp  className="text-lg text-green-500" /></button></Tooltip> */}
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit batch"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="edit batch"
              />
              {/* <Tooltip title="delete student" ><button ><FaTrash    onClick={handleDelete} className="text-lg text-red-500" /></button></Tooltip> */}
            </div>
          );
        },
      },
    ];
  }

  const AdminFreeStudents = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminFreeStudents(page);
    } else {
      get = await getAdminFreeStudents(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            phone: item.phone,
            email: item.email,
            dob: item.dob,
            gender: item.gender,
            profile: item?.profileImg,
            subscription: item.studentSubscription,
            created: moment(item?.createAt, 'YYYY-MM-DD HH:mm:ss').format(
              'YYYY-MM-DD'
            ),
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminFreeStudents') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.12,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 0.15,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'dob',
        headerName: 'DOB',
        flex: 0.08,
        minWidth: 50,
        sortable: false,
        renderCell: (params: any) => {
          let dob: any = moment(params.row.dob, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD'
          );

          return <p>{dob}</p>;
        },
      },
      {
        field: 'created',
        headerName: 'Register On',
        flex: 0.08,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.05,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminStudent(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: 'types',
        headerName: 'Type',
        flex: 0.05,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus = params.row.subscription;

          return (
            <div className="flex gap-4 flex-row">
              {params.row.subscription.length > 0 ? (
                <>
                  <p className=" bg-purple2 text-white px-3 py-1 rounded-xl">
                    Paid
                  </p>
                </>
              ) : (
                <>
                  <p className=" bg-gray-600 text-white px-3 py-1 rounded-xl">
                    Free
                  </p>
                </>
              )}
            </div>
          );
        },
      },
      {
        field: '',
        headerName: '',
        flex: 0.15,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              html: `<p>type <span style="color:red">${classname}</span> to delete student</p>`,
              input: 'text',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                if (classname === value) {
                  const del: any = await deleteAdminStudent(params.row.uuid);
                  if (del.status === 'success') {
                    const newData = tableRow.filter(
                      (item: any) => item.uuid !== params.row.uuid
                    );
                    settableRow(newData);
                    setTotalPages(totalPages - 1);
                    setTotalRow(totalRow - 1);
                    return toast.success(del.message);
                  }
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Incorrect value',
                    text: `you have entered wrong text`,
                  });
                }
                return false;
              },
            });
          };
          const handleResetPassword = () => {
            // adminResetStudentPassword
            Swal.fire({
              title: 'Are you sure want to Reset Password ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                let reset: any = await adminResetStudentPassword(
                  params.row.uuid
                );
                if (reset?.status === 'success') {
                  return toast.success(reset.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="key"
                icon={true}
                onClick={handleResetPassword}
                toottip="reset password"
              />
              {/* <Tooltip title="student detail" ><button  > <IoFileTrayFullSharp  className="text-lg text-green-500" /></button></Tooltip> */}
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit batch"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="edit batch"
              />
              {/* <Tooltip title="delete student" ><button ><FaTrash    onClick={handleDelete} className="text-lg text-red-500" /></button></Tooltip> */}
            </div>
          );
        },
      },
    ];
  }

  const AdminbatchStudents = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getStudentsWithBatch(props.id, page);
    } else {
      get = await getStudentsWithBatch(
        props.id,
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            ids: index + rowid,
            uuid: item.id,
            id: item.batchStudent[0].id,
            name: item.name,
            phone: item.phone,
            email: item.email,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminbatchStudents') {
    columns = [
      {
        field: 'ids',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 0.17,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteSingleAdminBatchStudents(
                  props.id,
                  params.row.uuid
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          const handleSubscription = async () => {
            setOpen(!open);
            setModelDate({
              id: props.id,
              stu: params.row.uuid,
            });
          };
          const handlepayments = async () => {
            setOpen1(!open1);
            setModelDate({
              id: props.id,
              stu: params.row.uuid,
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="rupee"
                onClick={handlepayments}
                toottip="manage payments"
                icon={true}
              />
              <TableButton
                title="create"
                onClick={handleSubscription}
                toottip="manage subscription"
                text="Subscription"
                icon={true}
              />
              <TableButton
                title="delete"
                toottip="remove student"
                icon={true}
                onClick={handleDelete}
              />
            </div>
          );
        },
      },
    ];
  }
  const AdminCourseMaster = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminCourseMaster(page);
    } else {
      get = await getAdminCourseMaster(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            onApp: item.on_app,
            slug: item.slug,
            onWeb: item.on_web,
            img: item.image,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };

  if (props.name === 'AdminCourseMaster') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <p
              className=" cursor-pointer font-semibold"
              onClick={() =>
                navigate(`/admin/courses/master/${params.row.slug}`)
              }
            >
              {params.row.name}
            </p>
          );
        },
      },
      {
        field: 'img',
        headerName: 'Image',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-4 flex-row justify-center">
              <a href={params.row.img} target="_blank">
                <img
                  src={params.row.img}
                  className=" w-20 h-14 p-1 rounded-md "
                />
              </a>
            </div>
          );
        },
      },
      {
        field: 'onWeb',
        headerName: 'On Web',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.onWeb;
          const handleChange = async () => {
            let status: boolean = params.row.onWeb;
            const update: any = await updateOnWebAdminCourseMaster(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.onWeb;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, onWeb: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: 'onApp',
        headerName: 'On App',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.onApp;
          const handleChange = async () => {
            let status: boolean = params.row.onApp;
            const update: any = await updateOnAppAdminCourseMaster(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.onApp;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, onApp: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminCourseMaster(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: '',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminCourseMaster(params.row.uuid);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit course master"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete course master"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminCoursecategory = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminCourseCategory(props.id, page);
    } else {
      get = await getAdminCourseCategory(
        props.id,
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            image: item?.image,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminCoursecategory') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-2 items-center">
              <img
                src={params.row.image ? params.row.image : ''}
                className="w-[50px] h-[50px] rounded-full"
                alt="image"
              />
              <p>{params.row.name}</p>
            </div>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminCourseCategory(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: '',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
              master: props.id,
            });
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminCourseCategory(
                  params.row.uuid
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit course master"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete course master"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminCourses = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminCourse(props.id, page);
    } else {
      get = await getAdminCourse(
        props.id,
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            amount: item.amount,
            duration: `${item.duration} days`,
            image: item.image,
            status: item.status,
            on_app: item.on_app,
            required_login: item.required_login,
            courseCategory: item?.courseCategory?.name
              ? item?.courseCategory?.name
              : '-',
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminCourses') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <>
              <img
                src={params.row.image}
                className="w-10 rounded-full h-10 mr-2"
                alt=""
              />{' '}
              {params.row.name}
            </>
          );
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 0.08,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <>
              {params.row.amount === '0' ? (
                'Free'
              ) : (
                <>
                  {' '}
                  <FaRupeeSign /> {params.row.amount}
                </>
              )}
            </>
          );
        },
      },
      {
        field: 'duration',
        headerName: 'Duration',
        flex: 0.08,
        minWidth: 100,
        sortable: false,
      },

      {
        field: 'courseCategory',
        headerName: 'Category',
        flex: 0.12,
        minWidth: 150,
        sortable: false,
      },

      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminCourse(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: '',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            navigate(
              `/admin/courses/master/${props.id}/edit?id=${params.row.uuid}`
            );
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminCourse(params.row.uuid);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          const handleLinks = () => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleInstallments = () => {
            setOpen1(!open1);
            setModelDate({
              id: params.row.uuid,
              amount: params.row.amount,
              days: params.row.duration
            });
          }
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="rupee"
                icon={true}
                onClick={handleInstallments}
                toottip="Installments"
              />
              <TableButton
                title="link"
                icon={true}
                onClick={handleLinks}
                toottip="link course"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit course master"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete course master"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminVideos = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminVideos(page);
    } else {
      get = await getAdminVideos(page, filterModel.quickFilterValues.join(' '));
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            image: item.thumbnail,
            status: item.status,
            length: item.length,
            url: item.url,
            videoType: item.videoType.name,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminVideos') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          let image = params.row.image;
          let imageCount = image.split('/').length;
          if (image.split('/')[2] !== 'i.vimeocdn.com') {
            image = image.replace(
              `${image.split('/')[imageCount - 2]}/${image.split('/')[imageCount - 1]}`,
              `mobile/${image.split('/')[imageCount - 1]}`
            );
          }

          return (
            <div className="flex gap-2 items-center flex-row">
              <img
                src={`${image}`}
                className=" rounded-full h-[45px] w-[45px]"
                alt=""
              />
              <Tooltip title={params.row.name}>
                <p>{truncateString(params.row.name, 14)}</p>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: 'type',
        headerName: 'Category',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let category = 'vid';

          if (params.row.videoType === 'Youtube') {
            category = 'youtube';
          } else if (params.row.videoType === 'Vimeo') {
            category = 'vimeo';
          }
          return (
            <>
              {category === 'youtube' ? (
                <FaYoutube className=" text-red-500 text-2xl" />
              ) : category === 'vimeo' ? (
                <FaVimeo className=" text-blue-500 text-2xl" />
              ) : (
                <FaVideo className=" text-purple-500 text-2xl" />
              )}
            </>
          );
        },
      },
      {
        field: 'length',
        headerName: 'Duration',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const duration = moment.duration(
            Number(params.row.length),
            'minutes'
          );
          const formatted = moment
            .utc(duration.asMilliseconds())
            .format('HH:mm:ss');
          return (
            <div className="flex gap-2 flex-row items-center">
              <FaClock className="text-xl text-gray-500" />
              <p>{formatted}</p>
            </div>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminVideo(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            navigate(`/admin/videos/edit?id=${params.row.uuid}`);
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminVideo(params.row.uuid);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          const handleVideoRelation = () => {
            setOpen1(!open1);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handlePlay = () => {
            let uri = params.row.url;
            setOpen(!open);
            setModelDate({
              id: uri,
              type: params.row.videoType,
              name: params.row.name,
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="play"
                icon={true}
                onClick={handlePlay}
                toottip="play video"
              />
              <TableButton
                title="link"
                icon={true}
                onClick={handleVideoRelation}
                toottip="video relation"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit video"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete video"
              />
            </div>
          );
        },
      },
    ];
  }

  const adminVimeoAccount = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminVimoeAccount(page);
    } else {
      get = await getAdminVimoeAccount(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            token: item.token,
            identifier: item.identifier,
            secret: item.secret,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminVimeoAccount') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.15,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'default',
        headerName: 'Default',
        flex: 0.05,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let id = params.row.uuid;
          const handleChange = async (event: any) => {
            setBtnLoading(true);

            if (localStorage.getItem(`vimoeAccount`)) {
              localStorage.removeItem(`vimoeAccount`);
            }
            const accessToken = params.row.token;
            try {
              const response = await axios.get('https://api.vimeo.com/me', {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              const userId = response.data.uri.split('/').pop();
              let values: any = {
                id: id,
                userId: encryptCryptoData(userId),
                token: encryptCryptoData(params.row.token),
                identifier: encryptCryptoData(params.row.identifier),
                secret: encryptCryptoData(params.row.secret),
                name: encryptCryptoData(params.row.name),
              };
              localStorage.setItem(`vimoeAccount`, JSON.stringify(values));
              setBtnLoading(false);
            } catch (error: any) {
              console.log(error);
              setBtnLoading(false);
              return toast.error(error.message);
            }
            setBtnLoading(false);
          };
          return (
            <div className="flex gap-4 flex-row">
              {JSON.parse(localStorage.getItem(`vimoeAccount`)!)?.id === id ? (
                <button
                  disabled
                  onClick={handleChange}
                  className=" text-white bg-green-700 px-3 py-2 rounded-lg"
                >
                  Current
                </button>
              ) : (
                <button
                  disabled={btnLoading ? true : false}
                  onClick={handleChange}
                  className=" text-white bg-primary px-3 py-2 rounded-lg"
                >
                  {btnLoading ? 'updating...' : 'Set Current'}
                </button>
              )}
            </div>
          );
        },
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async (event: any) => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminVimoeAccount(params.row.uuid);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit video"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete video"
              />
            </div>
          );
        },
      },
    ];
  }
  const AdminVideoModules = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminVideosModule(page);
    } else {
      get = await getAdminVideosModule(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            restriction: item.attempt,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminVideoModules') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },

      // { field: "restriction", headerName: "Restriction", flex: 0.14 ,minWidth: 150,sortable:false},
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminVideoModule(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminVideoModule(params.row.uuid);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="next"
                text="SubModules"
                onClick={() =>
                  navigate(
                    `/admin/videos/modules/${params.row.uuid}?name=${params.row.name}`
                  )
                }
                toottip="open submodules"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit video"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete video"
              />
            </div>
          );
        },
      },
    ];
  }
  const AdminVideoSubModules = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminVideosSubModule(page);
    } else {
      get = await getAdminVideosSubModule(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            // module:item?.videoModule?.name,
            restriction: item.attempt,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminVideoSubModules') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      // { field: "module", headerName: "Module", flex: 0.14 ,minWidth: 150,sortable:false},
      {
        field: 'restriction',
        headerName: 'Restriction',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminVideoSubModule(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminVideoSubModule(
                  params.row.uuid
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              {/* AdminVideoSubModules */}
              <TableButton
                title="video"
                icon={true}
                onClick={() =>
                  navigate(
                    `/admin/videos/list/${params.row.uuid}?name=${params.row.name}&mod=${params.row.moduleId}`
                  )
                }
                toottip="videos"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit video"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete video"
              />
            </div>
          );
        },
      },
    ];
  }
  const AdminVideoModuleSubModules = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminVideosSubModule(page, props.dataId);
    } else {
      get = await getAdminVideosSubModule(
        page,
        props.dataId,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            module: item?.videoModule?.name,
            moduleId: item?.videoModule?.id,
            restriction: item.attempt,
            fk_videosubmodule_id: item.fk_videosubmodule_id,
            types: item?.types,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminVideoModuleSubModules') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-4 flex-row">
              {params.row.types === 'dir' ? (
                <button
                  onClick={() =>
                    navigate(
                      `/admin/videos/submodules/nmod/${props.dataId}/${params.row.uuid}`
                    )
                  }
                  className="flex flex-row gap-2 items-center"
                >
                  <FaFolder className="text-2xl text-yellow-500" />
                  {params.row.name}
                </button>
              ) : (
                <button
                  onClick={() =>
                    navigate(
                      `/admin/videos/list/${params.row.uuid}?name=${params.row.name}&mod=${params.row.moduleId}`
                    )
                  }
                  className="flex flex-row gap-2 items-center"
                >
                  <FaFileAlt className="text-2xl text-blue-400" />
                  {params.row.name}
                </button>
              )}
            </div>
          );
        },
      },
      // { field: "module", headerName: "Module", flex: 0.14 ,minWidth: 150,sortable:false},
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminVideoSubModule(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
              module: props.dataId,
            });
          };
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminVideoSubModule(
                  params.row.uuid
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit sub module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete sub module"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminSubModulesVideoList = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getSubModuleVideosList(props.dataId, page);
    } else {
      get = await getSubModuleVideosList(
        props.dataId,
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            image: item.thumbnail,
            wlimit: item?.watchtime,
            status: item.status,
            length: item.length,
            url: item.url,
            videoType: item.videoType.name,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminSubModulesVideoList') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          let image = params.row.image;
          // let imageCount = image.split('/').length
          // if( image.split('/')[2] !== "i.vimeocdn.com"){
          //   image = image.replace(`${image.split('/')[imageCount-2]}/${image.split('/')[imageCount-1]}`,`mobile/${image.split('/')[imageCount-1]}`)
          // }

          return (
            <div className="flex gap-2 items-center flex-row">
              <img
                src={`${image}`}
                className=" rounded-full h-[45px] w-[45px]"
                alt=""
              />
              <p>{params.row.name}</p>
            </div>
          );
        },
      },
      {
        field: 'type',
        headerName: 'Category',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let category = 'vid';

          if (params.row.videoType === 'Youtube') {
            category = 'youtube';
          } else if (params.row.videoType === 'Vimeo') {
            category = 'vimeo';
          }
          return (
            <>
              {category === 'youtube' ? (
                <FaYoutube className=" text-red-500 text-2xl" />
              ) : category === 'vimeo' ? (
                <FaVimeo className=" text-blue-500 text-2xl" />
              ) : (
                <FaVideo className=" text-purple-500 text-2xl" />
              )}
            </>
          );
        },
      },
      {
        field: 'wlimit',
        headerName: 'Watch Limit',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'length',
        headerName: 'Duration',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const duration = moment.duration(
            Number(params.row.length),
            'minutes'
          );
          const formatted = moment
            .utc(duration.asMilliseconds())
            .format('HH:mm:ss');
          return (
            <div className="flex gap-2 flex-row items-center">
              <FaClock className="text-xl text-gray-500" />
              <p>{formatted}</p>
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async (event: any) => {
            navigate(
              `/admin/videos/edit?id=${params.row.uuid}&sub=${props.dataId}`
            );
          };
          const handleDelete = async (event: any) => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                let datas = {
                  videos: params.row.uuid,
                  fk_submodule_id: props.dataId,
                  fk_module_id: props.dataObject,
                };
                const del: any = await deleteAdminSubMOdVideosRelation(datas);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          const handlePlay = () => {
            let uri = params.row.url;
            console.log(uri);
            setOpen(!open);
            setModelDate({
              id: uri,
              type: params.row.videoType,
              name: params.row.name,
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="play"
                icon={true}
                onClick={handlePlay}
                toottip="play video"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit video"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete video"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminSubModulesVideoNotVideo = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getSubModuleVideosNotVideos(props.dataId, page);
    } else {
      get = await getSubModuleVideosNotVideos(
        props.dataId,
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: item.id,
            uuid: index + rowid,
            name: item.name,
            image: item.thumbnail,
            status: item.status,
            length: item.length,
            url: item.url,
            videoType: item.videoType.name,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminSubModulesVideoNotVideo') {
    columns = [
      {
        field: 'uuid',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          let image = params.row.image;
          let imageCount = image.split('/').length;
          if (image.split('/')[2] !== 'i.vimeocdn.com') {
            image = image.replace(
              `${image.split('/')[imageCount - 2]}/${image.split('/')[imageCount - 1]}`,
              `mobile/${image.split('/')[imageCount - 1]}`
            );
          }

          return (
            <div className="flex gap-2 items-center flex-row">
              <img
                src={`${image}`}
                className=" rounded-full h-[45px] w-[45px]"
                alt=""
              />
              <p>{params.row.name}</p>
            </div>
          );
        },
      },
      {
        field: 'type',
        headerName: 'Category',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let category = 'vid';

          if (params.row.videoType === 'Youtube') {
            category = 'youtube';
          } else if (params.row.videoType === 'Vimeo') {
            category = 'vimeo';
          }
          return (
            <>
              {category === 'youtube' ? (
                <FaYoutube className=" text-red-500 text-2xl" />
              ) : category === 'vimeo' ? (
                <FaVimeo className=" text-blue-500 text-2xl" />
              ) : (
                <FaVideo className=" text-purple-500 text-2xl" />
              )}
            </>
          );
        },
      },
      {
        field: 'length',
        headerName: 'Duration',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const duration = moment.duration(
            Number(params.row.length),
            'minutes'
          );
          const formatted = moment
            .utc(duration.asMilliseconds())
            .format('HH:mm:ss');
          return (
            <div className="flex gap-2 flex-row items-center">
              <FaClock className="text-xl text-gray-500" />
              <p>{formatted}</p>
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handlePlay = () => {
            let uri = params.row.url;
            setOpen(!open);
            setModelDate({
              id: uri,
              type: params.row.videoType,
              name: params.row.name,
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="play"
                icon={true}
                onClick={handlePlay}
                toottip="play video"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminTestSeriesModules = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminTestSeriesModules(page);
    } else {
      get = await getAdminTestSeriesModules(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: item.id,
            uuid: index + rowid,
            name: item.name,
            status: item.status,
            attempt: item.attempt,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminTestSeriesModules') {
    columns = [
      {
        field: 'uuid',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-4 flex-row">
              <button
                onClick={() =>
                  navigate(
                    `/admin/test-series/submodules/${params.row.id}?bredcrum=${params.row.name}`
                  )
                }
              >
                {params.row.name}
              </button>
            </div>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminTestSeriesModules(
              params.row.id,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.id,
              name: params.row.name,
            });
          };
          const handleDelete = async () => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminTestSeriesModules(
                  params.row.id
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.id !== params.row.id
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit  module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete  module"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminTestSeriesSubModules = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminTestSeriesSubModules(
        props.dataId,
        props.dataObject,
        page
      );
    } else {
      get = await getAdminTestSeriesSubModules(
        props.dataId,
        props.dataObject,
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: item.id,
            uuid: index + rowid,
            name: item.name,
            status: item.status,
            attempt: item.attempt,
            dir: item?.fk_dir_id,
            marks: item?.marks,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminTestSeriesSubModules') {
    columns = [
      {
        field: 'uuid',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleNavigation = () => {
            if (props.dataObject === 'single') {
              navigate(
                `/admin/test-series/nsubmodules/${props.dataId}/${params.row.id}?bredcrum=${params.row.name}`
              );
            } else {
              navigate(
                `/admin/test-series/nsubmodules-group-test/${props.dataId}/${params.row.id}?bredcrum=${params.row.name}&md=${props.otherId[0]}&cat=${props.otherId[1]}`
              );
            }
          };
          const handleFileNavigation = () => {
            if (props.dataObject === 'single') {
              navigate(
                `/admin/test-series/submodules/questions/${params.row.id}?bredcrum=${params.row.name}`
              );
            } else {
              navigate(
                `/admin/test-series/submodules-group-test/questions/${params.row.id}?bredcrum=${params.row.name}&mod=smod&md=${props.otherId[0]}&cat=${props.otherId[1]}`
              );
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              {params.row.marks === null ? (
                <button
                  onClick={handleNavigation}
                  className="flex flex-row gap-2 items-center"
                >
                  <FaFolder className="text-2xl text-yellow-500" />
                  {params.row.name}
                </button>
              ) : (
                <button
                  onClick={handleFileNavigation}
                  className="flex flex-row gap-2 items-center"
                >
                  <FaFileAlt className="text-2xl text-blue-400" />
                  {params.row.name}
                </button>
              )}
            </div>
          );
        },
      },
      {
        field: 'attempt',
        headerName: 'Attempt',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminTestSeriesSubModules(
              params.row.id,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.id,
              name: params.row.name,
            });
          };
          const handleDelete = async () => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminTestSeriesSubModules(
                  params.row.id
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.id !== params.row.id
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit  module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete  module"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminTestSeriesNSubModules = async () => {
    setloading(true);
    let get;
    console.log(props.id, '---', props.dataObject);
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminTestSeriesNSubModules(
        props.id,
        props.dataObject,
        page
      );
    } else {
      get = await getAdminTestSeriesNSubModules(
        props.id,
        props.dataObject,
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: item.id,
            uuid: index + rowid,
            name: item.name,
            status: item.status,
            attempt: item.attempt,
            dir: item?.fk_dir_id,
            marks: item?.marks,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminTestSeriesNSubModules') {
    columns = [
      {
        field: 'uuid',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          console.log(props.dataObject);
          const handleNavigation = () => {
            if (props.dataObject === 'single') {
              navigate(
                `/admin/test-series/nsubmodules/${props.dataId}/${params.row.id}?bredcrum=${params.row.name}`
              );
            } else {
              navigate(
                `/admin/test-series/nsubmodules-group-test/${props.dataId}/${params.row.id}?bredcrum=${params.row.name}&md=${props.otherId[0]}`
              );
            }
          };
          const handleFileNavigation = () => {
            if (props.dataObject === 'single') {
              navigate(
                `/admin/test-series/submodules/questions/${params.row.id}?bredcrum=${params.row.name}`
              );
            } else {
              navigate(
                `/admin/test-series/submodules-group-test/questions/${params.row.id}?bredcrum=${params.row.name}&mod=nmod&md=${props.otherId[0]}`
              );
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              {params.row.marks === null ? (
                <button
                  onClick={handleNavigation}
                  className="flex flex-row gap-2 items-center"
                >
                  <FaFolder className="text-2xl text-yellow-500" />
                  {params.row.name}
                </button>
              ) : (
                <button
                  onClick={handleFileNavigation}
                  className="flex flex-row gap-2 items-center"
                >
                  <FaFileAlt className="text-2xl text-blue-400" />
                  {params.row.name}
                </button>
              )}
            </div>
          );
        },
      },
      {
        field: 'attempt',
        headerName: 'Attempt',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminTestSeriesSubModules(
              params.row.id,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.id,
              name: params.row.name,
            });
          };
          const handleDelete = async () => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminTestSeriesSubModules(
                  params.row.id
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.id !== params.row.id
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit  module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete  module"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminTestSeriesQuestion = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminTestSeriesSubQuestion(props.id, page);
    } else {
      get = await getAdminTestSeriesSubQuestion(
        props.id,
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    // props?.otherId(get.message?.totalRow)
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: item.id,
            uuid: index + rowid,
            testSubModules: item.testSubModules,
            question: item.question,
            testtype: item.testtype.name,
            status: item.status,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminTestSeriesQuestion') {
    columns = [
      {
        field: 'uuid',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'testtype',
        headerName: 'Type',
        flex: 0.03,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'question',
        headerName: 'Question',
        flex: 0.4,
        minWidth: 200,
        sortable: false,
        renderCell: (params: any) => {
          let firstLine = params.row.question.match(
            /<p>.*?<\/p>|<li>.*?<\/li>/i
          )?.[0];
          if (!firstLine) {
            firstLine = params.row.question;
          }
          return <div className="">{parse(firstLine)}</div>;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminTestSeriesQuestion(
              params.row.id,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.id,
              name: params.row.name,
            });
          };
          const handleDelete = async () => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminTestSeriesQuestion(
                  params.row.id
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.id !== params.row.id
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={() =>
                  props.dataId === 'group'
                    ? navigate(
                        `/admin/test-series/submodules-group-test/questions/edit/${params.row.id}${props.otherId}`
                      )
                    : navigate(
                        `/admin/test-series/submodules/questions/edit/${props.id}/${params.row.id}`
                      )
                }
                toottip="edit  module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete  module"
              />
            </div>
          );
        },
      },
    ];
  }

  const adminLiveClassSubModules = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminLiveClassSubmodule(page);
    } else {
      get = await getAdminLiveClassSubmodule(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: item.id,
            uuid: index + rowid,
            name: item.name,
            status: item.status,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminLiveClassSubModules') {
    columns = [
      {
        field: 'uuid',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.3,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.2,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminLiveClassSubmodule(
              params.row.id,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.id,
              name: params.row.name,
            });
          };
          const handleDelete = async () => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminLiveClassSubmodule(
                  params.row.id
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.id !== params.row.id
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit  module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete  module"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminLiveClassAccounts = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminLiveClassAccount(page);
    } else {
      get = await getAdminLiveClassAccount(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: item.id,
            uuid: index + rowid,
            name: item.email,
            status: item.status,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminLiveClassAccounts') {
    columns = [
      {
        field: 'uuid',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.3,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.2,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminLiveClassAccount(
              params.row.id,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.id,
              name: params.row.name,
            });
          };
          const handleDelete = async () => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminLiveClassAccount(
                  params.row.id
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.id !== params.row.id
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit  module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete  module"
              />
            </div>
          );
        },
      },
    ];
  }

  const adminLiveClass = async () => {
    setloading(true);
    let get: any;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminLiveClasses(page);
    } else {
      get = await getAdminLiveClasses(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: item.id,
            uuid: index + rowid,
            title: item.title,
            datetime: item.datetime,
            duration: item.duration,
            meeting_number: item.meeting_number,
            lms_live_Class: get.message?.joinFrom?.institute.lms_live_Class,
            uri: item?.url,
          });
        });
      }
    }

    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminLiveClass') {
    columns = [
      {
        field: 'uuid',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'title',
        headerName: 'Title',
        flex: 0.2,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'date',
        headerName: 'Date',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-1 flex-row items-center font-semibold">
              {moment(params.row.datetime).format('Do MMMM YYYY')}
            </div>
          );
        },
      },
      {
        field: 'time',
        headerName: 'time',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let tm = params.row.datetime;
          return (
            <div className="flex gap-1 flex-row items-center font-semibold">
              {moment(tm).format('h:mm a')} -{' '}
              {moment(params.row.datetime)
                .add(params.row.duration, 'minutes')
                .format('h:mm a')}
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.id,
              name: params.row.title,
            });
          };
          const handleDelete = async () => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminLiveClasses(params.row.id);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.id !== params.row.id
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          const handleLiveClass = () => {
            if (params.row.lms_live_Class) {
              navigate(
                `/admin/live-class/join/${encryptUUID(params.row.meeting_number)}`
              );
            } else {
              window.open(params.row.uri, '_blank', 'noopener,noreferrer');
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="video"
                icon={true}
                text="Start Class"
                onClick={handleLiveClass}
                toottip="start class"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit  module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete  module"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminInstallments = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminPaidStudents(page);
    } else {
      get = await getAdminPaidStudents(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            phone: item.phone,
            email: item.email,
            dob: item.dob,
            gender: item.gender,
            profile: item?.profileImg,
            subscription: item.studentSubscription,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminInstallments') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 0.17,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'dob',
        headerName: 'DOB',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          let dob: any = moment(params.row.dob, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD'
          );

          return <p>{dob}</p>;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminStudent(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },

      {
        field: '',
        headerName: '',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handlepayments = async () => {
            setOpen1(!open1);
            setModelDate({
              id: props.id,
              stu: params.row.uuid,
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="rupee"
                onClick={handlepayments}
                toottip="manage payments"
                icon={true}
                text="Installments"
              />
              {/* <Tooltip title="student detail" ><button  > <IoFileTrayFullSharp  className="text-lg text-green-500" /></button></Tooltip> */}
              {/* <TableButton title="edit" icon={true}   onClick={handleChange} toottip="edit batch" /> */}
              {/* <Tooltip title="delete student" ><button ><FaTrash    onClick={handleDelete} className="text-lg text-red-500" /></button></Tooltip> */}
            </div>
          );
        },
      },
    ];
  }
  const adminExamAlert = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminExamAlert(page);
    } else {
      get = await getAdminExamAlert(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            year: item.year,
            exam_date: moment(item.exam_date).format('DD-MM-YYYY'),
            created_at: moment(item?.createAt).format('DD-MM-YYYY'),
            url: item.pdf,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminExamAlert') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Exam Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'year',
        headerName: 'Year',
        flex: 0.17,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'exam_date',
        headerName: 'Exam Date',
        flex: 0.17,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminExamAlert(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },

      {
        field: '',
        headerName: '',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen1(!open1);
            setModelDate({
              id: params.row.uuid,
              name: params.row.title,
            });
          };
          const handleDelete = async () => {
            let classname = `delete ${params.row.name}`;
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminExamAlert(params.row.uuid);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          const handlePdf = () => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
              name: params.row.title,
              url: params.row.url,
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="pdf"
                onClick={handlePdf}
                toottip="manage pdfs"
                icon={true}
              />
              {/* <Tooltip title="student detail" ><button  > <IoFileTrayFullSharp  className="text-lg text-green-500" /></button></Tooltip> */}
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit exam alert"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete exam alert"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminFeeds = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminFeed(page);
    } else {
      get = await getAdminFeed(page, filterModel.quickFilterValues.join(' '));
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            title: item.name ? item.name : '-',
            status: item.status,
            url: item.url,
            feedType: item.feedsType.name,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminFeeds') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'title',
        headerName: 'Title',
        flex: 0.4,
        minWidth: 200,
        sortable: false,
        renderCell: (params: any) => {
          let firstLine = params.row.title;
          let data: any;
          if (firstLine !== null) {
            data = parse(firstLine);
            data = data[0]?.props?.children;
          }
          return (
            <div className="">
              {params.row.url ? (
                <img src={params.row.url} className="h-[50px]" />
              ) : (
                data
              )}
            </div>
          );
        },
      },
      {
        field: 'feedType',
        headerName: 'Type',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminFeed(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },

      {
        field: '',
        headerName: '',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen1(!open1);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminFeed(params.row.uuid);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          const handleWatchModel = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="watch"
                icon={true}
                onClick={handleWatchModel}
                toottip="watch feed"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit feed"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete feed"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminPromotionalContent = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminpromotional(page);
    } else {
      get = await getAdminpromotional(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            type: item.type === 0 ? 'Image' : item.type === 1 ? 'Video' : '-',
            status: item.status,
            url: item.url,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminPromotionalContent') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'url',
        headerName: '#',
        flex: 0.4,
        minWidth: 200,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="">
              {params.row.type === 'Image' ? (
                <img src={params.row.url} className="h-[50px]" />
              ) : null}
              {params.row.type === 'Video' ? (
                <FaVideo className="text-3xl text-purple-500" />
              ) : null}
            </div>
          );
        },
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminpromotional(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },

      {
        field: '',
        headerName: '',
        flex: 0.2,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen1(!open1);
            setModelDate({
              id: params.row.uuid,
            });
          };
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminpromotional(params.row.uuid);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          const handleWatchModel = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
              type: params.row.type,
              url: params.row.url,
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="watch"
                icon={true}
                onClick={handleWatchModel}
                toottip="watch content"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete"
              />
            </div>
          );
        },
      },
    ];
  }
  const AdminVideoNSubModules = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminNSubMOdules(props.id, page);
    } else {
      get = await getAdminNSubMOdules(
        props.id,
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            module: item?.videoModule?.name,
            moduleId: item?.videoModule?.id,
            restriction: item.attempt,
            fk_videosubmodule_id: item.fk_videosubmodule_id,
            types: item?.types,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminVideoNSubModules') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-4 flex-row">
              {params.row.types === 'dir' ? (
                <button
                  onClick={() =>
                    navigate(
                      `/admin/videos/submodules/nmod/${props.dataId}/${params.row.uuid}`
                    )
                  }
                  className="flex flex-row gap-2 items-center"
                >
                  <FaFolder className="text-2xl text-yellow-500" />
                  {params.row.name}
                </button>
              ) : (
                <button
                  onClick={() =>
                    navigate(
                      `/admin/videos/list/${params.row.uuid}?name=${params.row.name}&mod=${params.row.moduleId}`
                    )
                  }
                  className="flex flex-row gap-2 items-center"
                >
                  <FaFileAlt className="text-2xl text-blue-400" />
                  {params.row.name}
                </button>
              )}
            </div>
          );
        },
      },
      // { field: "module", headerName: "Module", flex: 0.14 ,minWidth: 150,sortable:false},
      {
        field: 'restriction',
        headerName: 'Restriction',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminVideoSubModule(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
              module: props.dataId,
            });
          };
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminVideoSubModule(
                  params.row.uuid
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit sub module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete sub module"
              />
            </div>
          );
        },
      },
    ];
  }

  const adminCouponMaster = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminCouponMaster(page);
    } else {
      get = await getAdminCouponMaster(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            coupon: item.name,
            status: item.status,
            end_date: moment(item?.end_date).format('YYYY-MM-DD'),
            start_date: moment(item?.start_date).format('YYYY-MM-DD'),
            discountType: item?.discount_type?.name,
            discount: item?.discount_value,
            uses: item?.max_uses,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminCouponMaster') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'coupon',
        headerName: 'Coupon',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'uses',
        headerName: 'Max Uses',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'date',
        headerName: 'Start Date',
        flex: 0.15,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex items-center gap-2">
              <span>{params.row.start_date}</span> -{' '}
              <span>{params.row.end_date}</span>
            </div>
          );
        },
      },
      {
        field: 'discountType',
        headerName: 'Discount Type',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'discount',
        headerName: 'Discount',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await updateStatusAdminCouponMaster(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate(params.row.uuid);
          };
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminCouponMaster(params.row.uuid);
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="course"
                icon={true}
                onClick={() =>
                  navigate(
                    `/admin/courses/master/coupon/master/${params.row.uuid}`
                  )
                }
                toottip="courses"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit sub module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete sub module"
              />
            </div>
          );
        },
      },
    ];
  }

  const adminPendingPayments = async () => {
    setloading(true);
    let get;
    if (
      filterModel === null ||
      filterModel === undefined ||
      filterModel === ''
    ) {
      get = await getAdminPendingPayments(page);
    } else {
      get = await getAdminPendingPayments(
        page,
        filterModel.quickFilterValues.join(' ')
      );
    }
    setTotalPages(get.message?.totalPage * pageSize);
    setTotalRow(get.message?.totalRow);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.data?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            studentname: item?.student?.name,
            studentphone: item?.student?.phone,
            course: item?.course?.name,
            date: moment(item?.createAt).format('YYYY-MM-DD'),
            method: item?.adminPaymentMaster?.name,
            transactionid: item?.transaction_id,
            remark: item?.remark,
            amount: item?.amount,
            installments:item?.is_installment ? 'Yes' : 'No'
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminPendingPayments') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 80,
        sortable: false,
      },
      {
        field: 'studentname',
        headerName: 'Student Name',
        flex: 0.15,
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'studentphone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'course',
        headerName: 'Course Name',
        flex: 0.12,
        minWidth: 120,
        sortable: false,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 0.08,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          return <>
          <div className='flex gap-1 justify-start items-center'>
            <FaRupeeSign /> <p>{params.row.amount}</p>
          </div>
          </>
        },
      },
      {
        field: 'date',
        headerName: 'Date',
        flex: 0.08,
        minWidth: 80,
        sortable: false,
      },
      {
        field: 'method',
        headerName: 'Method',
        flex: 0.08,
        minWidth: 80,
        sortable: false,
      },
      {
        field: 'installments',
        headerName: 'installments',
        flex: 0.08,
        minWidth: 80,
        sortable: false,
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.14,
        minWidth: 150,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            Swal.fire({
              title: 'Are you sure, want to approve payment?',
              icon: 'warning',
              confirmButtonColor: '#9333ea',
              confirmButtonText: 'Yes, Approve',
              showCancelButton: true,
              cancelButtonColor: '#d33',
              denyButtonColor: '#1f2937',
              denyButtonText: `Reject`,
              showDenyButton: true,
            }).then(async (result) => {
              if (result.isConfirmed) {
                let cr: any = await courseOrderApprove(params.row.uuid);
                if (cr?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(cr?.message);
                }
              } else if (result.isDenied) {
                Swal.fire({
                  title: 'Reason of rejection payment',
                  input: 'text',
                  inputAttributes: {
                    autocapitalize: 'off',
                  },
                  showCancelButton: true,
                  confirmButtonText: 'Submit',
                  showLoaderOnConfirm: true,
                  preConfirm: async (login) => {
                    let deny: any = await courseOrderReject(
                      params.row.uuid,
                      login
                    );
                    if (deny?.status === 'success') {
                      const newData = tableRow.filter(
                        (item: any) => item.uuid !== params.row.uuid
                      );
                      settableRow(newData);
                      setTotalPages(totalPages - 1);
                      setTotalRow(totalRow - 1);
                      return toast.success(deny?.message);
                    }
                  },
                  allowOutsideClick: () => !Swal.isLoading(),
                });
              }
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <button
                onClick={handleChange}
                className="px-5 py-2 bg-purple-600 text-white font-semibold rounded-xl"
              >
                Update
              </button>
            </div>
          );
        },
      },
    ];
  }

  useEffect(() => {
    if (props.name === 'adminInstallments') {
      adminInstallments();
    }
    if (props.name === 'adminSubModulesVideoNotVideo') {
      adminSubModulesVideoNotVideo();
    }
    if (props.name === 'adminSubModulesVideoList') {
      adminSubModulesVideoList();
    }
    if (props.name === 'AdminbatchStudents') {
      AdminbatchStudents();
    }
    if (props.name === 'adminVideos') {
      adminVideos();
    }
    // if(props.name === "superAdminCenterList"){
    //   superAdminCenterList()
    // }
    // if(props.name === "superAdmin_admins"){
    //   superAdmin_admins()
    // }
    // if(props.name === "adminBatches"){
    //   adminBatches()
    // }
  }, [
    props.refresh,
    page,
    pageSize,
    setFilterModel,
    filterModel,
    props.dataObject,
  ]);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRow !== undefined ? totalRow : prevRowCountState
    );
  }, [totalRow]);

  // useEffect(()=>{
  //   if(props.name === "adminTestSeriesModules"){
  //     adminTestSeriesModules()
  //   }
  //   if(props.name === "adminTestSeriesSubModules"){
  //     adminTestSeriesSubModules()
  //   }
  // },[setFilterModel])

  useEffect(() => {
    if (props.name === 'adminPendingPayments') {
      adminPendingPayments();
    }
    if (props.name === 'adminCouponMaster') {
      adminCouponMaster();
    }
    if (props.name === 'AdminVideoNSubModules') {
      AdminVideoNSubModules();
    }
    if (props.name === 'adminPromotionalContent') {
      adminPromotionalContent();
    }
    if (props.name === 'adminFeeds') {
      adminFeeds();
    }
    if (props.name === 'AdminVideoModuleSubModules') {
      AdminVideoModuleSubModules();
    }
    if (props.name === 'AdminVideoSubModules') {
      AdminVideoSubModules();
    }
    if (props.name === 'AdminVideoModules') {
      AdminVideoModules();
    }
    if (props.name === 'adminVimeoAccount') {
      adminVimeoAccount();
    }
    if (props.name === 'AdminCourseMaster') {
      AdminCourseMaster();
    }
    if (props.name === 'superAdminCenterList') {
      superAdminCenterList();
    }
    if (props.name === 'AdminAllStudents') {
      AdminAllStudents();
    }
    if (props.name === 'AdminPaidStudents') {
      AdminPaidStudents();
    }
    if (props.name === 'AdminFreeStudents') {
      AdminFreeStudents();
    }
    if (props.name === 'superAdmin_admins') {
      superAdmin_admins();
    }
    if (props.name === 'adminBatches') {
      adminBatches();
    }
    if (props.name === 'adminCoursecategory') {
      adminCoursecategory();
    }
    if (props.name === 'adminTestSeriesModules') {
      adminTestSeriesModules();
    }
    if (props.name === 'adminTestSeriesSubModules') {
      adminTestSeriesSubModules();
    }
    if (props.name === 'adminTestSeriesNSubModules') {
      adminTestSeriesNSubModules();
    }
    if (props.name === 'adminLiveClassSubModules') {
      adminLiveClassSubModules();
    }
    if (props.name === 'adminLiveClassAccounts') {
      adminLiveClassAccounts();
    }
    if (props.name === 'adminLiveClass') {
      adminLiveClass();
    }
    if (props.name === 'adminExamAlert') {
      adminExamAlert();
    }

    if (props.name === 'adminTestSeriesQuestion') {
      adminTestSeriesQuestion();
    }
    setRowCountState((prevRowCountState) =>
      totalRow !== undefined ? totalRow : prevRowCountState
    );
  }, [
    contentUpdate,
    setFilterModel,
    filterModel,
    props.refresh,
    page,
    pageSize,
  ]);

  useEffect(() => {
    if (props.id) {
      if (props.name === 'adminCoursecategory') {
        adminCoursecategory();
      }
      if (props.name === 'adminCourses') {
        adminCourses();
      }
      if (props.name === 'adminTestSeriesNSubModules') {
        adminTestSeriesNSubModules();
      }
    }
  }, [props.id, setFilterModel, filterModel]);

  const onPaginationModelChange = (paginationModel: any) => {
    setRowid(10 * paginationModel.page + 1);
    setPage(paginationModel.page + 1);
    setPaginationModel(paginationModel);
  };
  useEffect(() => {
    // const selectedData = rowSelectionModel.map((id:any) => tableRow.find((item:any, index:number) => item.id === id));
    if (props.onSubmit) {
      props.onSubmit(rowSelectionModel);
    }
  }, [rowSelectionModel]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ height: props.height ? props.height : 450, width: 1 }}
          className={` shadow-md rounded-xl p-2 ${localStorage.getItem('theme') === 'dark' ? ' bg-dark-purple1' : 'bg-gray-200'} `}
        >
          <DataGrid
            sx={{ border: 0, width: '100%' }}
            rows={tableRow}
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            onRowSelectionModelChange={(newRowSelectionModel: any) => {
              // const seletedId:any = tableRow.filter((row:any)=> row.id === newRowSelectionModel[0])
              //    setRowSelectionModel(seletedId);
              if (newRowSelectionModel.length > 0) {
                setRowSelectionModel([...newRowSelectionModel]);
              } else {
                setRowSelectionModel([]);
              }
            }}
            keepNonExistentRowsSelected
            checkboxSelection={props.checkbox}
            rowCount={rowCountState}
            columns={columns}
            loading={loading}
            paginationMode="server"
            paginationModel={paginationModel}
            // onPaginationModelChange={setPaginationModel}
            onPaginationModelChange={onPaginationModelChange}

            // Set the total row count
          />
        </Box>
      </ThemeProvider>
      {props.name === 'superAdminCenterList' ? (
        <Models name="Edit New Center" isOpen={open} isClose={setOpen}>
          <SuperAdminCenterCE type="edit" centerId={modelData} open={setOpen} />
        </Models>
      ) : null}
      {props.name === 'superAdmin_admins' ? (
        <Models name="Edit  Center" isOpen={open} isClose={setOpen}>
          <SuperAdmin_adminCE type="edit" data={modelData} open={setOpen} />
        </Models>
      ) : null}
      {props.name === 'adminBatches' ? (
        <Models name="Edit Batch" isOpen={open} isClose={setOpen}>
          <AdminBatchCE type="edit" data={modelData} open={setOpen} />
        </Models>
      ) : null}
      {props.name === 'AdminAllStudents' ? (
        <Models name="Edit Student" isOpen={open} isClose={setOpen}>
          <AdminStudentCE type="edit" data={modelData} isOpen={setOpen} />
        </Models>
      ) : null}
      {props.name === 'AdminCourseMaster' ? (
        <Models name="Edit Course Master" isOpen={open} isClose={setOpen}>
          <AdminCourseMasterCE type="edit" data={modelData} open={setOpen} />
        </Models>
      ) : null}
      {props.name === 'adminCoursecategory' ? (
        <Models name="Edit Course category" isOpen={open} isClose={setOpen}>
          <AdminCourseCategoryCE type="edit" data={modelData} open={setOpen} />
        </Models>
      ) : null}
      {props.name === 'adminBatches' ? (
        <Models name="Batch Courses" isOpen={open1} isClose={setOpen1}>
          <AdminBatchCourseCE data={modelData} />
        </Models>
      ) : null}
      {props.name === 'AdminbatchStudents' ? (
        <Models name="Create Subscription" isOpen={open} isClose={setOpen}>
          <AdminBatchSubscription data={modelData} open={setOpen} />
        </Models>
      ) : null}
      {props.name === 'AdminbatchStudents' ? (
        <Models name="Manage Payments" width="md:w-[70vw]" isOpen={open1} isClose={setOpen1}>
          <AdminPaymentInstallments data={modelData} open={setOpen1} />
        </Models>
      ) : null}
      {props.name === 'adminVimeoAccount' ? (
        <Models name="Update Account" isOpen={open} isClose={setOpen}>
          <AdminVimeoAccountCE type="edit" data={modelData} isOpen={setOpen} />
        </Models>
      ) : null}
      {props.name === 'AdminVideoModules' ? (
        <Models name="Update Account" isOpen={open} isClose={setOpen}>
          <VimeoModuleCE type="edit" data={modelData} isOpen={setOpen} />
        </Models>
      ) : null}
      {props.name === 'AdminVideoSubModules' ? (
        <Models name="Update Account" isOpen={open} isClose={setOpen}>
          <VideoSubModuleCE type="edit" data={modelData} isOpen={setOpen} />
        </Models>
      ) : null}
      {props.name === 'AdminVideoModuleSubModules' ? (
        <Models name="Update Sub module" isOpen={open} isClose={setOpen}>
          <VideoSubModuleCE type="edit" data={modelData} isOpen={setOpen} />
        </Models>
      ) : null}
      {props.name === 'adminVideos' ? (
        <>
          <Models name="Video Relation" isOpen={open1} isClose={setOpen1}>
            <AdminVideoRelation data={modelData} isOpen={setOpen1} />
          </Models>
        </>
      ) : null}
      {props.name === 'adminVideos' ||
      props.name === 'adminSubModulesVideoNotVideo' ||
      props.name === 'adminSubModulesVideoList' ? (
        <Models name={modelData.name} isOpen={open} isClose={setOpen}>
          {modelData.type === 'Vimeo' ? (
            <VimeoPlayer id={modelData.id} />
          ) : null}
          {modelData.type === 'Youtube' ? (
            <YoutubePlayer url={modelData.id} />
          ) : null}
        </Models>
      ) : null}

      {props.name === 'adminTestSeriesModules' ? (
        <Models name={`Edit ${modelData.name}`} isOpen={open} isClose={setOpen}>
          <AdminTestSeriesModulesCE
            data={modelData}
            type="edit"
            open={setOpen}
          />
        </Models>
      ) : null}
      {props.name === 'adminTestSeriesNSubModules' ? (
        <Models name={`Edit ${modelData.name}`} isOpen={open} isClose={setOpen}>
          <AdminTestSubModulesCE
            data={modelData}
            category="snmod"
            type="edit"
            open={setOpen}
          />
        </Models>
      ) : null}
      {props.name === 'adminTestSeriesSubModules' ? (
        <Models name={`Edit ${modelData.name}`} isOpen={open} isClose={setOpen}>
          <AdminTestSubModulesCE
            category="smod"
            data={modelData}
            type="edit"
            open={setOpen}
          />
        </Models>
      ) : null}
      {props.name === 'adminLiveClassSubModules' ? (
        <Models name={`Edit ${modelData.name}`} isOpen={open} isClose={setOpen}>
          <AdminLiveClassSubModCE data={modelData} type="edit" open={setOpen} />
        </Models>
      ) : null}
      {props.name === 'adminLiveClassAccounts' ? (
        <Models name={`Edit ${modelData.name}`} isOpen={open} isClose={setOpen}>
          <AdminLiveClassAccountCE
            data={modelData}
            type="edit"
            open={setOpen}
          />
        </Models>
      ) : null}
      {props.name === 'adminLiveClass' ? (
        <Models name={`Edit ${modelData.name}`} isOpen={open} isClose={setOpen}>
          <AdminLiveClassCE data={modelData} type="edit" open={setOpen} />
        </Models>
      ) : null}

      {props.name === 'adminInstallments' ? (
        <Models
          width="md:w-[90vw]"
          name="Manage Payments"
          isOpen={open1}
          isClose={setOpen1}
        >
          <AdminPaymentInstallments data={modelData} open={setOpen1} />
        </Models>
      ) : null}
      {props.name === 'adminExamAlert' ? (
        <>
          <Models
            width="w-[40vw]"
            name="Edit Exam Alert"
            isOpen={open1}
            isClose={setOpen1}
          >
            <AdminExamAlertCE type="edit" data={modelData} open={setOpen} />
          </Models>
          <Models
            width="w-[40vw]"
            name="Exam Alert Pdf"
            isOpen={open}
            isClose={setOpen}
          >
            <>
              <WordUpload
                name="adminExamAlertPdf"
                data={modelData.id}
                accept=".pdf"
                open={setOpen}
              />
              {modelData?.url !== null ? (
                <div className="flex justify-center bg-purple2 text-white p-1 rounded-lg">
                  <a
                    href={modelData?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download PDF
                  </a>
                </div>
              ) : null}
            </>
          </Models>
        </>
      ) : null}
      {props.name === 'adminFeeds' ? (
        <>
          <Models name={`Edit Feed`} isOpen={open1} isClose={setOpen1}>
            <AdminFeedsCE data={modelData} type="edit" open={setOpen1} />
          </Models>
          <Models
            width="w-[30vw]"
            name={`Feed`}
            isOpen={open}
            isClose={setOpen}
          >
            <FeedModels data={modelData} open={setOpen} />
          </Models>
        </>
      ) : null}
      {props.name === 'adminPromotionalContent' ? (
        <>
          <Models
            width="w-[30vw]"
            name={`Feed`}
            isOpen={open}
            isClose={setOpen}
          >
            <div className="flex items-center justify-center p-3 bg-gray-950 flex-col">
              {modelData?.type === 'Image' ? (
                <img src={modelData?.url} className="" />
              ) : null}
              {modelData?.type === 'Video' ? (
                <Player url={modelData?.url} />
              ) : null}
            </div>
          </Models>
        </>
      ) : null}
      {props.name === 'adminCourses' ? (
        <Models
          width="w-[40vw]"
          name={`Content Relation`}
          isOpen={open}
          isClose={setOpen}
        >
          <AdminCourseRelation data={modelData} />
        </Models>
      ) : null}
      {props.name === 'adminCourses' ? (
        <Models
          width="w-[40vw]"
          name={`Course Installments`}
          isOpen={open1}
          isClose={setOpen1}
        >
          <AdminCourseEMI data={modelData} />
        </Models>
      ) : null}
      {props.name === 'adminCouponMaster' ? (
        <Models name={`Update Coupon Master`} isOpen={open} isClose={setOpen}>
          <AdminCouponMasterEdit id={modelData} open={setOpen} />
        </Models>
      ) : null}
      {props.name === 'adminPendingPayments' ? (
        <Models name={`Remarks`} isOpen={open} isClose={setOpen}>
          <div className="flex justify-center">
            <img src={modelData} className=" h-[80%] " alt="" />
          </div>
        </Models>
      ) : null}
    </>
  );
}
