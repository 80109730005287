import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import {
  FaAngleDown,
  FaBook,
  FaCalendarTimes,
  FaDotCircle,
  FaRupeeSign,
  FaStopwatch,
  FaVideo,
} from 'react-icons/fa';
import { FaBookAtlas } from 'react-icons/fa6';
import {
  getStudentLiveClasses,
  StudentCourseList,
  StudentCourseTestStats,
  StudentCourseVideoStats,
  StudentDashboardFeeds,
} from '../../service/student/student.service';
import DataNotFound from '../../components/errors/DataNotFound';
import { CheckTimeStatus, DateIntoWeekDays } from '../../helpers/helpers';
import { useNavigate } from 'react-router-dom';
import ImageSliderSimple2 from '../../components/Slider/ImageSliderSimple2';
import Calendar from '../../components/calendar/Calendar';
import ImageSliderSimple from '../../components/Slider/ImageSliderSimple';
import Models from '../../components/models/Models';
import FeedModels from '../../components/models/FeedModels';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import moment from 'moment';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IoNewspaper } from 'react-icons/io5';
import { Skeleton } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function StudentDashboard() {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [promotionsList, setPromotionsList] = useState<any>([]);
  const [feedList, setFeedList] = useState<any>([]);
  const [showFeed, setShowFeed] = useState<any>(false);
  const [feedModelData, setFeedModelData] = useState<any>();
  const [courseList, setCourseList] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(0);
  const [courseStats, setCourseStats] = useState<any>([]);
  const [courseStatsLoading, setCOurseStatsLoading] = useState(false);
  const [liveClassLoading, setLiveClassLoading] = useState(false);
  const [feedLoading, setFeedLoading] = useState(false);
  const [courseDataLoading, setCourseDataLoading] = useState(false);
  const [courseCardDetails, setCourseCardDetails] = useState({
    total: 0,
    paid: 0,
    free: 0,
  });
  const navigate = useNavigate();

  const fetchCourseStats = async (id: string, index: number) => {
    setCOurseStatsLoading(true);
    let get: any;
    if (index === 0) {
      get = await StudentCourseTestStats(id);
    } else if (index === 1) {
      get = await StudentCourseVideoStats(id);
    }
    if (get?.status === 'success') {
      setCOurseStatsLoading(false);
      setCourseStats(get?.message);
    }
    setCOurseStatsLoading(false);
  };

  const fetchCourse = async () => {
    setCourseDataLoading(true);

    let get: any = await StudentCourseList();
    if (get?.status === 'success') {
      setCourseDataLoading(false);
      let total = 0;
      let paid = 0;
      let free = 0;
      if (get?.message?.length > 0) {
        get?.message?.map((item: any) => {
          total = total + 1;
          if (parseInt(item.studentSubscription?.course_amount) !== 0) {
            paid = paid + 1;
          } else {
            free = free + 1;
          }
        });
      }
      setCourseCardDetails({
        total: total,
        paid: paid,
        free: free,
      });
      setCourseList(get?.message);
    }
    setCourseDataLoading(false);
  };
  const fetchFeeds = async () => {
    setFeedLoading(true);
    let get: any = await StudentDashboardFeeds();
    if (get?.status === 'success') {
      setFeedLoading(false);
      setPromotionsList(get?.message?.promotions);
      let newP: any = [];
      get?.message?.promotions?.length > 0 &&
        get?.message?.promotions.map((item: any) => {
          if (item?.type === 0) {
            newP.push({
              url: item?.url,
              type: 'img',
            });
          } else {
            newP.push({
              url: item?.url,
              type: 'vid',
            });
          }
        });
      newP.length > 0 ? setPromotionsList(newP) : setPromotionsList([]);
      let newFeed: any = [];
      get?.message?.feeds?.length > 0 &&
        get?.message?.feeds.map((item: any) => {
          if (item?.feedsType?.name === 'Image') {
            newFeed.push({
              url: item?.url,
              name: item?.name,
              id: item?.id,
              type: 'img',
            });
          } else {
            newFeed.push({
              url: item?.url,
              name: item?.name,
              id: item?.id,
              type: 'vid',
            });
          }
        });
      newFeed.length > 0 ? setFeedList(newFeed) : setFeedList([]);
    }
    setFeedLoading(false);
  };
  const fetchLiveClass = async () => {
    setLiveClassLoading(true);
    const get: any = await getStudentLiveClasses();
    if (get?.status === 'success') {
      setLiveClassLoading(false);
      setFetchData(get?.message);
    }
    setLiveClassLoading(false);
  };
  useEffect(() => {
    fetchLiveClass();
    fetchFeeds();
    fetchCourse();
  }, []);
  const handleLiveClassJoin = (type: string, url: string) => {
    if (type === 'zoom') {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
    if (type === 'lms') {
      navigate(`/student/live-class/join/${url}`);
    }
  };
  const handleFeedModel = (id: string) => {
    setShowFeed(!showFeed);
    setFeedModelData({
      id: id,
    });
  };
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setActiveTab(0);
    };

  const tablist = [
    { id: 0, name: 'Test Series', title: 'testSeries' },
    { id: 1, name: 'Videos', title: 'Videos' },
  ];
  return (
    <>
      <Helmet>
        <title>{`Dashboard | ${window.location.hostname}`}</title>
      </Helmet>
      <div className=" grid grid-cols-12 gap-2">
        <div className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8 mb-3 ">
          <div className=" rounded-md bg-gray-800 flex justify-between items-center  h-[150px] shadow-lg w-full">
            <div className="pl-3">
              <p className="text-3xl font-sans font-bold  text-gray-200">
                Welcome Back,{' '}
                <span className="capitalize ">
                  {JSON.parse(Cookies.get('login')!).name}
                </span>
              </p>
              <p className=" text-xl  font-semibold  text-gray-300">
                Let's learn something new today
              </p>
            </div>
          </div>
          {feedLoading ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{
                width: '100%',
                marginTop: 2,
              }}
              height={140}
            />
          ) : (
            feedList?.length > 0 && (
              <div className="my-2 col-span-12 bg-white p-2 rounded-lg ">
                <div className="flex justify-between">
                  <p className="text-xl  font-semibold  text-gray-800">Feeds</p>
                </div>
                <div className="my-2 inline-block whitespace-nowrap overflow-y-auto">
                  {/* <ImageSliderSimple2> */}
                  {feedList.map((item: any, index: number) => (
                    <div
                      key={index}
                      onClick={() => handleFeedModel(item?.id)}
                      className="each-slide-effect px-3 inline-block "
                    >
                      {item?.type === 'img' && (
                        <img
                          src={item?.url}
                          className=" h-[100px] w-[100px] rounded-full hover:shadow-lg hover:cursor-pointer"
                          alt=""
                        />
                      )}
                    </div>
                  ))}
                  {/* </ImageSliderSimple2> */}
                </div>
              </div>
            )
          )}
          <div className="my-2">
            <div className="grid grid-cols-12 gap-2">
              <div className=" sm:col-span-12 col-span-12 md:col-span-6">
                {courseDataLoading ? (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{
                      width: '100%',
                      marginTop: 2,
                    }}
                    height={140}
                  />
                ) : (
                  <div className="card bg-white   rounded-lg p-3">
                    <p className="text-sm mb-2 text-gray-600 font-semibold">
                      Total Courses
                    </p>

                    <div className="flex flex-row gap-4 items-center justify-between">
                      <FaBookAtlas className="text-[3.4rem] text-purple-400 p-1 rounded-lg " />
                      <div className=" leading-10 text-center ">
                        <p className="text-[3rem] font-bold">
                          {courseCardDetails?.total}
                        </p>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <div className="flex items-center justify-between">
                      <div className="flex  items-center ">
                        <p className="text-lg text-gray-600">Paid : </p>{' '}
                        <p className="text-xl font-bold pl-1">
                          {courseCardDetails?.paid}
                        </p>
                      </div>
                      <div className="flex  items-center ">
                        <p className="text-lg text-gray-600">Free : </p>{' '}
                        <p className="text-xl font-bold pl-1">
                          {' '}
                          {courseCardDetails?.free}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className=" sm:col-span-12 col-span-12 md:col-span-6">
                {liveClassLoading ? (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{
                      width: '100%',
                      marginTop: 2,
                    }}
                    height={140}
                  />
                ) : (
                  <div className="card bg-white   rounded-lg p-3 min-h-[166px] h-[166px] overflow-auto">
                    <p className="text-sm mb-2 text-gray-600 font-semibold">
                      Live Class
                    </p>
                    <div className="my-2 grid grid-cols-12 gap-3">
                      {fetchData?.length > 0 ? (
                        fetchData.map(
                          (item: any, index: number) =>
                            CheckTimeStatus(item?.datetime, item?.duration) !==
                              0 && (
                              <div
                                key={index}
                                className=" col-span-12  relative  bg-gray-200 rounded-xl"
                              >
                                <div className="flex flex-col  gap-1 border-l-4 rounded-lg py-1 px-2 border-purple-600">
                                  <h5 className="text-lg font-bold">
                                    {item.title}
                                  </h5>
                                  {CheckTimeStatus(
                                    item?.datetime,
                                    item?.duration
                                  ) === 1 && (
                                    <FaDotCircle className="bg-green-700 text-white py-1 px-2  rounded-lg absolute  right-2 top-2" />
                                  )}

                                  <div className="flex  flex-row items-center justify-between">
                                    <div className="leading-5">
                                      <p className="flex gap-2  items-center text-left rounded-lg  font-semibold w-full text-gray-800 py-1">
                                        <FaBook />{' '}
                                        {
                                          item?.liveClassSubmoduleRelation[0]
                                            ?.liveClassSubmodule
                                            ?.liveClassCourseRelation[0]?.course
                                            ?.name
                                        }
                                      </p>
                                      <p className="flex gap-2  items-center text-left rounded-lg  font-semibold w-full text-gray-800 py-1">
                                        <FaStopwatch />{' '}
                                        {DateIntoWeekDays(item?.datetime)}
                                      </p>
                                    </div>
                                    <div>
                                      {CheckTimeStatus(
                                        item?.datetime,
                                        item?.duration
                                      ) === 1 ? (
                                        <button
                                          onClick={() =>
                                            handleLiveClassJoin(
                                              item?.url ? 'zoom' : 'lms',
                                              item?.url
                                                ? item?.url
                                                : item?.meeting_number
                                            )
                                          }
                                          className="bg-gradient w-full text-white py-1  px-2 rounded-lg "
                                        >
                                          Join Class
                                        </button>
                                      ) : (
                                        <button
                                          disabled={true}
                                          className="bg-gray-700 w-full text-white py-1  px-2 rounded-lg "
                                        >
                                          Join Class
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )
                      ) : (
                        <div className="col-span-12">
                          <p>
                            <b>Hurray! No Class for Today, </b> <br></br>would
                            you like to{' '}
                            <b
                              onClick={() => navigate('/student/test-series')}
                              className="text-blue-700 hover:cursor-pointer"
                            >
                              {' '}
                              take test
                            </b>{' '}
                            or{' '}
                            <b
                              onClick={() => navigate('/student/videos')}
                              className="text-blue-700 hover:cursor-pointer"
                            >
                              {' '}
                              watch videos
                            </b>{' '}
                            of previous class
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {courseDataLoading ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{
                width: '100%',
                marginTop: 2,
              }}
              height={140}
            />
          ) : (
            courseList.length > 0 && (
              <div className="mt-3">
                <p className="text-xl  font-semibold  text-gray-800">Courses</p>
                {courseList.map((item: any, index: number) => (
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    sx={{
                      marginB: 2,
                      boxShadow: 0,
                      borderLeft: 1,
                      borderLeftWidth: 4,
                      borderLeftColor: '#9333ea',

                      background: 'rgb(229 231 235)',
                      '&.Mui-disabled': {
                        backgroundColor: 'rgb(229 231 235)',
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      onClick={() => fetchCourseStats(item?.id, 0)}
                      expandIcon={<FaAngleDown />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        '&.Mui-disabled': {
                          opacity: 1,
                        },
                      }}
                    >
                      <div className=" hover:cursor-pointer  w-[100%] ">
                        <div className="!flex  lg:flex-row  flex-col md:flex-col sm:flex-col justify-between ">
                          <div className="flex items-center gap-2 ">
                            {moment(
                              item?.studentSubscription[0]?.expiry_date
                            ).isBefore(new Date()) ? (
                              <p className="bg-red-500 px-4 py-1 rounded-lg mr-5 text-white">
                                Expired
                              </p>
                            ) : (
                              <p className="bg-green-500 px-4 py-1 rounded-lg mr-5 text-white">
                                Active
                              </p>
                            )}
                            <img
                              src={item?.image}
                              className="h-[50px] rounded-lg"
                              alt=""
                            />{' '}
                            <p className="text-lg font-bold">{item?.name} </p>
                          </div>
                          <div className="flex gap-4 mr-0 sm:mr-0 md:mr-10 mt-5 sm:mt-5 md:mt-0 sm:flex-col flex-col md:flex-row  ">
                            <div className="flex gap-2 items-center">
                              <FaCalendarTimes className=" bg-red-600 text-white p-2 text-[2.2rem] rounded-lg" />
                              <div>
                                <p className=" font-bold text-sm">Expires On</p>
                                <p className="text-sm">
                                  {moment(
                                    item?.studentSubscription[0]?.expiry_date
                                  ).format('DD MMM YYYY')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="my-2">
                        <div className="">
                          <div className=" ">
                            {tablist?.length > 0 ? (
                              <>
                                <div className="flex flex-row gap-2 items-center justify-between">
                                  <div className="flex flex-row gap-4 items-center ">
                                    {tablist.map((e: any) => (
                                      <>
                                        <button
                                          onClick={() => {
                                            setActiveTab(e.id);
                                            fetchCourseStats(item?.id, e.id);
                                          }}
                                          className={`${activeTab === e.id ? 'hover-purple-gradient text-white' : 'bg-gray-200 dark:bg-secondary'} p-2 font-semibold rounded-md hover:shadow-xl`}
                                        >
                                          {e.name}
                                        </button>
                                      </>
                                    ))}
                                  </div>
                                </div>
                                {activeTab === 0 ? (
                                  <div className="mt-2 grid grid-cols-12 gap-2">
                                    {courseStatsLoading ? (
                                      <p className="ml-2 font-bold text-lg">
                                        Loading...
                                      </p>
                                    ) : courseStats?.length > 0 ? (
                                      courseStats?.map(
                                        (e: any, index: number) => (
                                          <div
                                            key={index}
                                            className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4"
                                          >
                                            <div className="bg-white p-2 rounded-lg flex flex-row justify-between items-center">
                                              <div>
                                                <IoNewspaper className="text-purple-800 text-[3rem]" />
                                              </div>
                                              <div>
                                                <p className="font-bold text-lg">
                                                  {e.name}
                                                </p>
                                                <p className="flex  justify-end">
                                                  <p className="text-xl font-bold">
                                                    {e.attempt}
                                                  </p>
                                                  <p className="text-3xl font-bold">
                                                    /
                                                  </p>
                                                  <p className="text-2xl font-bold pt-2">
                                                    {e.totalTest}
                                                  </p>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <div className="col-span-12">
                                        <p className="text-xl font-bold">
                                          No Test Series Found
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                                {activeTab === 1 ? (
                                  <div className="mt-2 grid grid-cols-12 gap-2">
                                    {courseStatsLoading ? (
                                      <p className="ml-2 font-bold text-lg">
                                        Loading...
                                      </p>
                                    ) : courseStats?.length > 0 ? (
                                      courseStats?.map(
                                        (e: any, index: number) => (
                                          <div
                                            key={index}
                                            className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4"
                                          >
                                            <div className="bg-white p-2 rounded-lg flex flex-row justify-between items-center">
                                              <div>
                                                <IoNewspaper className="text-purple-800 text-[3rem]" />
                                              </div>
                                              <div>
                                                <p className="font-bold text-lg">
                                                  {e.name}
                                                </p>
                                                <p className="flex  justify-end">
                                                  <p className="text-xl font-bold">
                                                    {e.watched}
                                                  </p>
                                                  <p className="text-3xl font-bold">
                                                    /
                                                  </p>
                                                  <p className="text-2xl font-bold pt-2">
                                                    {e.totalVideos}
                                                  </p>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <div className="col-span-12">
                                        <p className="text-xl font-bold">
                                          No Videos Found
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            )
          )}
        </div>
        <div className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4 ">
          <div className="bg-gray-200 dark:bg-secondary p-1  sm:hidden hidden md:block  rounded-md w-full  ">
            <Calendar date={new Date()} />
          </div>
          {feedLoading ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{
                width: '100%',
                marginTop: 2,
              }}
              height={250}
            />
          ) : (
            promotionsList.length > 0 && (
              <div className="">
                <div className="flex justify-between">
                  <p className="text-xl  font-semibold  text-gray-800">
                    Promotions
                  </p>
                </div>
                <ImageSliderSimple images={promotionsList} />
              </div>
            )
          )}
        </div>

        <Models
          width="w-[30vw]"
          name={`Feed`}
          isOpen={showFeed}
          isClose={setShowFeed}
        >
          <FeedModels data={feedModelData} open={setShowFeed} />
        </Models>
      </div>
    </>
  );
}
