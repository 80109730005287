import {
  adminLiveClassAccountTypes,
  liveClassesTypes,
} from '../../types/AdminTypes';
import instance from '../instace';

export const createAdminLiveClassSubmodule = async (
  name: string,
  status: boolean
) => {
  try {
    const get: any = await instance.post(
      '/api/admin/liveclass/submodule/create',
      {
        name,
        status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAdminLiveClassSubmodule = async (
  page?: number,
  query?: string
) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/liveclass/submodule/get?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const showAdminLiveClassSubmodule = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/liveclass/submodule/show/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const editAdminLiveClassSubmodule = async (
  id: string,
  name: string,
  status: boolean
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/liveclass/submodule/edit/${id}`,
      {
        name,
        status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const updateStatusAdminLiveClassSubmodule = async (
  id: string,
  status: boolean
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/liveclass/submodule/status/${id}`,
      {
        status: status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const deleteAdminLiveClassSubmodule = async (id: string) => {
  try {
    const get: any = await instance.delete(
      `/api/admin/liveclass/submodule/delete/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getActiveAdminLiveClassSubmodule = async () => {
  try {
    const get: any = await instance.get(
      `/api/admin/liveclass/submodule/get-active`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const createAdminLiveClassAccount = async (
  data: adminLiveClassAccountTypes
) => {
  try {
    const get: any = await instance.post(
      '/api/admin/liveclass/account/create',
      {
        email: data.email,
        clientId: data.clientId,
        clientSecret: data.clientSecret,
        serverAccount: data.serverAccount,
        serverClientId: data.serverClientId,
        serverClientSecret: data.serverClientSecret,
        status: data.status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAdminLiveClassAccount = async (
  page?: number,
  query?: string
) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/liveclass/account/get?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const showAdminLiveClassAccount = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/liveclass/account/show/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const editAdminLiveClassAccount = async (
  id: string,
  data: adminLiveClassAccountTypes
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/liveclass/account/edit/${id}`,
      {
        email: data.email,
        clientId: data.clientId,
        clientSecret: data.clientSecret,
        serverAccount: data.serverAccount,
        serverClientId: data.serverClientId,
        serverClientSecret: data.serverClientSecret,
        status: data.status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const updateStatusAdminLiveClassAccount = async (
  id: string,
  status: boolean
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/liveclass/account/status/${id}`,
      {
        status: status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const deleteAdminLiveClassAccount = async (id: string) => {
  try {
    const get: any = await instance.delete(
      `/api/admin/liveclass/account/delete/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getActiveAdminLiveClassAccount = async () => {
  try {
    const get: any = await instance.get(
      `/api/admin/liveclass/account/get-active`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const createAdminLiveClasses = async (data: liveClassesTypes) => {
  try {
    const get: any = await instance.post('/api/admin/liveclass/zoom/create', {
      title: data.title,
      datetime: data.datetime,
      passowrd: data.passowrd,
      fk_liveClassAccount_id: data.fk_liveClassAccount_id,
      fk_liveCLassSubModule_id: data.fk_liveCLassSubModule_id,
      duration: data.duration,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAdminLiveClasses = async (page?: number, query?: string) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/liveclass/zoom/get?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const showAdminLiveClasses = async (id: string) => {
  try {
    const get: any = await instance.get(`/api/admin/liveclass/zoom/show/${id}`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const editAdminLiveClasses = async (
  id: string,
  data: liveClassesTypes
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/liveclass/zoom/edit/${id}`,
      {
        title: data.title,
        datetime: data.datetime,
        passowrd: data.passowrd,
        fk_liveClassAccount_id: data.fk_liveClassAccount_id,
        fk_liveCLassSubModule_id: data.fk_liveCLassSubModule_id,
        duration: data.duration,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const deleteAdminLiveClasses = async (id: string) => {
  try {
    const get: any = await instance.delete(
      `/api/admin/liveclass/zoom/delete/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getAllLiveClassRelationByCourse = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/liveclass/relation/course/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAllLiveClassSubmodules = async () => {
  try {
    const get: any = await instance.get(
      `/api/admin/liveclass/submodules/get-all`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const createLiveClassRelationByCourse = async (data: any) => {
  try {
    const get: any = await instance.post(
      `/api/admin/liveclass/relation/create`,
      {
        rel: data,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
