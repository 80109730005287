import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormButton from '../../../../components/button/FormButton';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import { createAdminWHatsAppCredentials } from '../../../../service/admin/adminSetting.service';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';


export default function AdminSettingWhatsappCreate(props:{open:any}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const { handleSubmit, control } = useForm<any>();
    const onSubmit: SubmitHandler<any> = async (
      data: any
    ) => {
      setLoading(true);
      let cr:any = await createAdminWHatsAppCredentials(data.phone_number,data.pid,data.phone,data.token)
      if(cr?.status === "success"){
        setLoading(false);
        props.open(false)
           dispatch(
                      setContentUpdate({
                        id: Math.random() * (100000 - 111111),
                      })
                    );
        return toast.success(cr?.message)
       
      }
      setLoading(false);
    };

  return (
    <>
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
      <Input
            name="phone_number"
            type="tel"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="Whatsapp Phone"
            placeholder="phone"
          />
            <Input
            name="pid"
            type="text"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="maytapi pid"
            placeholder="maytapi pid"
          />
            <Input
            name="phone"
            type="text"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="maytapi Phone Id"
            placeholder="maytapi phone id"
          />
          <Input
            name="token"
            type="text"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="maytapi Token"
            placeholder="maytapi token"
          />
        <FormButton loading={loading} title={"Submit"} />
      </form>
    </div>
  </>
  )
}
 