import instance from '../instace';

export const getAdminWhatsappCredentials = async () => {
    try {
      const get: any = await instance.get('/api/admin/whatsapp-credentials/get/all');
      return get.data;
    } catch (e) {
      return false;
    }
  };
  export const createAdminWHatsAppCredentials = async(phone_number:any,pid:string,phone:string,token:string)=>{
    try {
        const get: any = await instance.post('/api/admin/whatsapp-credentials/create',{
            phone_number,pid,phone,token 
        });
        return get.data;
      } catch (e) {
        return false;
      }
  }
  export const showAdminWhatsappCredentials = async (id:string) => {
    try {
      const get: any = await instance.get(`/api/admin/whatsapp-credentials/show/${id}`);
      return get.data;
    } catch (e) {
      return false;
    }
  };
  export const editAdminWhatsappCredentials = async (id:string,phone_number:any,pid:string,phone:string,token:string) => {
    try {
      const get: any = await instance.put(`/api/admin/whatsapp-credentials/edit/${id}`,{
        phone_number,pid,phone,token 
      });
      return get.data;
    } catch (e) {
      return false;
    }
  };
  export const statusAdminWhatsappCredentials = async (id:string,status:boolean) => {
    try {
      const get: any = await instance.put(`/api/admin/whatsapp-credentials/status/${id}`,{
        status
      });
      return get.data;
    } catch (e) {
      return false;
    }
  };
  export const defaultAdminWhatsappCredentials = async (id:string,status:boolean) => {
    try {
      const get: any = await instance.put(`/api/admin/whatsapp-credentials/default/${id}`,{
        status
      });
      return get.data;
    } catch (e) {
      return false;
    }
  };
  export const deleteAdminWhatsappCredentials = async (id:string) => {
    try {
      const get: any = await instance.delete(`/api/admin/whatsapp-credentials/delete/${id}`);
      return get.data;
    } catch (e) {
      return false;
    }
  };