import React, { useEffect, useState } from 'react';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createAdminBatchStudents,
  showAdminBatch,
} from '../../../service/admin/adminBatch.service';
import SelectPaginationDateGrid from '../../../components/tables/SelectPaginationDateGrid';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

export default function AdminBatchAddStudentToBatch() {
  const [pageLoad, setpageLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [batchData, setBatchData] = useState<any>();
  const [showButton, setShowButton] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const id: any = useParams();
  const navigate = useNavigate();

  const fetchBatchData = async (id: string) => {
    const get: any = await showAdminBatch(id);
    console.log(get);
    if (get?.status === 'success') {
      setBatchData(get?.message);
    }
  };
  useEffect(() => {
    fetchBatchData(id?.id);
  }, []);
  const handleSubmit = async (data: any) => {
    if (data.length > 0) {
      setShowButton(true);
      setStudentList(data);
    } else {
      setShowButton(false);
    }
  };
  const handleStudentSubmit = async () => {
    const create = await createAdminBatchStudents(id.id, studentList);
    if (create.status === 'success') {
      toast.success(create.message);
      navigate(`/admin/batches/students/${id.id}`);
    }
  };
  return (
    <>
      <Helmet>
        <title>{`Add Student Batch | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`Add Students to ${batchData?.name} `}>
        {showButton ? (
          <CustomButton
            icon={false}
            title="Submit"
            onClick={handleStudentSubmit}
          />
        ) : null}
      </BreadcrumbBack>
      <div className="my-2">
        <SelectPaginationDateGrid
          name="AddStudentToBatchAdmin"
          id={id?.id}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  );
}
