import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AdminVideosTypes } from '../../../types/AdminTypes';
import { statusList, toBoolean } from '../../../helpers/helpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import SearchableSelect from '../../../components/form/SearchableSelect';
import DropZoneUploadImages from '../../../components/dropzone/DropZoneUploadImages';
import TinyMiceEditor from '../../../editor/TinyMiceEditor';
import {
  createAdminVideos,
  editAdminVideos,
  getVideosTypes,
  showAdminVideos,
} from '../../../service/admin/adminVideoes.service';
import FormButton from '../../../components/button/FormButton';
import { Helmet } from 'react-helmet-async';
import {
  getVideoInfo,
  getVimeoTimeandThumbnail,
} from '../../../helpers/videoPlayer/VimeoHelper';
import { getYoutubeVideoDurationThumnail } from '../../../helpers/videoPlayer/YoutubeHelper';

export default function AdminVideoCE() {
  const [videoTypeList, setVideoTypeList] = useState<any>([]);
  const [videoDetail, setVideoDetail] = useState<any>();
  const [url, setUrl] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [autoLength, setAutoLength] = useState();
  const [pageContent, setPageContent] = useState({
    button: 'Create Video',
    title: 'Create Video',
  });
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] =
    useState<any>(false);
  const [initalEditor, setInitalEditor] = useState<any>(undefined);
  const [editorValue, setEditorValue] = useState<any>();
  const location = useLocation();
  const locationLength = location.pathname.split('/').length;
  const params = new URLSearchParams(location.search);
  let pageType = location.pathname.split('/')[locationLength - 1];
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm<AdminVideosTypes>({
    values: {
      name: videoDetail?.name,
      fk_videoType_id: videoDetail?.videoType?.id,
      description: initalEditor,
      status: `${videoDetail?.status ? videoDetail?.status : null}`,
      thumbnail: videoDetail?.thumbnail,
      length: videoDetail?.length,
      watchtime: videoDetail?.watchtime,
      url: videoDetail?.url,
    },
  });

  const fetchVideoTypes = async () => {
    const get: any = await getVideosTypes();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setVideoTypeList(data);
    }
  };
  const fetchVidesDetails = async (id: string) => {
    const get: any = await showAdminVideos(id);
    if (get?.status === 'success') {
      setVideoDetail(get.message);
      setUrl(get.message.thumbnail);
      setInitalEditor(get.message.description);
    }
  };
  useEffect(() => {
    fetchVideoTypes();
    if (pageType === 'edit') {
      let id: any = params.get('id');
      fetchVidesDetails(id);
      setPageContent({
        button: 'Edit Video',
        title: 'Edit Video',
      });
    }
  }, []);
  const hadleVideoDurationThumnail = async (e: any) => {
    if (e) {
      let getInfo: any = getVideoInfo(e);
      console.log(getInfo);
      if (getInfo?.platform === 'YouTube') {
        let thumb = getYoutubeVideoDurationThumnail(getInfo?.videoId);
        setUrl(thumb);
      } else if (getInfo?.platform === 'Vimeo') {
        let thumb: any = await getVimeoTimeandThumbnail(getInfo?.videoId);
        setUrl(thumb[0]);
        setAutoLength(thumb[1]);
      }
    }
  };
  console.log(videoDetail);
  const onSubmit: SubmitHandler<AdminVideosTypes> = async (
    data: AdminVideosTypes
  ) => {
    setLoading(true);
    let status_: any = data.status?.value
      ? toBoolean(data.status.value)
      : videoDetail?.status
        ? videoDetail?.status
        : true;
    let fetch: any;
    data.status = status_;
    data['thumbnail'] = url;
    data['description'] = editorValue;
    data['moduleId'] = params.get('sub');
    let videoTypeId: any = data?.fk_videoType_id?.value;
    if (videoTypeId === undefined) {
      videoTypeId = videoDetail?.videoType?.id;
    }
    data.fk_videoType_id = videoTypeId;
    if (pageType === 'create') {
      fetch = await createAdminVideos(data);
    }
    if (pageType === 'edit') {
      let id: any = params.get('id');
      fetch = await editAdminVideos(id, data);
    }
    if (fetch?.status === 'success') {
      setLoading(false);
      navigate(-1);
      return toast.success(fetch.message);
    }

    setLoading(false);
  };

  const handleEditor = (output: any) => {
    setEditorValue(output);
  };

  return (
    <>
      <Helmet>
        <title>{`${pageType} Videos  | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={pageContent.title}></BreadcrumbBack>
      <div className="my-2">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="name"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                  ...formValidationRules.minLength(3),
                }}
                label="Title"
                placeholder="title"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <SearchableSelect
                name="fk_videoType_id"
                control={control}
                options={videoTypeList}
                rules={formValidationRules.required()}
                label="Select Video Type"
                placeholder="select video type"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                onBlur={hadleVideoDurationThumnail}
                name="url"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Video URL"
                placeholder="url"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="length"
                control={control}
                type="number"
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Duration (in seconds)"
                placeholder="duration"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="watchtime"
                control={control}
                type="number"
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Watch Limit"
                placeholder="Watch Limit"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <SearchableSelect
                name="status"
                control={control}
                options={statusList}
                rules={formValidationRules.required()}
                label="Select Status"
                placeholder="select Status"
              />
            </div>
          </div>
          <div className="my-2">
            <DropZoneUploadImages
              heigth="min-h-[25vh]"
              title="Upload Image"
              isRequired={true}
              maxFile={1}
              data={setUrl}
              url={url}
              status={setCheckUploadedFilesStatus}
            />
          </div>
          <TinyMiceEditor
            title="Description"
            isRequired={true}
            input={initalEditor}
            output={handleEditor}
          />
          <div className="my-2">
            <FormButton loading={loading} title={pageContent.button} />
          </div>
        </form>
      </div>
    </>
  );
}
