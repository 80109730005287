import React, { useEffect, useState } from 'react';
import {
  createAdminCourseCategoryTabCourse,
  getAllActiveCourseList,
} from '../../../../service/admin/adminCourses.service';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import FormButton from '../../../../components/button/FormButton';
import DynamicMultiSearchSelect from '../../../../components/form/DynamicMultiSearchSelect';
import formValidationRules from '../../../../helpers/formvalidationRules';

export default function CourseCategoryTab_courses_add(props: {
  type: string;
  data?: any;
  open: any;
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [slugData, setSlugData] = useState<any>([]);
  const fetchSlugData = async (query?: any) => {
    setPageLoad(true);
    const get = await getAllActiveCourseList(query ? query : null);
    if (get?.status === 'success') {
      let newData: any = [];
      if (get?.message.length > 0) {
        get?.message.map((item: any) => {
          newData.push({ value: item.id, label: item.name });
        });
      }

      setSlugData((prev: any) => {
        const mergedData = [...prev, ...newData];
        const uniqueData = Array.from(
          new Map(mergedData.map((item) => [item.value, item])).values()
        );
        return uniqueData;
      });
      setPageLoad(false);
    }
    setPageLoad(false);
  };
  useEffect(() => {
    fetchSlugData();
  }, []);
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm<any>();
  const onSubmit: SubmitHandler<any> = async (data) => {
    setLoading(true);
    let fetch: any;
    fetch = await createAdminCourseCategoryTabCourse(
      props.data.id,
      data.fk_courseCategory_id
    );
    if (fetch?.status === 'success') {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };
  const handleSearch = async (e: any) => {
    if (e) {
      if (e.length > 2) {
        fetchSlugData(e);
      }
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-[50px]">
        <DynamicMultiSearchSelect
          name="fk_courseCategory_id"
          control={control}
          onSearch={handleSearch}
          options={slugData}
          isLoading={pageLoad}
          rules={formValidationRules.required()}
          label="Select course"
          placeholder="Search.."
        />
        <br />
        <FormButton loading={loading} title="Submit" />
      </form>
    </>
  );
}
