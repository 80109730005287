import { createSlice } from '@reduxjs/toolkit';

const initialState: any = [{ id: 978 }];

const contentUpdateSlice: any = createSlice({
  name: 'contentUpdateSlice',
  initialState,
  reducers: {
    setContentUpdate: (state: any, action: any) => {
      return action.payload;
    },
  },
});
export const { setContentUpdate } = contentUpdateSlice.actions;
export default contentUpdateSlice.reducer;
