import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { adminCouponMasterType } from '../../../../types/AdminTypes';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import {
  createAdminCouponMaster,
  getDiscountTypes,
} from '../../../../service/admin/adminCoupon.service';
import SearchableSelect from '../../../../components/form/SearchableSelect';
import RadioButton from '../../../../components/form/RadioButton';
import { statusList, toBoolean } from '../../../../helpers/helpers';
import FormButton from '../../../../components/button/FormButton';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';

export default function AdminCouponMasterCreate(props: {
  type: string;
  data?: any;
  open: any;
}) {
  const [discountTypeList, setDiscountTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchDiscountType = async () => {
    const get: any = await getDiscountTypes();
    if (get?.status === 'success') {
      let newData: any = [];
      get?.message.map((item: any) => {
        newData.push({ value: item.id, label: item.name });
      });
      setDiscountTypeList(newData);
    }
  };
  useEffect(() => {
    fetchDiscountType();
  }, []);
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm<any>();
  const onSubmit: SubmitHandler<adminCouponMasterType> = async (
    data: adminCouponMasterType
  ) => {
    setLoading(true);
    let newData = {
      name: data.name,
      max_uses: data.max_uses,
      start_date: data.start_date,
      end_date: data.end_date,
      fk_discount_type_id: data.fk_discount_type_id?.value,
      discount_value: data.discount_value,
      status: toBoolean(data.status),
    };
    let send: any = await createAdminCouponMaster(newData);
    if (send?.status === 'success') {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(send.message);
    }
    setLoading(false);
  };
  const today: any = new Date().toISOString().split('T')[0];
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <Input
          name="name"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Coupon"
          placeholder="coupon"
        />
        <div className="grid gap-2 grid-cols-12">
          <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <Input
              name="start_date"
              type="date"
              control={control}
              rules={{
                ...formValidationRules.required(),
                ...formValidationRules.min(today),
              }}
              label="Start Date"
              placeholder=""
            />
          </div>
          <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <Input
              name="end_date"
              type="date"
              control={control}
              rules={{
                ...formValidationRules.required(),
                ...formValidationRules.min(today),
              }}
              label="End Date"
              placeholder=""
            />
          </div>
          <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <SearchableSelect
              name="fk_discount_type_id"
              control={control}
              options={discountTypeList}
              rules={formValidationRules.required()}
              label="Select Discount Type"
              placeholder="discount type"
            />
          </div>
          <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <Input
              name="discount_value"
              type="number"
              control={control}
              rules={{
                ...formValidationRules.required(),
                ...formValidationRules.min(1),
              }}
              label="Discount"
              placeholder="discount"
            />
          </div>
          <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <Input
              name="max_uses"
              type="number"
              control={control}
              rules={{
                ...formValidationRules.required(),
                ...formValidationRules.min(1),
              }}
              label="Max Uses"
              placeholder="max uses"
            />
          </div>
          <div className=" col-span-12 sm:col-span-12 md:col-span-6">
            <RadioButton
              name="status"
              control={control}
              rules={{ ...formValidationRules.required() }}
              options={statusList}
              label="status"
            />
          </div>
          <div className=" col-span-12 sm:col-span-12 md:col-span-12">
            <FormButton loading={loading} title="Create Coupon" />
          </div>
        </div>
      </form>
    </>
  );
}
