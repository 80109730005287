import axios from 'axios';
import React, { useState } from 'react';
import { endPoint1 } from '../../service/instace';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../redux/contentUpdateSlice';
import MUITableGrid from '../tables/MUITableGrid';

export default function ExcelUpload(props: {
  name: string;
  accept?: string;
  data?: any;
  open: any;
}) {
  const [file, setFile] = useState<any>(null);
  const [notCreated, setNotCreated] = useState([]);
  const [rowid, setRowid] = useState<any>(1);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };
  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (!file) {
      console.error('No file selected.');
      return;
    }
    if (props.name === 'AdminBatchStudentUpload') {
      const formData = new FormData();
      formData.append('excelFile', file);
      formData.append('batchId', props.data.id);

      try {
        let fetch: any = await axios.post(
          `${endPoint1}/api/uploads/admin/student/bulk/create-batch`,
          formData,
          {
            headers: {
              Authorization: Cookies.get('token'),
            },
          }
        );

        if (fetch?.data?.status === 'success') {
          props.open(false);
          dispatch(
            setContentUpdate({
              id: Math.random() * (100000 - 111111),
            })
          );
          return toast.success(fetch.data?.message);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        if (error.response?.status === 404) {
          return toast.error('Api not found, contact to administrator');
        }
        if (error.response?.status !== 200) {
          return toast.error(error?.response?.data?.message);
        }
      }
    }

    if (props.name === 'AdminStudentUpload') {
      const formData = new FormData();
      formData.append('excelFile', file);

      try {
        let fetch: any = await axios.post(
          `${endPoint1}/api/uploads/admin/student/bulk/create`,
          formData,
          {
            headers: {
              Authorization: Cookies.get('token'),
            },
          }
        );

        if (fetch?.data?.status === 'success') {
          setLoading(false);
          dispatch(
            setContentUpdate({
              id: Math.random() * (100000 - 111111),
            })
          );

          let dt: any = [];
          if (fetch.data?.message?.not[0].length > 0) {
            fetch.data?.message?.not?.map((item: any, index: number) => {
              if (item.length > 0) {
                item.map((e: any, i: number) => {
                  dt.push({
                    id: i + rowid,
                    name: e.name,
                    email: e.email,
                    phone: e.phone,
                  });
                });
              }
            });
            setNotCreated(dt);
          }
          // if(notCreated.length === 0){
          //   props.open(false);
          // }
          return toast.success(fetch.data?.message?.message);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        if (error.response?.status === 404) {
          return toast.error('Api not found, contact to administrator');
        }
        if (error.response?.status !== 200) {
          return toast.error(error?.response?.data?.message);
        }
      }
    }
  };

  return (
    <div>
      <form className="p-2" onSubmit={handleSubmit}>
        <input
          className="block w-full text-lg p-1 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          type="file"
          accept={`${props.accept ? props.accept : '.xlsx, .xls, .csv'}`}
          onChange={handleFileChange}
        />
        <button
          disabled={loading ? true : false}
          className="p-2 mt-3 mb-4 flex w-full  gap-1 items-center justify-center hover-purple-gradient text-white rounded-lg"
        >
          {loading ? ' Uploading...' : 'upload'}
        </button>
      </form>
      {notCreated.length > 0 ? (
        <div className="my-2 bg-gray-100 p-1 rounded-lg">
          <p className="text-red-500 my-3 pl-3">Data Not Created</p>
          <MUITableGrid
            name="studentsNotCreatedFromExcel"
            height={320}
            dataObject={notCreated}
          />
        </div>
      ) : null}
    </div>
  );
}
