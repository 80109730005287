import React, { useEffect } from 'react'
// import SwitchButton from '../../../components/button/SwitchButton'
import { FaPlus } from 'react-icons/fa'
import TableButton from '../../../components/button/TableButton'
import Swal from 'sweetalert2'
import { createCourseOrderInstallement, getCourseOrderInstallement } from '../../../service/admin/adminCourses.service'
import { toast } from 'react-toastify'
import { createStudentCourseInstallments } from '../../../service/admin/adminStudent.service'

export default function AdminCourseEMI(props:{data:any,title?:any}) {
  const [emiData, setEmiData] = React.useState<any>([
    { id:'',amount: props.data.amount, date: props.data.days.split(' ')[0], status: '' }
  ])
  const [totalEMI, setTotalEMI] = React.useState(1)
  const [readOnly, setReadOnly] = React.useState(false)
  const [newTotalAmount, setNewTotalAmount] = React.useState(props.data.amount)
  const [newTotalDays, setNewTotalDays] = React.useState(props.data.days.split(' ')[0])

  // getCourseOrderInstallement
  const fetchApi = async ()=>{
    const get:any = await getCourseOrderInstallement(props.data.id)
    if(get?.status === "success"){
      let newData:any = [];
      if(get?.message.length > 0){
        get.message.map((item:any)=>{
          newData.push({id:item.id,amount:item.installment_amount, date:item.days, status: '' })
        })
        setTotalEMI(get.message.length)
      setEmiData(newData)

        
      }
    }
  }
  useEffect(()=>{
    fetchApi()
  },[])
  const handleAddEMI = () => {
    setNewTotalAmount(props.data.amount)
    setNewTotalDays(props.data.days.split(' ')[0])
    setTotalEMI(totalEMI + 1);
    setEmiData([...emiData, { id:'',amount: props.data.amount-newTotalAmount, date: props.data.days.split(' ')[0]-newTotalDays, status: '' }]);
  };

  // Handler to update the EMI data
  const handleEMIChange = (
    index: number,
    field: keyof typeof emiData[number],
    value: string
  ) => {
    // Update the selected EMI field
    const updatedEMIData = emiData.map((emi: any, i: number) =>
      i === index ? { ...emi, [field]: value } : emi
    );
  
    // Calculate totals
    let newAmount = 0;
    let newDays = 0;
    updatedEMIData.forEach((item: any) => {
      newAmount += parseInt(item.amount || "0", 10); // Default to 0 if invalid
      newDays += parseInt(item.date || "0", 10); // Default to 0 if invalid
    });
  
    // Update state
    setEmiData(updatedEMIData);
    setNewTotalAmount(newAmount);
    setNewTotalDays(newDays);
  };
  
  const handleSubmit = async()=>{
    Swal.fire({
      title: "Do you want to save the changes?",
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`
    }).then(async(result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        let sendindData:any = [];
        emiData.map((item:any)=>{
          sendindData.push({installment_amount:item.amount, days:item.date})
        })
        let cr:any;
        if(props.title && props.title === "studentInstallments"){
          
         cr =  await createStudentCourseInstallments(props.data.stu,props.data.id,sendindData)
         console.log(cr)
        }else{
         cr =  await createCourseOrderInstallement(props.data.id,sendindData)
         console.log(cr)
        }
       if(cr?.status=== "success"){
        return toast.success('EMI Updated Successfully')
       }
      } 
    });
  }
  const handleDeleteRow = (index: number) => {
    const filteredData = emiData.filter((_:any, i:any) => i !== index);
    setEmiData(filteredData);
  };
  return (
    <>
      <div className="mb-3 flex items-center justify-between">
        <div>
          <p>Total EMIs: {totalEMI}</p>
        </div>
        <div className="flex items-center gap-3">
          {/* <div>
            <label htmlFor="">Status</label>
            <SwitchButton checked={true} />
          </div> */}
          <button
            onClick={handleAddEMI}
            className="px-3 py-2 bg-purple2 text-white flex gap-2 items-center justify-center rounded-lg"
          >
            <FaPlus /> Add EMI
          </button>
        </div>
      </div>
      <div>
      {
        emiData.length > 0 ? 
        <div className="relative overflow-x-auto rounded-lg  max-h-[300px] min-h-[100px]">
      <table className="w-full   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className='p-2 '>S.no</th>
            <th className='p-2 '>Duration</th>
            <th className='p-2 '>Amount</th>
            <th className='p-2 '></th>
          </tr>
        </thead>
        <tbody>
        {emiData.map((emi:any, index:any) => (
          <tr key={index}>
            <td className='p-2 m-2 items-center'>{index+1}</td>
            <td>
            <input
              type="number"
              min={0}
              placeholder="duration in days"
              defaultValue={emi.date}
              readOnly={readOnly}
              onChange={(e) => handleEMIChange(index, 'date', e.target.value)}
              className="p-2 rounded-lg m-2 bg-gray-300"
            />
            </td>
            <td> 
              <input
              type="text"
              placeholder="amount"
              defaultValue={emi.amount}
              onChange={(e) => handleEMIChange(index, 'amount', e.target.value)}
               className="p-2 rounded-lg m-2 bg-gray-300"
            /></td>
            <td><TableButton onClick={() => handleDeleteRow(index)} toottip='remove' icon={true} title='delete'/></td>
          </tr>
        ))}
        <tr>
          <td className='p-2 m-2 items-center font-bold'>Total</td>
          <td className='p-2 m-2 font-semibold'>{newTotalDays}/{props.data.days}</td>
          <td className='p-2 m-2 font-semibold'>{newTotalAmount}/{props.data.amount}</td>
        </tr>
        <tr>
         
          <td colSpan={4}>
          <button
          onClick={handleSubmit}
            className="px-3 w-[90%] mx-[5%] py-2 bg-purple2 text-white  flex gap-2 items-center justify-center rounded-lg"
          >
            Save
            </button>
            </td>
        </tr>
        </tbody>
      </table>
    </div>
    :
    <div className='w-full h-[100px] flex items-center justify-center border border-dashed border-gray-700'>
        <p className=' text-xl text-gray-600'>No EMIs Found</p>
    </div>
      }
 
      </div>
    </>
  );
}
