import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';
import { toast } from 'react-toastify';
import CustomButton from '../../../components/button/CustomButton';
import { createAdminSubMOdVideosRelation } from '../../../service/admin/adminVideoes.service';
import { Helmet } from 'react-helmet-async';

export default function AdminVideoSubMOduleAdd() {
  const [videos, setVideos] = useState([]);
  const location = useLocation();
  let moduleId =
    location.pathname.split('/')[location.pathname.split('/').length - 1];
  let moduleName: any = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const handleSubmit = (data: any) => {
    setVideos(data);
  };
  const handleFormSubmit = async () => {
    if (videos.length === 0) {
      return toast.error('Please select videos');
    }
    let dataa = {};
    if (moduleName.get('mod') === 'undefined') {
      dataa = {
        fk_submodule_id: moduleId,
        videos: videos,
      };
    } else {
      dataa = {
        fk_module_id: moduleName.get('mod'),
        fk_submodule_id: moduleId,
        videos: videos,
      };
    }
    const sub: any = await createAdminSubMOdVideosRelation(dataa);
    if (sub?.status === 'success') {
      toast.success('Videos added successfully');
      navigate(`/admin/videos/list/${moduleId}?name=${moduleName.get('name')}`);
    }
  };
  return (
    <>
      <Helmet>
        <title>{`Add Videos Submodules  | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`Add Video to ${moduleName.get('name')}`}>
        {videos.length > 0 ? (
          <CustomButton
            onClick={handleFormSubmit}
            title="Submit"
            icon={true}
            iconName="create"
          />
        ) : null}
      </BreadcrumbBack>
      <div className="my-2">
        <PaginationDataGrid
          checkbox={true}
          onSubmit={handleSubmit}
          name="adminSubModulesVideoNotVideo"
          dataId={moduleId}
        />
      </div>
    </>
  );
}
