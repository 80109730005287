import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { statusList, toBoolean } from '../../../../helpers/helpers';
import { useDispatch } from 'react-redux';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import RadioButton from '../../../../components/form/RadioButton';
import FormButton from '../../../../components/button/FormButton';
import {
  adminLiveClassAccountTypes,
  adminLiveClassSubTypes,
} from '../../../../types/AdminTypes';
import {
  createAdminLiveClassAccount,
  createAdminLiveClassSubmodule,
  editAdminLiveClassAccount,
  editAdminLiveClassSubmodule,
  showAdminLiveClassAccount,
  showAdminLiveClassSubmodule,
} from '../../../../service/admin/adminLiveClass.service';

export default function AdminLiveClassAccountCE(props: {
  type: string;
  data?: any;
  open: any;
}) {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [url, setUrl] = useState<any>();
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] =
    useState<any>(false);
  const [pageContent, setPageContent] = useState({
    button: 'Create Live Class Submodule',
  });

  const fetchBatchDetail = async (id: string) => {
    const get: any = await showAdminLiveClassAccount(id);
    if (get?.status === 'success') {
      setUrl(get.message?.image);
      setFetchData(get.message);
    }
  };
  useEffect(() => {
    setPageLoad(true);
    if (props.type === 'edit') {
      fetchBatchDetail(props.data.id);
      setPageContent({
        button: 'Update Live Class Submodule',
      });
    }
    // fetchInstituteCenter()
    setPageLoad(false);
  }, []);

  const { handleSubmit, control } = useForm<adminLiveClassAccountTypes>({
    values: {
      email: fetchData?.email,
      clientId: fetchData?.clientId,
      clientSecret: fetchData?.clientSecret,
      serverAccount: fetchData?.serverAccount,
      serverClientId: fetchData?.serverClientId,
      serverClientSecret: fetchData?.serverClientSecret,
      status: `${fetchData?.status ? fetchData?.status : true}`,
    },
  });
  const onSubmit: SubmitHandler<adminLiveClassAccountTypes> = async (
    data: adminLiveClassAccountTypes
  ) => {
    setLoading(true);
    let fetch: any;
    let status_: any = toBoolean(data.status);
    data.status = status_;
    if (props.type === 'create') {
      fetch = await createAdminLiveClassAccount(data);
    }
    if (props.type === 'edit') {
      fetch = await editAdminLiveClassAccount(fetchData?.id, data);
    }
    if (fetch?.status === 'success') {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <Input
          name="email"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="email"
          placeholder="email"
        />
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-12 sm:col-span-12 md:col-span-6">
            <Input
              name="clientId"
              control={control}
              rules={{
                ...formValidationRules.required(),
                ...formValidationRules.minLength(3),
              }}
              label="Client Id"
              placeholder="Client Id"
            />
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-6">
            <Input
              name="clientSecret"
              control={control}
              rules={{
                ...formValidationRules.required(),
                ...formValidationRules.minLength(3),
              }}
              label="Client Secret"
              placeholder="Client Secret"
            />
          </div>
          <div className="col-span-12">
            <Input
              name="serverAccount"
              control={control}
              rules={{
                ...formValidationRules.required(),
                ...formValidationRules.minLength(3),
              }}
              label="Server Account"
              placeholder="Server Account"
            />
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-6">
            <Input
              name="serverClientId"
              control={control}
              rules={{
                ...formValidationRules.required(),
                ...formValidationRules.minLength(3),
              }}
              label="Server ClientId"
              placeholder="Server ClientId"
            />
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-6">
            <Input
              name="serverClientSecret"
              control={control}
              rules={{
                ...formValidationRules.required(),
                ...formValidationRules.minLength(3),
              }}
              label="Server Client Secret"
              placeholder="Server Client Secret"
            />
          </div>
        </div>

        <div className="my-3">
          <RadioButton
            name="status"
            control={control}
            rules={{ ...formValidationRules.required() }}
            options={statusList}
            label="status"
          />
        </div>
        <br />
        <FormButton loading={loading} title={pageContent.button} />
      </form>
    </>
  );
}
