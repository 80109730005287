import React, { useEffect, useState } from 'react';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../components/button/CustomButton';
import Models from '../../../../components/models/Models';
import AdminTestSubModulesCE from './AdminTestSubModulesCE';
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { randomNumber } from '../../../../helpers/helpers';
import { Helmet } from 'react-helmet-async';

export default function AdminTestSubModules() {
  const [open, setOpen] = useState(false);
  const [moduleType, setModuleType] = useState<any>();
  const [change, setChange] = useState<any>(null);

  const navigate = useNavigate();

  let location = useLocation();
  const query = new URLSearchParams(location.search);
  let moduleId = location.pathname.split('/')[6 - 1];
  let subMOd = location.pathname.split('/')[5 - 1];
  console.log(location.pathname.split('/')[4]);
  useEffect(() => {
    setModuleType(null);
    if (moduleId && subMOd) {
      setModuleType('nmod');
      setChange(randomNumber(100));
    } else {
      setModuleType('smod');
    }
  }, [setModuleType, location, moduleType, setChange]);
  return (
    <>
      <Helmet>
        <title>{` Test Series   | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`${query.get('bredcrum')} modules`}>
        <CustomButton
          onClick={() =>
            navigate(
              `/admin/test-series/submodules-group-test/${location.pathname.split('/')[4]}?bredcrum=${query.get('bredcrum')}&md=${moduleType === 'smod' ? subMOd : moduleId}&cat=${moduleType === 'smod' ? 'smod' : 'nmod'}`
            )
          }
          bg={'bg-purple-950'}
          title="Group Test Series"
          icon={true}
          iconName="folderTree"
        />
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create Sub Module"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <div className="my-2">
          {change ? (
            moduleType !== 'smod' ? (
              <PaginationDataGrid
                name="adminTestSeriesNSubModules"
                dataObject="single"
                dataId={subMOd}
                id={moduleId}
              />
            ) : null
          ) : null}
          {moduleType === 'smod' ? (
            <PaginationDataGrid
              name="adminTestSeriesSubModules"
              dataObject="single"
              dataId={subMOd}
            />
          ) : null}
        </div>
      </div>
      <Models
        name="Create Test Series Sub Module"
        isOpen={open}
        isClose={setOpen}
      >
        <AdminTestSubModulesCE
          type="create"
          category={moduleType}
          open={setOpen}
          data={{ mod: subMOd, submod: moduleId }}
        />
      </Models>
    </>
  );
}
