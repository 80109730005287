import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadCrumRoutes } from '../../../helpers/helpers';
import Loaders from '../../../components/loaders/Loaders';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import SearchableSelect from '../../../components/form/SearchableSelect';
import { useForm } from 'react-hook-form';
import {
  FaCheck,
  FaCross,
  FaDotCircle,
  FaDownload,
  FaSlash,
} from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { PiExam, PiTarget } from 'react-icons/pi';
import AccordianMUI from '../../../components/accordion/AccordianMUI';
import Cookies from 'js-cookie';
import { IoMdCloseCircle } from 'react-icons/io';
import { StudentTestSeriesBasicResult } from '../../../service/student/student.service';
import PieChartMUI from '../../../components/charts/PieChartMUI';
import { Helmet } from 'react-helmet-async';
export default function StudentResultBasic() {
  const [pageLoad, setPageLoad] = useState(false);
  const [resultLoad, setResultLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>([]);
  const [bradCrumData, setBreadcrumbData] = useState<any>();
  const [attempt, setAttempt] = useState<any>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchResult = async (attempt: any) => {
    setResultLoad(true);
    let getRoutes: any = Cookies.get('routes');
    let route: any = JSON.parse(getRoutes);
    let check = route.filter((e: any) => e.key === 'studentTestSeriesModule');
    let get: any = await StudentTestSeriesBasicResult(
      location.pathname.split('/')[5].split('?')[0],
      check[0].link.split('/')[4].split('?')[0],
      attempt
    );
    if (get?.status === 'success') {
      setFetchData(get?.message);
      if (get?.message?.attemps) {
        let attempts = [];
        for (let i = 1; i <= get?.message?.attemps; i++) {
          attempts.push({
            label: `Attempt ${i}`,
            value: `${i}`,
          });
        }
        setAttempt(attempts);
      }
      setResultLoad(false);
    }
    setResultLoad(false);
  };

  const params = new URLSearchParams(location.search);
  useEffect(() => {
    fetchResult(1);
    let brd: any =
      params.get('name') !== null ? params.get('name') : 'Submodule';
    setBreadcrumbData([]);
    setBreadcrumbData(
      BreadCrumRoutes(
        `${brd} Result`,
        '/student/test-series/submodules/' +
          location.pathname.split('/')[4] +
          '?name=' +
          brd,
        'studentTestSeriesSubModuleResult' + brd,
        false
      )
    );
  }, [location]);

  const { control } = useForm({
    values: {
      attempt: '1',
    },
  });

  const parseSingleLine = (line: any) => {
    let m: any = '';
    if (line) {
      line.replace(/<br\s*\/?>/gi, '');
      let m = line.match(/<p>.*?<\/p>|<li>.*?<\/li>/i)?.[0];
      if (!m) {
        return line;
      }
    }

    return m;
  };

  const handleAttemptChange = (e: any) => {
    fetchResult(e.value);
  };

  return pageLoad ? (
    <Loaders type="spinner" />
  ) : (
    <>
      <Helmet>
        <title>{`Test Series Result | ${window.location.hostname}`}</title>
      </Helmet>
      <div className="flex flex-wrap justify-between">
        <BreadCrumb routes={bradCrumData} />
        <div className="w-[100%] sm:w-[100%] md:w-[50%] lg:w-[30%] flex flex-row gap-3 justify-end items-center">
          <div className="w-full">
            <SearchableSelect
              name="attempt"
              control={control}
              onFetch={handleAttemptChange}
              options={attempt}
              label=""
              placeholder="Select Attempt"
            />
          </div>
          {/* <Tooltip title="Download Result">
            <button className="mb-5">
              <FaDownload className="text-2xl text-purple2" />
            </button>
          </Tooltip> */}
        </div>
      </div>

      {resultLoad ? (
        <Loaders type="spinner" />
      ) : (
        <>
          <div className="my-4 grid grid-cols-12 gap-4">
            <div className=" col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4">
              <div className=" my-1 relative ">
                <div className="bg-white p-3 rounded-lg shadow-lg ">
                  <p className="absolute right-5 top-2 font-bold">
                    SCORED MARKS
                  </p>
                  <div className="flex flex-row gap-3 items-center justify-between">
                    <PiExam className="text-[5rem] text-purple-800" />
                    <div className="flex flex-row mt-4">
                      <p className="text-4xl font-semibold mt-1 text-gray-700">
                        {fetchData?.obtainMarks?.toFixed(1)}
                      </p>
                      <p className="text-5xl font-bold text-gray-600">/</p>
                      <p className="text-4xl font-semibold mt-3 text-purple2">
                        {fetchData?.totalMarks}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" my-2 relative">
                <div className="bg-white p-3 rounded-lg shadow-lg ">
                  <p className="absolute right-5 top-2 font-bold">ACCURACY</p>
                  <div className="flex flex-row gap-3 items-center justify-between">
                    <PiTarget className="text-[5rem] text-red-700" />
                    <div className="flex flex-row mt-4 items-center">
                      <p className="text-4xl font-semibold text-gray-700">
                        {fetchData?.accuracy?.toFixed(1)}
                      </p>
                      <p className="text-3xl font-bold text-gray-600">%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4">
              <div className="bg-white p-4 rounded-lg shadow-lg mt-1">
                <div className="flex justify-between items-center">
                  <p className="text-xl font-bold mb-2">Questions Analysis</p>
                  <p className="text-xl font-bold mb-2">
                    Total :{' '}
                    {fetchData?.totalWrongQuestions +
                      fetchData?.totalCorrectQuestions +
                      fetchData?.totalLeaveQuestions}
                  </p>
                </div>
                <PieChartMUI
                  data={[
                    {
                      id: 0,
                      value: parseInt(fetchData?.totalCorrectQuestions),
                      label: 'Correct',
                      color: '#4ade80',
                    },
                    {
                      id: 1,
                      value: parseInt(fetchData?.totalWrongQuestions),
                      label: 'Wrong',
                      color: '#f87171',
                    },
                    {
                      id: 2,
                      value: parseInt(fetchData?.totalLeaveQuestions),
                      label: 'Leave',
                      color: '#a3a3a3',
                    },
                  ]}
                />
              </div>
            </div>
            <div className=" col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4">
              <div className="bg-white p-4 rounded-lg shadow-lg mt-1">
                <div className="flex items-center justify-between">
                  <p className="text-xl font-bold mb-2">
                    Total Marks : {fetchData?.totalMarks}
                  </p>
                  <p className="text-xl font-bold mb-2">
                    Negative Marks : {fetchData?.negativeMarks}
                  </p>
                </div>
                <PieChartMUI
                  data={[
                    {
                      id: 0,
                      value: parseInt(fetchData?.obtainMarks),
                      label: 'Correct',
                      color: '#4ade80',
                    },
                    {
                      id: 1,
                      value: parseInt(fetchData?.totalMarks),
                      label: 'Wrong',
                      color: '#f87171',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          {fetchData?.subjectAnalysis?.length > 0 && (
            <div className="my-3  bg-white p-2 rounded-lg">
              <p className="text-3xl font-bold mb-2 text-gray-700">
                Subject Analysis
              </p>
              <div className="my-3 relative overflow-x-auto rounded-lg  max-h-[300px] min-h-[100px] ">
                <table className="w-full   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="py-2 px-2 text-center">
                        #
                      </th>
                      <th scope="col" className="py-2 px-2 text-center">
                        Subject
                      </th>
                      <th scope="col" className="py-2 px-2 text-center">
                        Questions
                      </th>
                      <th scope="col" className="py-2 px-2 text-center">
                        Attempt
                      </th>
                      <th scope="col" className="py-2 px-2 text-center">
                        Wrong
                      </th>
                      <th scope="col" className="py-2 px-2 text-center">
                        Leave
                      </th>
                      {/* <th scope="col" className="py-2 px-2">
                         Status
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {fetchData?.subjectAnalysis.map((e: any, index: number) => (
                      <tr
                        key={index}
                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 text-center even:dark:bg-gray-800 border-b dark:border-gray-700"
                      >
                        <td className="px-2 py-2 text-center">{index + 1}</td>
                        <td className="px-2 py-2 text-center">{e?.sb}</td>
                        <td className="px-2 py-2 text-center">
                          {e?.correct + e?.wrong + e?.leave}
                        </td>
                        <td className="px-2 py-2 text-center">{e?.correct}</td>
                        <td className="px-2 py-2 text-center">{e?.wrong}</td>
                        <td className="px-2 py-2 text-center">{e?.leave}</td>
                        {/* <td>

                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {fetchData?.totalMarks != fetchData?.obtainMarks && (
            <div className="my-3">
              <p className="text-3xl font-bold mb-2 text-gray-700">
                Question Analysis
              </p>
              <div className="">
                {fetchData?.wrongQuestions?.length > 0 && (
                  <div className="my-3 bg-white p-2 rounded-lg">
                    <p className="text-xl font-bold mb-2 text-red-700">
                      Wrong Question Analysis
                    </p>
                    <div className="my-3">
                      {fetchData?.wrongQuestions?.map(
                        (item: any, index: any) => (
                          <AccordianMUI
                            id={`${item?.id}`}
                            key={index}
                            title={`Q.${index + 1} ${parseSingleLine(
                              item?.question
                            )}`}
                          >
                            <div>
                              {`${parseSingleLine(item?.description)}`}
                              <div className="mb-2">
                                <b className="flex items-center  gap-2 ">
                                  <IoMdCloseCircle className="text-red-600 text-sm" />{' '}
                                  Your Answer
                                </b>
                                <div
                                  className="ml-4"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.yourAnswer,
                                  }}
                                ></div>
                              </div>
                              <div className="mb-2">
                                <b className="flex items-center  gap-2 ">
                                  <FaCheck className="text-green-600 text-sm" />{' '}
                                  Correct Answer
                                </b>
                                <div
                                  className="ml-4"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.correctAns,
                                  }}
                                ></div>
                              </div>
                              <div>
                                {item?.explaination && (
                                  <>
                                    <b className="flex items-center  gap-2 ">
                                      <FaDotCircle className="text-blue-600 text-sm" />{' '}
                                      Explaination
                                    </b>
                                    <div
                                      className="ml-4"
                                      dangerouslySetInnerHTML={{
                                        __html: item?.explaination,
                                      }}
                                    ></div>
                                  </>
                                )}
                                <br />
                                {item?.video_link && (
                                  <>
                                    <a
                                      className={`className='ml-4'`}
                                      href={item?.video_link}
                                      target="_blank"
                                    >
                                      Watch Video
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </AccordianMUI>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div>
                {fetchData?.leaveQuestions?.length > 0 && (
                  <div className="my-3 bg-white p-2 rounded-lg">
                    <p className="text-xl font-bold mb-2 text-red-700">
                      Leave Question Analysis
                    </p>
                    <div className="my-3">
                      {fetchData?.leaveQuestions?.map(
                        (item: any, index: any) => (
                          <AccordianMUI
                            id={`${item?.id}`}
                            key={index}
                            title={`Q.${index + 1} ${parseSingleLine(
                              item?.question
                            )}`}
                          >
                            {`${parseSingleLine(item?.description)}`}

                            <div className="mb-2">
                              <b className="flex items-center  gap-2 ">
                                <FaCheck className="text-green-600 text-sm" />{' '}
                                Correct Answer
                              </b>
                              <div
                                className="ml-4"
                                dangerouslySetInnerHTML={{
                                  __html: item?.correctAns,
                                }}
                              ></div>
                            </div>

                            <div>
                              {item?.explaination && (
                                <>
                                  <b className="flex items-center  gap-2 ">
                                    <FaDotCircle className="text-blue-600 text-sm" />{' '}
                                    Explaination
                                  </b>
                                  <div
                                    className="ml-4"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.explaination,
                                    }}
                                  ></div>
                                </>
                              )}
                              <br />
                              {item?.video_link && (
                                <>
                                  <a
                                    className={`className='ml-4'`}
                                    href={item?.video_link}
                                    target="_blank"
                                  >
                                    Watch Video
                                  </a>
                                </>
                              )}
                            </div>
                          </AccordianMUI>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
