import {
  adminExamAlertsTypes,
  adminFeedsTypes,
  AdminTypes,
} from '../../types/AdminTypes';
import instance from '../instace';

export const createAdminFeed = async (data: adminFeedsTypes) => {
  try {
    const get: any = await instance.post('/api/admin/feed/create', {
      fk_feedsType_id: data.fk_feedsType_id,
      name: data.name,
      url: data.url,
      status: data.status,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAdminFeed = async (page?: number, query?: string) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/feed/get?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const showAdminFeed = async (id: string) => {
  try {
    const get: any = await instance.get(`/api/admin/feed/show/${id}`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const editAdminFeed = async (id: string, data: adminFeedsTypes) => {
  try {
    const get: any = await instance.put(`/api/admin/feed/edit/${id}`, {
      fk_feedsType_id: data.fk_feedsType_id,
      name: data.name,
      url: data.url,
      status: data.status,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const updateStatusAdminFeed = async (id: string, status: boolean) => {
  try {
    const get: any = await instance.put(`/api/admin/feed/status/${id}`, {
      status: status,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const deleteAdminFeed = async (id: string) => {
  try {
    const get: any = await instance.delete(`/api/admin/feed/delete/${id}`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAdminFeedTypes = async () => {
  try {
    const get: any = await instance.get(`/api/admin/feed/get-feedtype`);
    return get.data;
  } catch (e) {
    return false;
  }
};
