import React, { useEffect, useState } from 'react';
import { studentCourseHoldPayments } from '../../../service/student/studentCourses.service';
import Loaders from '../../../components/loaders/Loaders';
import { Helmet } from 'react-helmet-async';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { BreadCrumRoutes } from '../../../helpers/helpers';
import { FaRupeeSign } from 'react-icons/fa';
import moment from 'moment';
import MUITableGrid from '../../../components/tables/MUITableGrid';

export default function HoldPayments() {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const [breadCrumData, setBreadCrumData] = useState<any>();

  useEffect(() => {
    setBreadCrumData(
      BreadCrumRoutes(
        'Pending Payments',
        '/student/payment/hold',
        'studentspaymenthold',
        true
      )
    );
  }, []);
  return pageLoad ? (
    <Loaders type="spinner" />
  ) : (
    <>
      <Helmet>
        <title>{`Pending Payments | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadCrumb routes={breadCrumData} />
      <MUITableGrid name="studentHoldPayment" />
    </>
  );
}
