import React from 'react'
import logo from '../../assets/images/logo.jpg'
import { useNavigate } from 'react-router-dom'
import { decryptData } from '../../helpers/helpers'
import { studentLogoutDevice } from '../../service/student/student.service'
import { toast } from 'react-toastify'
export default function () {
    const navigate = useNavigate()
    const handleLogout = async () => {
        const get:any = await studentLogoutDevice(decryptData(localStorage.getItem('temp_key')),decryptData(localStorage.getItem('temp_process')))
        if(get?.status === "success"){
            toast.success(get?.message)
            localStorage.removeItem('temp_key')
            localStorage.removeItem('temp_process')
            navigate('/')
        }
    }
  return (
    <div className=' bg-red-400'>
        <div className='flex justify-center p-3'>
            <img src={logo} alt="" />
        </div>
        <div className=' h-[90vh] flex items-center justify-center flex-col gap-5 p-2'>
            <p className='text-white text-[3rem]'>You are already logged in on another device.</p>
            <div className='flex gap-4'>
                <button onClick={(()=>navigate('/'))} className='px-4 py-2 bg-blue-500 text-white rounded-xl text-xl'>
                    Home
                </button>
                <button onClick={handleLogout} className='px-4 py-2 bg-purple-600 text-white rounded-xl text-xl'>
                    Logout From Other Device    
                </button>
            </div>
        </div>
    </div>
  )
}
