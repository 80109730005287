import React, { useState } from 'react';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../components/button/CustomButton';
import { useNavigate } from 'react-router-dom';
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid';
import Models from '../../../../components/models/Models';
import AdminLiveClassSubModCE from './AdminLiveClassSubModCE';
import { Helmet } from 'react-helmet-async';

export default function AdminLiveClassSubMod() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{` Live Class    | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`Live Class Submodules`}>
        <CustomButton
          onClick={() => setOpen(!open)}
          bg="bg-purple-800"
          title="Create Submodule"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>

      <div className="my-2">
        <PaginationDataGrid name="adminLiveClassSubModules" />
      </div>
      <Models
        name="Create New Live Class Submodule"
        isOpen={open}
        isClose={setOpen}
      >
        <AdminLiveClassSubModCE type="create" open={setOpen} />
      </Models>
    </>
  );
}
