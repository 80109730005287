import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadCrumRoutes } from '../../../helpers/helpers';
import {
  getStudentTestSeriesCoursesNSubModules,
  getStudentTestSeriesCoursesSubModules,
  StudentTestSeriesAttempt,
} from '../../../service/student/student.service';
import Cookies from 'js-cookie';
import DataNotFound from '../../../components/errors/DataNotFound';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import Loaders from '../../../components/loaders/Loaders';
import { Helmet } from 'react-helmet-async';

export default function StudentTestSeriesNmodules() {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>([]);
  const [bradCrumData, setBreadcrumbData] = useState<any>();
  const [root, setRoot] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const fetchSubModules = async (id: string) => {
    let getRoutes: any = Cookies.get('routes');
    let route: any = JSON.parse(getRoutes);
    let check = route.filter((e: any) => e.key === 'studentTestSeriesModule');
    console.log(check[0].link.split('/')[4].split('?')[0]);
    let get: any = await getStudentTestSeriesCoursesSubModules(
      id,
      check[0].link.split('/')[4].split('?')[0]
    );
    if (get?.status === 'success') {
      setFetchData(get?.message);
    }
  };
  const fetchNSubMOdules = async (id: string) => {
    let getRoutes: any = Cookies.get('routes');
    let route: any = JSON.parse(getRoutes);
    let check = route.filter((e: any) => e.key === 'studentTestSeriesModule');
    console.log(check[0].link.split('/')[4].split('?')[0]);
    let get: any = await getStudentTestSeriesCoursesNSubModules(
      id,
      check[0].link.split('/')[4].split('?')[0]
    );
    if (get?.status === 'success') {
      setFetchData(get?.message);
    }
  };
  useEffect(() => {
    let brd: any =
      params.get('name') !== null ? params.get('name') : 'Submodule';
    setBreadcrumbData([]);
    if (params.get('nmod')) {
      fetchNSubMOdules(location.pathname.split('/')[4]);
      setBreadcrumbData(
        BreadCrumRoutes(
          brd,
          '/student/test-series/submodules/' +
            location.pathname.split('/')[4] +
            '?name=' +
            brd +
            '&nmod=true',
          'studentTestSeriesSubModule' + brd,
          false
        )
      );
    } else {
      fetchSubModules(location.pathname.split('/')[4]);
      setBreadcrumbData(
        BreadCrumRoutes(
          brd,
          '/student/test-series/submodules/' +
            location.pathname.split('/')[4] +
            '?name=' +
            brd,
          'studentTestSeriesSubModule' + brd,
          false
        )
      );
    }
  }, [location]);

  const handleStartTest = async (id: string) => {
    let routes: any = JSON.parse(Cookies.get('routes')!);
    let courseId: any = routes[1].link?.split('/')[4];
    const check: any = await StudentTestSeriesAttempt(
      id,
      courseId.split('?')[0]
    );
    if (check?.status === 'success') {
      localStorage.removeItem('storedQuestion');
      localStorage.removeItem('timer');
      navigate(`/student/testseries/question/${id}`);
    }
    //
  };
  return pageLoad ? (
    <Loaders type="spinner" />
  ) : (
    <>
      <Helmet>
        <title>{`Test Series | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadCrumb routes={bradCrumData} />
      <div className="my-2 grid grid-cols-12 gap-3">
        {fetchData?.length > 0 ? (
          fetchData.map((item: any, index: number) => (
            <>
              {item?.marks !== null && item?._count?.testSeries !== 0 && (
                <div
                  key={index}
                  className=" hover:cursor-pointer lg:col-span-3 col-span-12 sm:col-span-12 md:col-span-6   bg-gray-200 rounded-xl"
                >
                  <div className="flex flex-col  gap-2 border-l-4 rounded-lg p-3 border-purple-900">
                    <>
                      <p className="text-center text-3xl font-semibold my-3">
                        {item?.name}
                      </p>
                      <hr className="h-[2px] bg-primary mb-4" />
                      <div className="grid grid-cols-12">
                        <div className=" col-span-5">
                          <b>Duration : </b> {item?.duration}
                        </div>
                        <div className=" col-span-7">
                          <b>Attempts : </b>{' '}
                          {item?.testSeriesAttempt[0]?.attempts
                            ? item?.testSeriesAttempt[0]?.attempts
                            : 0}
                          /{item?.attempt}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 mb-2">
                        <div className=" col-span-5">
                          <b>Marks : </b> {item?.marks}
                        </div>
                        <div className=" col-span-7">
                          <b>Negative Marks : </b> {item?.negative_marks}
                        </div>
                      </div>
                      <div className="flex gap-3">
                        {item?.testSeriesAttempt[0]?.attempts ? (
                          item?.testSeriesAttempt[0]?.attempts !=
                            item?.attempt && (
                            <button
                              onClick={() => handleStartTest(item?.id)}
                              className="px-4 w-full py-2 bg-purple-900 text-white rounded-lg"
                            >
                              Start Test
                            </button>
                          )
                        ) : (
                          <button
                            onClick={() => handleStartTest(item?.id)}
                            className="px-4 w-full py-2 bg-purple-900 text-white rounded-lg"
                          >
                            Start Test
                          </button>
                        )}
                        {item?.testSeriesAttempt[0]?.attempts && (
                          <button
                            onClick={() =>
                              navigate(
                                `/student/test-series/result/basic/${item?.id}?name=${item?.name}`
                              )
                            }
                            className="px-4 w-full py-2 bg-blue-950 text-white rounded-lg"
                          >
                            Result
                          </button>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              )}
              {item?.marks === null && (
                <div
                  onClick={() =>
                    navigate(
                      `/student/test-series/submodules/${item.id}?name=${item?.name}&nmod=true`
                    )
                  }
                  key={index}
                  className=" hover:cursor-pointer lg:col-span-3 col-span-12 sm:col-span-12 md:col-span-6   bg-gray-200 rounded-xl"
                >
                  <div className="flex flex-col  gap-2 border-l-4 rounded-lg p-3 border-purple-500">
                    <>
                      <p className="text-center text-3xl font-semibold my-3">
                        {item?.name}
                      </p>
                      <hr className="h-[2px] bg-primary mb-4" />
                      <div className="grid grid-cols-12">
                        <div className=" col-span-5">
                          <b>Duration : </b>
                        </div>
                        <div className=" col-span-7">
                          <b>Attempts : </b>
                        </div>
                      </div>
                      <div className="grid grid-cols-12 mb-2">
                        <div className=" col-span-5">
                          <b>Marks : </b>
                        </div>
                        <div className=" col-span-7">
                          <b>Negative Marks : </b>
                        </div>
                      </div>
                      <button className="px-4 w-full py-2 bg-purple-500 text-white rounded-lg">
                        Show Test Series
                      </button>
                    </>
                  </div>
                </div>
              )}
            </>
          ))
        ) : (
          <div className="col-span-12">
            <DataNotFound title="No Test Series Found" />
          </div>
        )}
      </div>
    </>
  );
}
