import instance from '../instace';

export const getPaymethodMethodMaster = async () => {
  try {
    const get: any = await instance.get(
      `/api/admin/payments/master-method/all`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const createAdminPaymethodMethodMaster = async (
  fk_payment_method_master_id: string,
  name: string,
  status: boolean
) => {
  try {
    const get: any = await instance.post('/api/admin/payments/method/create', {
      name,
      status,
      fk_payment_method_master_id,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAdminPaymethodMethodMaster = async () => {
  try {
    const get: any = await instance.get(`/api/admin/payments/method`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const showAdminPaymethodMethodMaster = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/payments/method/show/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const updateAdminPaymethodMethodMaster = async (
  id: string,
  fk_payment_method_master_id: string,
  name: string,
  status: boolean
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/payments/method/show/${id}`,
      {
        fk_payment_method_master_id,
        name,
        status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const updateStatusAdminPaymethodMethodMaster = async (
  id: string,
  status: boolean
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/payments/method/status/${id}`,
      {
        status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const deleteAdminPaymethodMethodMaster = async (id: string) => {
  try {
    const get: any = await instance.delete(
      `/api/admin/payments/method/delete/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const createAdminPaymethodMethodSetup = async (data: any) => {
  try {
    const get: any = await instance.post(
      `/api/admin/payments/method/setup/create`,
      {
        fk_admin_payment_master_id: data.fk_admin_payment_master_id,
        mechant_id: data.mechant_id,
        mechant_user_id: data.mechant_user_id,
        salt_key: data.salt_key,
        salt_index: data.salt_index,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const showAdminPaymethodMethodSetup = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/payments/method/setup/show/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getAdminPendingPayments = async (
  page?: number,
  query?: string
) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/payments/all/payment/hold?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const courseOrderApprove = async (id: string) => {
  try {
    const get: any = await instance.post(`/api/admin/payments/course/approve`, {
      id: id,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const courseOrderReject = async (id: string, comment: any) => {
  try {
    const get: any = await instance.put(
      `/api/admin/payments/course/reject/${id}`,
      {
        comment: comment,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
