import React, { useState } from 'react';
import Select from 'react-select';
import { Control, useController } from 'react-hook-form';

interface Option {
  value: string;
  label: string;
}
interface SearchableSelectProps {
  name: string;
  control: Control<any>;
  options: Option[];
  rules?: any;
  label: string;
  placeholder?: string;
  onFetch?: any;
  defaultValue?: Option[];
  selectedValue?: any;
}
const MultiSearchableSelect: React.FC<SearchableSelectProps> = ({
  name,
  control,
  options,
  rules,
  label,
  placeholder,
  defaultValue,
  onFetch,
}) => {
  const [prevSelectedOptions, setPrevSelectedOptions] = useState<Option[]>(
    defaultValue || []
  );
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue,
  });
  const handleChange = (selectedOptions: any) => {
    const values: any = selectedOptions
      ? selectedOptions.map((option: any) => option.value)
      : [];
    const removedItem: any = prevSelectedOptions.find(
      (prevOption) =>
        !selectedOptions.some(
          (selectedOption: any) => selectedOption.value === prevOption.value
        )
    );
    field.onChange(values);
    setPrevSelectedOptions(selectedOptions || []);
    if (selectedOptions && onFetch) {
      onFetch({
        selected: values,
        removed: removedItem?.value,
      });
    }
  };

  // console.log(options)

  return (
    <div className="flex flex-col mb-3">
      <label
        htmlFor={name}
        className={`${error ? 'text-red-500' : 'text-gray-600 dark:text-gray-400'} ml-1`}
      >
        {label}{' '}
        {rules && rules.required && <sup className="text-red-500">*</sup>}
      </label>
      <Select
        {...field}
        id={name}
        styles={{
          menuPortal: (base) => ({ ...base, overflowY: 'auto' }),
          control: (provided, state) => ({
            ...provided,

            borderColor: state.isFocused
              ? 'rgb(126 34 206)'
              : error
                ? 'rgb(239 68 68)'
                : 'rgb(126 34 206)',
            backgroundColor:
              localStorage.getItem('theme') === 'dark'
                ? 'rgb(55 65 81)'
                : 'white',
            borderWidth: 1.6,
            color: 'white',
            borderRadius: 8,
            '&:hover': {
              borderColor: state.isFocused
                ? 'rgb(126 34 206)'
                : error
                  ? 'rgb(239 68 68)'
                  : 'rgb(126 34 206)',
            }, // Change border color based on focus
            // You can adjust other border styles here like borderWidth, borderRadius, etc.
          }),
          input: (provided) => ({
            ...provided,
            color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray', // Change the text color of the input
          }),
          placeholder: (styles) => ({
            ...styles,
            padding: 8,
            borderRadius: 10,
            border: 0,
            outline: 0,
            color: '#a0aec0',
            backgroundColor:
              localStorage.getItem('theme') === 'dark'
                ? 'rgb(55 65 81)'
                : 'white',
            borderColor: error ? 'rgb(239 68 68)' : '#2d3748',
          }),
          singleValue: (base) => ({
            ...base,
            padding: 8,
            borderRadius: 5,
            color:
              localStorage.getItem('theme') === 'dark'
                ? 'white'
                : 'rgb(55 65 81)',
            display: 'flex',
          }),
          multiValue: (base) => ({
            ...base,
            padding: 4,
            borderRadius: 5,
            color:
              localStorage.getItem('theme') === 'dark'
                ? 'white'
                : 'rgb(55 65 81)',
            display: 'flex',
          }),
          menu: (provided) => ({
            ...provided,
            scrollbarWidth: 'thin', // Makes the scrollbar thinner
            msOverflowStyle: 'auto', // Support for older browsers like IE
            zIndex: 9999,
            overflow: 'auto', // Enable vertical scrolling if the content exceeds the max height
          }),
        }}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        value={options.filter(
          (option) =>
            Array.isArray(field?.value) && field.value.includes(option?.value)
        )}
        isMulti
        defaultValue={defaultValue}
      />
      {error && <small className="text-red-500 ml-2">{error.message}</small>}
    </div>
  );
};

export default MultiSearchableSelect;
