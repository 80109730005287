import {
  AdminVideosTypes,
  AdminVimeoModulesTypes,
  AdminVimeoSubModulesTypes,
  importVimeoVideosTypes,
} from '../../types/AdminTypes';
import instance from '../instace';

export const getVideosTypes = async () => {
  try {
    const get: any = await instance.get(`/api/admin/videos/videos-types`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const createAdminVideos = async (data: AdminVideosTypes) => {
  try {
    const get: any = await instance.post(`/api/admin/videos/create`, {
      fk_videoType_id: data.fk_videoType_id,
      name: data.name,
      description: data.description,
      thumbnail: data.thumbnail,
      length: data.length,
      watchtime: data.watchtime,
      status: data.status,
      url: data.url,
      moduleId: data.moduleId,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getAdminVideos = async (page?: number, query?: string) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/videos/get?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const showAdminVideos = async (id: string) => {
  try {
    const get: any = await instance.get(`/api/admin/videos/show/${id}`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const editAdminVideos = async (id: string, data: AdminVideosTypes) => {
  try {
    const get: any = await instance.put(`/api/admin/videos/edit/${id}`, {
      fk_videoType_id: data.fk_videoType_id,
      name: data.name,
      description: data.description,
      thumbnail: data.thumbnail,
      length: data.length,
      watchtime: data.watchtime,
      status: data.status,
      url: data.url,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const updateStatusAdminVideo = async (id: string, status: boolean) => {
  try {
    const get: any = await instance.put(`/api/admin/videos/status/${id}`, {
      status: status,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const deleteAdminVideo = async (id: string) => {
  try {
    const get: any = await instance.delete(`/api/admin/videos/delete/${id}`);
    return get.data;
  } catch (e) {
    return false;
  }
};

export const createAdminVideosModule = async (data: AdminVimeoModulesTypes) => {
  try {
    const get: any = await instance.post(`/api/admin/videos/module/create`, {
      name: data.name,
      status: data.status,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getAdminVideosModule = async (page?: number, query?: string) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/videos/module/get?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const showAdminVideosModule = async (id: string) => {
  try {
    const get: any = await instance.get(`/api/admin/videos/module/show/${id}`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getVideosSubByModule = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/videos/module/submodules/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getVideosActiveModule = async () => {
  try {
    const get: any = await instance.get(`/api/admin/videos/module/active`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getVideosActiveSubModuleByModule = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/videos/module/active/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const editAdminVideosModule = async (
  id: string,
  data: AdminVimeoModulesTypes
) => {
  try {
    const get: any = await instance.put(`/api/admin/videos/module/edit/${id}`, {
      name: data.name,
      status: data.status,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const updateStatusAdminVideoModule = async (
  id: string,
  status: boolean
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/videos/module/status/${id}`,
      {
        status: status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const deleteAdminVideoModule = async (id: string) => {
  try {
    const get: any = await instance.delete(
      `/api/admin/videos/module/delete/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const createAdminVideosSubModule = async (
  data: AdminVimeoSubModulesTypes
) => {
  try {
    const get: any = await instance.post(`/api/admin/videos/submodule/create`, {
      name: data.name,
      status: data.status,
      restriction: data.restriction,
      fk_module_id: data.fk_module_id,
      fk_submodule_id: data.fk_submodule_id,
      types: data.types,
      fk_videosubmodule_id: data.fk_videosubmodule_id,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getAdminVideosSubModule = async (
  page?: number,
  module?: string,
  query?: string
) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/videos/submodule/get?module=${module}&page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAdminVideosSubModuleActive = async () => {
  try {
    const get: any = await instance.get(
      `/api/admin/videos/submodule/get/active`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const showAdminVideosSubModule = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/videos/submodule/show/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const editAdminVideosSubModule = async (
  id: string,
  data: AdminVimeoSubModulesTypes
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/videos/submodule/edit/${id}`,
      {
        name: data.name,
        status: data.status,
        restriction: data.restriction,
        fk_module_id: data.fk_module_id,
        fk_submodule_id: data.fk_submodule_id,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const updateStatusAdminVideoSubModule = async (
  id: string,
  status: boolean
) => {
  try {
    const get: any = await instance.put(
      `/api/admin/videos/submodule/status/${id}`,
      {
        status: status,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const deleteAdminVideoSubModule = async (id: string) => {
  try {
    const get: any = await instance.delete(
      `/api/admin/videos/submodule/delete/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const createAdminVideosRelation = async (id: any, data: any) => {
  try {
    const get: any = await instance.post(
      `/api/admin/videos/relation/create/${id}`,
      {
        data: data,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAdminVideosRelation = async (id: any) => {
  try {
    const get: any = await instance.get(`/api/admin/videos/relation/get/${id}`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const createImportVimeoVideoImport = async (
  data: importVimeoVideosTypes
) => {
  try {
    const get: any = await instance.post(
      `/api/admin/videos/vimeo/import-video`,
      {
        fk_mod_id: data.fk_mod_id,
        fk_module_id: data.fk_module_id,
        fk_submodule_id: data.fk_submodule_id,
        fk_sub_module_id: data.fk_sub_module_id,
        fk_course_id: data.fk_course_id,
        videos: data.videos,
        restriction: data.restriction,
        account: data.account,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getSubModuleVideosList = async (
  id: string,
  page?: number,
  query?: string
) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/videos/submodules/videos/list/${id}?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getSubModuleVideosNotVideos = async (
  id: string,
  page?: number,
  query?: string
) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/videos/submodules/videos/add/${id}?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const createAdminSubMOdVideosRelation = async (data: any) => {
  try {
    const get: any = await instance.post(
      `/api/admin/videos/submodules/videos/create`,
      {
        fk_module_id: data.fk_module_id,
        fk_submodule_id: data.fk_submodule_id,
        videos: data.videos,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const deleteAdminSubMOdVideosRelation = async (data: any) => {
  try {
    const get: any = await instance.post(
      `/api/admin/videos/submodules/videos/delete`,
      {
        fk_module_id: data.fk_module_id,
        fk_submodule_id: data.fk_submodule_id,
        videos: data.videos,
      }
    );
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getAllVideoModules = async () => {
  try {
    const get: any = await instance.get(`/api/admin/videos/modules/get-all`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAllVideoModulesSubModules = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/videos/submodules/get-all/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAllVideoRelationByCourse = async (id: string) => {
  try {
    const get: any = await instance.get(
      `/api/admin/videos/relation/course/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAllVideoonlySubmodules = async () => {
  try {
    const get: any = await instance.get(`/api/admin/videos/only-submodules`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const createVideoRelationByCourse = async (data: any) => {
  try {
    const get: any = await instance.post(`/api/admin/videos/relation/create`, {
      rel: data,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getAdminNSubMOdules = async (
  id: string,
  page?: number,
  module?: string,
  query?: string
) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/videos/submodule/nmod/${id}?module=${module}&page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
