import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AdminLoginService,
  updateAdminPasswordService,
} from '../../service/admin/admin.service';
import { setAuthToken } from '../../service/AuthConfig';
import { logout, schoolLogin, studentLogin } from '../../redux/loginSlice';
import Logo from '../../assets/icons/logo.jpg';
import { inputField } from '../../helpers/Classes';
import LoginButton from '../../components/button/LoginButton';
import { updateStudentPasswordService } from '../../service/student/student.service';
import { Helmet } from 'react-helmet-async';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
export default function StudentLoginPasswordUpdate() {
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    Cookies.set('role', 'student');
    document.title = 'Login | LMS';
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (email === undefined) {
      return Swal.fire({
        icon: 'error',
        text: 'password required',
      });
    }
    if (password === undefined) {
      return Swal.fire({
        icon: 'error',
        text: 'confirm password required',
      });
    }

    if (password !== email) {
      return Swal.fire({
        icon: 'error',
        text: 'password does not match',
      });
    }
    let login = await updateStudentPasswordService(email, password);
    if (login.status === 'error') {
      return setLoading(false);
    }
    if (login.status === 'success') {
      dispatch(studentLogin());
      navigate('/student/dashboard');
      setLoading(false);
      return toast.success(login.message);
    } else {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{`Update Password | ${window.location.hostname}`}</title>
      </Helmet>
      <div className="container-login100">
        <div className=" lg:min-w-[25vw]">
          <div className="bg-white rounded-3xl shadow-lg p-5 sm:p-5 md:p-10 lg:p-10 w-full">
            <div className="flex justify-center mb-4">
              <img src={Logo} alt="chahal Academy Logo" />
            </div>
            <p className=" text-2xl font-bold text-center">Update password </p>
            {/* <p className=' font-semibold text-gray-500 mt-5 text-center'>Login to continue..</p> */}
            <form className="my-5" onSubmit={handleSubmit}>
              <div className="flex mb-2 flex-row gap-3 ">
                <div className="flex flex-col mb-3 w-[100%] relative">
                  <label className="text-gray-600 dark:text-gray-400 ml-1 ">
                    New Password <sup className="text-red-600">*</sup>
                  </label>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    required
                    onChange={(e: any) => setEmail(e.target.value)}
                    className={`${inputField} `}
                    placeholder="new password"
                  />
                  <div
                    className=" absolute  text-gray-600 right-5 bottom-4 hover:cursor-pointer "
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash className=" " />}
                  </div>
                </div>
              </div>
              <div className="flex mb-3 flex-row gap-3 ">
                <div className="flex flex-col mb-3 w-[100%] relative">
                  <label className="text-gray-600 dark:text-gray-400 ml-1 ">
                    Confirm Password <sup className="text-red-600">*</sup>
                  </label>
                  <input
                    type={showPassword1 ? 'text' : 'password'}
                    required
                    onChange={(e: any) => setPassword(e.target.value)}
                    className={`${inputField} `}
                    placeholder="confirm password"
                  />
                  <div
                    className=" absolute  text-gray-600 right-5 bottom-4 hover:cursor-pointer "
                    onClick={() => setShowPassword1(!showPassword1)}
                  >
                    {showPassword1 ? <FaEye /> : <FaEyeSlash className=" " />}
                  </div>
                </div>
              </div>
              <div className="flex mb-3 flex-row gap-3 ">
                <div className="flex flex-col mb-3 w-[100%] ">
                  <LoginButton
                    icon={true}
                    title="Update Password"
                    loading={loading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
