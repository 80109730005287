import React, { useState } from 'react';

import {  FaUser, FaWhatsapp } from 'react-icons/fa';

import AdminProfile from '../../pages/admins/profile/AdminProfile';
import AdminSettingWhatsapp from '../../pages/admins/setting/whatsappSetup/AdminSettingWhatsapp';
export default function SettingTopNav() {

  const [active, setActive] = useState<any>(1);

  let topItems: any = [];
  topItems = [
    { id: 1, label: 'Profile', icon: FaUser },
    {
      id: 2,
      label: 'Whatsapp',
      icon: FaWhatsapp,
    },
  ];

  const handleNavClick = (e: any) => {
    setActive(e.id);
  };
  return (
    <>
      <div
        className={`w-full flex bg-gray-300 mb-3 rounded-xl items-center gap-3 `}
      >
        <div
          className={`h-[8vh] sm:h-[10vh] md:h-[12vh] lg:h-[10vh] flex justify-start w-[100%]  items-center `}
        >
          <div className="flex flex-row gap-1 ml-3">
            {topItems.map((e: any, index: any) => (
              <button
                onClick={() => handleNavClick(e)}
                className={`${
                  active === e.id ? ' text-white bg-purple-600 ' : ' text-dark'
                } px-4 py-2 rounded-xl flex gap-1 items-center font-semibold`}
              >
                {e?.icon ? React.createElement(e?.icon, { size: '20' }) : null}
                <p>{e?.label}</p>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-5">
        {active === 1 && <AdminProfile />}
        {active === 2 && <AdminSettingWhatsapp />}
      </div>
    </>
  );
}
