import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PlauginError() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <div className="flex items-center justify-center p-6 bg-gray-100">
      <div className="bg-white shadow-md rounded-lg max-w-md text-center">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Warning</h1>
        <p className="text-gray-700 mb-4">
          An external plugin has been detected, kindly deactivated or
          uninstalled.
        </p>
        <button
          onClick={handleNavigate}
          className="my-2 px-5 py-2 bg-purple-500 text-white rounded hover:bg-purple-700"
        >
          Go Back
        </button>
      </div>
    </div>
  );
}
