import React, { useEffect, useState } from 'react';
import {
  getStudentTestSeriesCoursesModule,
  getStudentVideosCourses,
  getStudentVideosCoursesModule,
} from '../../../service/student/student.service';
import Loaders from '../../../components/loaders/Loaders';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import {
  FaEye,
  FaFile,
  FaFileWord,
  FaFolder,
  FaViadeoSquare,
  FaVideo,
} from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaStopwatch } from 'react-icons/fa6';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { BreadCrumRoutes } from '../../../helpers/helpers';
import DataNotFound from '../../../components/errors/DataNotFound';
import { Helmet } from 'react-helmet-async';

export default function StudentTestSeriesModules() {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>([]);
  const [bradCrumData, setBreadcrumbData] = useState<any>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const fetchVideos = async (id: string) => {
    setPageLoad(true);
    let get: any = await getStudentTestSeriesCoursesModule(id);
    if (get?.status === 'success') {
      setPageLoad(false);
      setFetchData(get?.message);
    }
    setPageLoad(false);
  };

  useEffect(() => {
    fetchVideos(location.pathname.split('/')[4].split('?')[0]);
    let brd: any = params.get('name') !== '' ? params.get('name') : 'Module';
    setBreadcrumbData(
      BreadCrumRoutes(
        brd,
        '/student/test-series/modules/' +
          location.pathname.split('/')[
            location.pathname.split('/').length - 1
          ] +
          '?name=' +
          brd,
        'studentTestSeriesModule',
        false
      )
    );
  }, []);

  return pageLoad ? (
    <Loaders type="spinner" />
  ) : (
    <>
      <Helmet>
        <title>{`Test Series | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadCrumb routes={bradCrumData} />
      <div className="my-2 grid grid-cols-12 gap-3">
        {fetchData?.length > 0 ? (
          fetchData.map((item: any, index: number) => (
            <div
              onClick={() =>
                navigate(
                  `/student/test-series/submodules/${item.id}?name=${item.name}`
                )
              }
              key={index}
              className=" hover:cursor-pointer lg:col-span-3 col-span-12 sm:col-span-12 md:col-span-4   bg-gray-200 rounded-xl"
            >
              <div className="flex flex-col  gap-2 border-l-4 rounded-lg p-3 border-purple-600">
                <p className="text-2xl text-center font-semibold">
                  {item?.name}
                </p>
                <div className=" flex gap-3 px-3 mt-3 justify-between">
                  <p className="flex gap-2  items-center">
                    <FaFile className="text-purple-700" /> {item?.totaltest}
                  </p>
                  <p className="flex gap-2  items-center">
                    <FaFolder className="text-purple-700" /> {item?.totalDir}
                  </p>
                </div>
                <button
                  onClick={() =>
                    navigate(
                      `/student/test-series/submodules/${item.id}?name=${item.name}`
                    )
                  }
                  className="px-4 w-full py-2 bg-purple-700 text-white rounded-lg"
                >
                  Show Test Series
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-12">
            <DataNotFound title="No Test Series Found" />
          </div>
        )}
      </div>
    </>
  );
}
