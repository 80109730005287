import React, { useState } from 'react';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../components/button/CustomButton';
import { useNavigate } from 'react-router-dom';
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid';
import Models from '../../../../components/models/Models';
import VimeoModuleCE from './VimeoModuleCE';
import { Helmet } from 'react-helmet-async';

export default function VimeoModules() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{` Videos Modules  | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title="Video Modules">
        <CustomButton
          onClick={() => navigate(`/admin/videos/submodules`)}
          title="Manage Sub Modules"
          icon={true}
          bg="bg-purple-900"
          iconName="folderTree"
        />
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create New Module"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>

      <div className="my-2">
        <PaginationDataGrid name="AdminVideoModules" />
      </div>
      <Models name="Create New Module" isOpen={open} isClose={setOpen}>
        <VimeoModuleCE type="create" isOpen={setOpen} />
      </Models>
    </>
  );
}
