import React, { useState } from 'react';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import AdminCourseMasterCE from './AdminCourseMasterCE';
import Models from '../../../components/models/Models';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

export default function AdminCourseMaster() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{`Course Menu | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title="Course Menu">
        <CustomButton
          onClick={() => navigate('/admin/courses/master/tab')}
          bg="bg-purple-900"
          title="Course Tab"
          icon={true}
          iconName="star"
        />
        <CustomButton
          onClick={() => navigate('/admin/courses/master/coupon/master')}
          bg="bg-purple-700"
          title="Coupon Master"
          icon={true}
          iconName="bookMark"
        />
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create Course Menu"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <PaginationDataGrid name="AdminCourseMaster" />
      </div>

      <Models name="Create New Course Master" isOpen={open} isClose={setOpen}>
        <AdminCourseMasterCE type="create" open={setOpen} />
      </Models>
    </>
  );
}
