import React from 'react';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import { useLocation, useNavigate } from 'react-router-dom';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';
import { Helmet } from 'react-helmet-async';

export default function AdminVideoList() {
  const location = useLocation();
  let moduleId =
    location.pathname.split('/')[location.pathname.split('/').length - 1];
  let moduleName = new URLSearchParams(location.search);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{`Videos List  | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`${moduleName.get('name')}'s videos`}>
        {/* <CustomButton
          title="Manage Vimeo "
          icon={true}
          onClick={(()=>navigate('/admin/videos/vimeo?id='+moduleId))}
          bg='bg-blue-500'
          iconName="vimeo"
        /> */}
        <CustomButton
          onClick={() => navigate(`/admin/videos/create?sub=${moduleId}`)}
          title="Create New Video"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <PaginationDataGrid name="adminSubModulesVideoList" dataId={moduleId} />
      </div>
    </>
  );
}
