import React, { useState } from 'react';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import { useLocation, useNavigate } from 'react-router-dom';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';
import TableButton from '../../../components/button/TableButton';
import { generateRandomString } from '../../../helpers/commonHelper';
import { CSVDownload } from '../../../helpers/FileDownloader';
import Models from '../../../components/models/Models';
import ExcelUpload from '../../../components/uploads/ExcelUpload';
import {
  getAllCenterStudents,
  getFreeCenterStudents,
  getPaidCenterStudents,
} from '../../../service/admin/adminStudent.service';
import moment from 'moment';
import { toast } from 'react-toastify';
import AdminStudentCE from './AdminStudentCE';
import { Helmet } from 'react-helmet-async';

export default function AdminStudents() {
  const [openUpload, setOpenUpload] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [activetab, setActiveTab] = useState({
    id: 0,
    name: 'All Students',
    title: 'AdminAllStudents',
  });
  const tablist = [
    { id: 0, name: 'All Students', title: 'AdminAllStudents' },
    { id: 1, name: 'Paid Students', title: 'AdminPaidStudents' },
    { id: 2, name: 'Free Students', title: 'AdminFreeStudents' },
  ];

  const handleDownload = async (e: any) => {
    let get: any;
    if (e === 'all') {
      get = await getAllCenterStudents();
    } else if (e === 'paid') {
      get = await getPaidCenterStudents();
    } else if (e === 'free') {
      get = await getFreeCenterStudents();
    }
    if (get?.status === 'success') {
      if (get?.message.length > 0) {
        let newData: any = [];
        get?.message.map((e: any, i: number) => {
          newData.push({
            'S No': i + 1,
            Name: e.name,
            Email: e.email,
            Phone: e.phone,
            DOB: moment(e.dob).format('YYYY-MM-DD'),
            Gender: e?.gender?.name,
          });
        });
        CSVDownload(`${e} students`, newData);
      } else {
        return toast.error('No students found for download');
      }
    }
  };
  const handleFileUpload = async () => {
    setOpenUpload(!openUpload);
  };
  const handleSampleDownload = async () => {
    let data = [];
    for (let i = 0; i <= 1; i++) {
      data.push({
        S_no: i + 1,
        Name: i + 'Sample User',
        Phone: generateRandomString(10, true),
        Email: generateRandomString(3, true) + 'sample@demo.in',
        DOB: '2002-12-12',
        Gender: 'Male',
      });
    }
    CSVDownload('student-sample-download', data);
  };
  return (
    <>
      <Helmet>
        <title>{` Students    | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title="Students">
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create New Student"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <div className="">
          <div className=" ">
            {tablist?.length > 0 ? (
              <>
                <div className="flex flex-row gap-2 items-center justify-between">
                  <div className="flex flex-row gap-4 items-center ">
                    {tablist.map((e: any) => (
                      <>
                        <button
                          onClick={() => setActiveTab(e)}
                          className={`${activetab.id === e.id ? 'hover-purple-gradient text-white' : 'bg-gray-200 dark:bg-secondary'} p-2 font-semibold rounded-md hover:shadow-xl`}
                        >
                          {e.name}
                        </button>
                      </>
                    ))}
                  </div>
                  <div className="gap-2 flex flex-row  flex-wrap justify-end mr-5  ">
                    <TableButton
                      onClick={() => setDownloadOpen(!downloadOpen)}
                      icon={true}
                      title="download"
                      text="Download Students"
                      toottip="Download Excel"
                    />
                    <TableButton
                      onClick={handleFileUpload}
                      text="Upload Students"
                      icon={true}
                      title="upload"
                      toottip="Upload Excel"
                    />
                    <TableButton
                      onClick={handleSampleDownload}
                      text="Download Sample"
                      icon={true}
                      title="excel"
                      toottip="Download Sample Excel"
                    />
                  </div>
                </div>

                {activetab.id === 0 ? (
                  <div className="mt-2">
                    <PaginationDataGrid name="AdminAllStudents" />
                  </div>
                ) : null}
                {activetab.id === 1 ? (
                  <div className="mt-2">
                    <PaginationDataGrid name="AdminPaidStudents" />
                  </div>
                ) : null}
                {activetab.id === 2 ? (
                  <div className="mt-2">
                    <PaginationDataGrid name="AdminFreeStudents" />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
      <Models name="Create New Student " isOpen={open} isClose={setOpen}>
        <AdminStudentCE type="create" isOpen={setOpen} />
      </Models>
      <div className="lg:w-[60vw]">
        <Models
          name="Upload Batch Student"
          isOpen={openUpload}
          isClose={setOpenUpload}
        >
          <ExcelUpload
            name="AdminStudentUpload"
            accept=".csv"
            open={setOpenUpload}
          />
        </Models>
      </div>
      <Models
        name="Download Students"
        isOpen={downloadOpen}
        isClose={setDownloadOpen}
      >
        <div className="flex flex-col my-1 gap-3">
          <TableButton
            onClick={() => handleDownload('all')}
            bg={`border-[#7516c8] hover:bg-[#5c07a7] bg-[#7516c8] text-white`}
            icon={true}
            title="download"
            text="Download All Students"
            toottip="Download All Students"
          />
          <TableButton
            onClick={() => handleDownload('paid')}
            bg={`border-[#650469] hover:bg-[#4d0450] bg-[#650469] text-white`}
            icon={true}
            title="download"
            text="Download Paid Students"
            toottip="Download Paid Students"
          />
          <TableButton
            onClick={() => handleDownload('free')}
            bg={`border-[#141191] hover:bg-[#0f0c73] bg-[#141191] text-white`}
            icon={true}
            title="download"
            text="Download Free Students"
            toottip="Download Free Students"
          />
        </div>
      </Models>
    </>
  );
}
