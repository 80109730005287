import moment from 'moment';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
export const randomNumber = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const statusList = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
];
export const toBoolean = (value: any) => {
  if (value === 'true') {
    return true;
  }
  return false;
};
export const capitalizeString = (str: string) => {
  if (!str) return '';
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
export const toLowerString = (str: string) => {
  return str.replace(/\s+/g, '').toLowerCase();
};

export const SearchURLParams = (key: string) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return params.get(key);
};

export const getpagingNumber = (range: number, start: number, last: number) => {
  const result = [];
  const starts = Math.max(start - range, 1);
  const end = Math.min(start + range, last);

  for (let i = starts; i <= end; i++) {
    result.push(i);
  }

  return result;
};
export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
};
export const containsHTMLTags = (str: any) => {
  const regex = /<\/?[a-z][\s\S]*>/i; // Regex to detect HTML tags
  return regex.test(str);
};

export const BreadCrumRoutes = (
  name: string,
  link: string,
  key: string,
  root: boolean
) => {
  const Cookies = require('js-cookie');
  let getRoutes: any = Cookies.get('routes');
  if (getRoutes) {
    let route: any = JSON.parse(getRoutes);
    if (root === false) {
      let check = route.filter((e: any) => e.key === key);
      if (check.length === 0) {
        route.push({
          name: name,
          link: link,
          key: key,
          root: root,
        });
        console.log(route);
        Cookies.set('routes', JSON.stringify(route));
      } else {
        let checkInder = route.findIndex((item: any) => item.key === key);
        Cookies.set('routes', JSON.stringify(route.slice(0, checkInder + 1)));
      }
    } else {
      let routes: any = [{ name: name, link: link, key: key, root: root }];

      Cookies.set('routes', JSON.stringify(routes));
    }
  } else {
    let routes: any = [{ name: name, link: link, key: key, root: root }];

    Cookies.set('routes', JSON.stringify(routes));
  }

  return JSON.parse(Cookies.get('routes')!);
};

export const DateIntoWeekDays = (dataTime: string) => {
  const now = moment();
  const inputDate = moment(dataTime);
  if (inputDate.isSame(now, 'day')) {
    return `Today at ${inputDate.format('h:mm A')}`;
  }
  // Check if the input date is tomorrow
  else if (inputDate.isSame(now.add(1, 'day'), 'day')) {
    return `Tomorrow at ${inputDate.format('h:mm A')}`;
  }
  // Otherwise, return the full date format
  else {
    return inputDate.format('D MMM YYYY [at] h:mm A');
  }
};

export const CheckTimeStatus = (
  inputTime: string,
  minutes: number,
  currentTime?: string
) => {
  let currentDay = new Date();
  if (currentTime) {
    currentDay = new Date(currentTime);
  }
  let compareDay: any = new Date(inputTime);
  let end = new Date(compareDay.getTime() + minutes * 60000);
  if (currentDay < compareDay) {
    return 2;
  } else if (currentDay >= compareDay && currentDay <= end) {
    return 1;
  } else {
    return 0;
  }
};
export const DOBformat = (date: any) => {
  return moment(date).format('DD MMM YYYY');
};

export const encryptData = (data:any) => {
  const SECRET_KEY = 'KEYTEST-TEST-ACCESSKEY-85269-85672';
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// Decrypt function
export const decryptData = (ciphertext:any) => {
  const SECRET_KEY = 'KEYTEST-TEST-ACCESSKEY-85269-85672';
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};