import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BatchStuSubsciptionTypes } from '../../../types/AdminTypes';
import { getCourseExcludeBatch } from '../../../service/admin/adminBatch.service';
import MultiSearchableSelect from '../../../components/form/MultiSearchableSelect';
import formValidationRules from '../../../helpers/formvalidationRules';
import FormButton from '../../../components/button/FormButton';
import Input from '../../../components/form/Input';
import SearchableSelect from '../../../components/form/SearchableSelect';
import moment from 'moment';
import { copyCourseStudentInstallments, createSubscription, getStudentCourseInstallmentsList, payStudentCourseInstallments } from '../../../service/admin/adminStudent.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TextArea from '../../../components/form/TextArea';
import Models from '../../../components/models/Models';
import AdminCourseEMI from '../courses/AdminCourseEMI';

export default function AdminBatchSubscription(props: {
  data?: any;
  open: any;
}) {
  const [loading, setLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState<any>(null);
  const [instAmount, setInstAmount] = useState<any>(0);
  const [pendingInstAmount, setPendingInstAmount] = useState(0);
  const [courseInstallmentsModelOpen, setCourseInstallmentsModelOpen] = useState(false);
  const [modelData, setModelData] = useState<any>()
  const paymentModes= [
    {label:"CASH",value:"CASH"},
    {label:"UPI",value:"UPI"},
    {label:"CHEQUE",value:"CHEQUE"},
    {label:"CREDIT_CARD",value:"CREDIT_CARD"},
    {label:"DEBIT_CARD",value:"DEBIT_CARD"},
  ]
  // new
  const [activeTab, setActiveTab] = useState(0)
  const [installmetnsList, setInstallmetnsList] = useState([])
  const [installmentsDropDown, setInstallmentsDropDown] = useState<any>([])
  const navigate = useNavigate();
  const fetchBatches = async (batchId: string, stu: string) => {
    const get: any = await getCourseExcludeBatch(batchId, stu);
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        if (item.studentSubscription.length === 0) {
          data.push({
            value: item.id,
            label: item.name,
            amount: item.amount,
            duration: item.duration,
          });
        }
      });
      setCourseList(data);
    }
  };
  const fetchStudentInstallments = async(course:string)=>{
    if(course){
      let get:any = await getStudentCourseInstallmentsList( props.data?.stu,course)
      if(get?.status === "success"){
        setInstallmetnsList(get.message)
        setInstallmentsDropDown([])
        if(get?.message.length > 0){
          get.message.map((item:any,index:number)=>{
            setInstallmentsDropDown((prevState:any) => [...prevState, {label:item.installment_number+" - "+item.installment_amount+"Rs - "+moment(item.expiry_date).format('DD MMM YYYY'),index:index, value:item.id}])
          })
        }
      }
    }
  
  }
  useEffect(() => {
    fetchBatches(props.data.id, props.data?.stu);
  }, []);
  useEffect(()=>{
    if(selectedCourse?.value){
      fetchStudentInstallments(selectedCourse?.value)

    }
  },[selectedCourse])
  const { handleSubmit, control } = useForm<BatchStuSubsciptionTypes>({
    values: {
      fk_course_id: selectedCourse?.value,
      mode:"",
      total_amount: selectedCourse ? selectedCourse.amount : 0,
      exp_date: selectedCourse?.duration
        ? moment().add(selectedCourse?.duration, 'days').format('YYYY-MM-DD')
        : '',
      inst_amount: instAmount,
      pending_amount:
        instAmount === 0
          ? pendingInstAmount
          : selectedCourse?.amount
            ? selectedCourse.amount - instAmount
            : 0,
      fk_student_id: '',
      complete: '',
    },
  });
  const onSubmit: SubmitHandler<BatchStuSubsciptionTypes> = async (
    data: BatchStuSubsciptionTypes
  ) => {
    setLoading(true);
    if(data.inst_amount === 0){
      setLoading(false);
      return toast.error(
        'Instalment amount should not be zero'
      );
    }
    if (selectedCourse?.amount < data.inst_amount) {
      setLoading(false);
      return toast.error(
        'Instalment amount should not be more than course amount'
      );
    }
    data.pending_amount = 0
    data.complete = true;
    data.mode = data.mode.value;
    data.fk_student_id = props.data?.stu;

    const cr: any = await createSubscription(data);
    if (cr?.status === 'success') {
      setLoading(false);
      props.open(false);
      return toast.success(cr.message);
    }
    setLoading(false);
  };
  const handleChea = (data: any) => {
    setSelectedCourse(data);
  };
  const handleCourseInstallments = async()=>{
    let cr:any = await copyCourseStudentInstallments(props.data?.stu,selectedCourse.value)
    console.log(cr)
    if(cr?.status === "success"){
     
      setInstallmetnsList(cr.message)
      return toast.success("course installments created successfully");
    }
  }
  
  const handleStudentCourseInstallments = ()=>{
    setCourseInstallmentsModelOpen(!courseInstallmentsModelOpen)
    setModelData({
      id:selectedCourse?.value,
      amount:selectedCourse?.amount,
      days:`${selectedCourse?.duration} days`,
      stu:props.data?.stu
    })
  }

  const {
    handleSubmit: handleSubmitInstallments,
    control: controlInstallments,
  } = useForm();
  const onSubmitInstallments:any = async (data: any) => {
    setLoading(true);
     let cr:any = await payStudentCourseInstallments(props.data?.stu,data.installments?.value,data.mode?.value,data.remark)
    if(cr?.status === "success"){
      setLoading(false);
      props.open(false);
      return toast.success(cr.message)
    }
    setLoading(false);
  };
  return (
    <>
       <div className='mb-5'>
       <SearchableSelect
              name="fk_course_id"
              control={control}
              options={courseList}
              onFetch={handleChea}
              rules={formValidationRules.required()}
              label="select couse"
              placeholder="Search.."
            />
            <div className="flex items-center justify-start gap-4 my-4">
              <button onClick={(()=>setActiveTab(0))} className={`${activeTab === 0 ? 'bg-purple2 text-white' : 'bg-gray-400'} px-4 py-2 rounded-xl `}>&nbsp;&nbsp;&nbsp;Full&nbsp;&nbsp;&nbsp;</button>
              <button onClick={(()=>{fetchStudentInstallments(selectedCourse?.value);setActiveTab(1)})} className={`${activeTab === 1 ? 'bg-purple2 text-white' : 'bg-gray-400'} px-4 py-2 rounded-xl `}>Installments</button>
            </div>
             {
              activeTab === 0 && 
              <>
              <form onSubmit={handleSubmit(onSubmit)} className="mb-2">
        <div className="grid grid-cols-12 gap-2 place-content-center">
          {/* <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 ">
            <SearchableSelect
              name="fk_course_id"
              control={control}
              options={courseList}
              onFetch={handleChea}
              rules={formValidationRules.required()}
              label="select couse"
              placeholder="Search.."
            />
          </div> */}
          <div className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
            <Input
              name="total_amount"
              readonly={true}
              type="number"
              control={control}
              rules={{
                ...formValidationRules.required(),
              }}
              label="Total Amount"
              defaultValue=""
              placeholder="total amount"
            />
          </div>
          <div className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
            <Input
              name="inst_amount"
              type="number"
              control={control}
              onChange={setInstAmount}
              rules={{
                ...formValidationRules.required(),
              }}
              label="Installment  Amount"
              defaultValue=""
              placeholder="installment  amount"
            />
          </div>
        
        </div>
        <div className="grid grid-cols-12 gap-2 place-content-center">
        
              <input type="hidden" name="pending_amount" value="0" />
          {/* <div className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
            <Input
              name="pending_amount"
              type="number"
              control={control}
              rules={{
                ...formValidationRules.required(),
              }}
              label="Pending Amount"
              defaultValue=""
              placeholder="pending amount"
            />
          </div> */}
            <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 ">
            <Input
              name="exp_date"
              type="date"
              control={control}
              rules={{
                ...formValidationRules.required(),
              }}
              label="Expiry Date"
              defaultValue=""
              placeholder="expiry date"
            />
          </div>
          <div className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
            <SearchableSelect
              name="mode"
              control={control}
              options={paymentModes}
              rules={formValidationRules.required()}
              label="Payment Mode"
              placeholder="Search.."
            />
          </div>
          <div className=" col-span-12">
          <TextArea
              name="remark"
              type="text"
              control={control}
              label="Remarks"
              defaultValue=""
              placeholder="remarks"
            />
          </div>
          <div className="mt-3 w-full col-span-12">
            <FormButton loading={loading} title={'Submit'} />
          </div>
        </div>
      </form>
              </>
             }
             {
              activeTab === 1 ?
                installmetnsList.length > 0 ?
                <>
                   <form onSubmit={handleSubmitInstallments(onSubmitInstallments)} className="mb-2">
        <div className="grid grid-cols-12 gap-2 place-content-center">
          <div className="col-span-12">
            <SearchableSelect
              name="installments"
              control={controlInstallments}
              options={installmentsDropDown}
              // onFetch={handleChea}
              rules={formValidationRules.required()}
              label="select installments"
              placeholder="Search.."
            />
          </div>
        
        </div>
        <div className="grid grid-cols-12 gap-2 place-content-center">
        <div className=" col-span-12 ">
            <SearchableSelect
              name="mode"
              control={controlInstallments}
              options={paymentModes}
              rules={formValidationRules.required()}
              label="Payment Mode"
              placeholder="Search.."
            />
          </div>
          <div className=" col-span-12">
          <TextArea
              name="remark"
              type="text"
              control={controlInstallments}
              label="Remarks"
              defaultValue=""
              placeholder="remarks"
            />
          </div>
           
          <div className="mt-3 w-full col-span-12">
            <FormButton loading={loading} title={'Submit'} />
          </div>
        </div>
      </form>
                </>
                :
               selectedCourse?.value ?
               <div className=' w-full h-[150px] flex-col border border-dark border-dashed rounded-xl flex items-center justify-center'>
                  <p>No Installments Found</p>
                  <p onClick={handleStudentCourseInstallments} className='hover:cursor-pointer my-2 text-blue-700'>Create New Installmetns? for this Student</p> 
                  <p>OR</p>
                  <p onClick={handleCourseInstallments}  className='hover:cursor-pointer my-2 text-blue-700'>Use Course Installments</p>
                </div>
                :
                <div className=' w-full h-[100px] flex-col border border-dark border-dashed rounded-xl flex items-center justify-center'>
                <p className='text-red-500'>Select any course</p>
              
              </div>
                :
                null
             }
       </div>

 <Models name={`Create New Installments `} isOpen={courseInstallmentsModelOpen} isClose={setCourseInstallmentsModelOpen}>
             <AdminCourseEMI data={modelData} title={"studentInstallments"}/>
        </Models>
    </>
  );
}
