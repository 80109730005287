import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createAdminService,
  editAdminService,
  showAdminService,
} from '../../../service/admin/admin.service';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AdminTypes } from '../../../types/AdminTypes';
import { statusList, toBoolean } from '../../../helpers/helpers';
import { setContentUpdate } from '../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import RadioButton from '../../../components/form/RadioButton';
import FormButton from '../../../components/button/FormButton';
import SearchableSelect from '../../../components/form/SearchableSelect';
import { ActiveInstituteCenters } from '../../../service/superAdmin/superAdminCenters.service';

export default function SuperAdmin_adminCE(props: {
  type: string;
  data?: any;
  open: any;
}) {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [centerList, setCenterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState({
    button: 'Create Admin',
  });

  const dispatch = useDispatch();

  const fetchDetails = async (id: string) => {
    const get: any = await showAdminService(id);
    if (get?.status === 'success') {
      setFetchData(get.message);
    }
  };
  const fetchInstituteCenter = async () => {
    const get: any = await ActiveInstituteCenters();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setCenterList(data);
    }
  };
  useEffect(() => {
    setPageLoad(true);
    if (props.type === 'edit') {
      fetchDetails(props.data.id);
      setPageContent({
        button: 'Update Admin',
      });
    }
    fetchInstituteCenter();
    setPageLoad(false);
  }, []);

  const { handleSubmit, control } = useForm<AdminTypes>({
    values: {
      name: fetchData?.name,
      status: `${fetchData?.status}`,
      email: fetchData?.email,
      phone: fetchData?.phone,
      center_id: fetchData?.instituteCenter?.id,
    },
  });
  const onSubmit: SubmitHandler<AdminTypes> = async (data: any) => {
    setLoading(true);
    let status_: any = toBoolean(data.status);
    let formdata: any = {
      name: data.name,
      email: data?.email,
      phone: data?.phone,
      center_id: data.center_id?.value,
      status: status_,
    };
    let fetch: any;
    if (props.type === 'create') {
      fetch = await createAdminService(formdata);
    }
    if (props.type === 'edit') {
      fetch = await editAdminService(fetchData?.id, formdata);
    }
    if (fetch?.status === 'success') {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="my-2">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
          <Input
            name="name"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="Name"
            placeholder="Name"
          />
          <Input
            name="email"
            control={control}
            rules={{
              ...formValidationRules.required(),
            }}
            label="email"
            placeholder="Email"
          />
          <Input
            name="phone"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(10),
              ...formValidationRules.maxLength(10),
            }}
            label="phone"
            placeholder="Phone"
          />
          <SearchableSelect
            name="center_id"
            control={control}
            options={centerList}
            rules={formValidationRules.required()}
            label="Select Center"
            placeholder="Search.."
          />
          <RadioButton
            name="status"
            control={control}
            rules={{ ...formValidationRules.required() }}
            options={statusList}
            label="status"
          />
          <br />
          <FormButton loading={loading} title={pageContent.button} />
        </form>
      </div>
    </>
  );
}
