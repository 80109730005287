import Box from '@mui/material/Box';
import { DataGrid, GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ThemeProvider, Tooltip, createTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaEdit, FaPlus, FaRupeeSign, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import moment from 'moment';
import { getDateStatus } from '../../helpers/commonHelper';
import Models from '../models/Models';
import { DOBformat } from '../../helpers/helpers';
import {
  deleteAdminCourseCategoryTab,
  deleteAdminCourseCategoryTabCourse,
  getAdminCourseCategoryTab,
  getAdminCourseCategoryTabCourse,
  statusAdminCourseCategoryTab,
  statusAdminCourseCategoryTabCourse,
} from '../../service/admin/adminCourses.service';
import TableButton from '../button/TableButton';
import SwitchButton from '../button/SwitchButton';
import CourseCategoryTabCE from '../../pages/admins/courses/courseCategoryTab/CourseCategoryTabCE';
import {
  deleteAdminCouponMasterCourses,
  getAdminCouponMasterCourses,
} from '../../service/admin/adminCoupon.service';
import { studentCourseHoldPayments } from '../../service/student/studentCourses.service';
import { defaultAdminWhatsappCredentials, getAdminWhatsappCredentials, statusAdminWhatsappCredentials } from '../../service/admin/adminSetting.service';
import AdminSettingWhatsappEdit from '../../pages/admins/setting/whatsappSetup/AdminSettingWhatsappEdit';

export default function MUITableGrid(props: {
  name: String;
  id?: any;
  refresh?: any;
  height?: any;
  dataId?: any;
  dataObject?: any;
  onSubmit?: any;
  checkbox?: boolean;
  anyData?: any;
}) {
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const themeSlice = useSelector((state: any) => state.themeSlice);
  const theme: any = createTheme({
    palette: {
      mode: themeSlice ? themeSlice : 'light',
    },
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [columnWidths, setColumnWidths] = useState({});
  const [modelData, setModelDate] = useState<any>([]);
  const [rowLoading, setRowLoading] = useState(false);
  const handleColumnWidthChange = (newColumnWidths: any) => {
    setColumnWidths(newColumnWidths);
  };
  const [loading, setloading] = useState<boolean>(false);
  let columns: any = [];
  let [tableRow, settableRow] = useState(
    props.dataObject ? props.dataObject : []
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [rowid, setRowid] = useState<any>(1);
  const [updateModelData, setUpdateModelData] = useState<any>();
  const [rowCountState, setRowCountState] = useState(totalRow || 0);
  const courseUpdateData = useSelector((state: any) => state.courseUpdateSlice);
  const [filterModel, setFilterModel] = useState<any>({
    items: [],
    quickFilterValues: [],
  });
  const contentUpdate = useSelector((state: any) => state.contentUpdateSlice);
  if (props.name === 'studentsNotCreatedFromExcel') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email ',
        flex: 0.2,
        minWidth: 170,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
      },
    ];
  }
  if (props.name === 'AdminDashBoardRecentStudents') {
    columns = [
      {
        field: 'rowId',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email ',
        flex: 0.2,
        minWidth: 170,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
      },
      {
        field: 'dob',
        headerName: 'DOB',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          return DOBformat(params.row.dob);
        },
      },
      {
        field: '',
        headerName: 'Type',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          return params.row._count?.studentSubscription === 0 ? (
            <button
              disabled={true}
              className="bg-gray-600 text-white p-2 rounded-lg"
            >
              Free
            </button>
          ) : (
            <button
              disabled={true}
              className="bg-green-600 text-white p-2 rounded-lg"
            >
              Paid
            </button>
          );
        },
      },
    ];
  }
  if (props.name === 'AdminDashBoardExpriyStudents') {
    columns = [
      {
        field: 'rowId',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'email',
        headerName: 'Email ',
        flex: 0.2,
        minWidth: 170,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
      },
      {
        field: 'dob',
        headerName: 'DOB',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          return DOBformat(params.row.dob);
        },
      },
      {
        field: '',
        headerName: 'Expiry on',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          return DOBformat(params.row.studentSubscription[0]?.expiry_date);
        },
      },
    ];
  }
  const AdminCourseCategoryTabs = async () => {
    setloading(true);
    let get: any = await getAdminCourseCategoryTab();
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            name: item.name,
            status: item.status,
            image: item?.image,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminCourseCategoryTabs') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.14,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await statusAdminCourseCategoryTab(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          const handleChange = async () => {
            setOpen(!open);
            setModelDate({
              id: params.row.uuid,
              module: props.dataId,
            });
          };
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminCourseCategoryTab(
                  params.row.uuid
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="course"
                icon={true}
                onClick={() =>
                  navigate(
                    `/admin/courses/master/tab/${params.row.uuid}?name=${params.row.name}`
                  )
                }
                toottip="courses"
              />
              <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit sub module"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete sub module"
              />
            </div>
          );
        },
      },
    ];
  }

  const AdminCourseTabCourse = async () => {
    setloading(true);
    let get: any = await getAdminCourseCategoryTabCourse(props.id);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            course: item.course.name,
            courseImg: item.course.image,
            courseid: item.fk_course_id,
            status: item.status,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'AdminCourseTabCourse') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'course',
        headerName: 'Course Name',
        flex: 0.14,
        minWidth: 130,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-2  items-center">
              <img
                src={params.row.courseImg}
                className="w-[50px] h-[50px] rounded-full"
                alt=""
              />
              <p>{params.row.course}</p>
            </div>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await statusAdminCourseCategoryTabCourse(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminCourseCategoryTabCourse(
                  params.row.uuid
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete sub module"
              />
            </div>
          );
        },
      },
    ];
  }
  const adminCouponMaster = async () => {
    setloading(true);
    let get: any = await getAdminCouponMasterCourses(props.id);
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            course: item.course.name,
            courseImg: item.course.image,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'adminCouponMaster') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'course',
        headerName: 'Course Name',
        flex: 0.14,
        minWidth: 130,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-2  items-center">
              <img
                src={params.row.courseImg}
                className="w-[50px] h-[50px] rounded-full"
                alt=""
              />
              <p>{params.row.course}</p>
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminCouponMasterCourses(
                  params.row.uuid
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          return (
            <div className="flex gap-4 flex-row">
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete sub module"
              />
            </div>
          );
        },
      },
    ];
  }
  const studentHoldPayment = async () => {
    setloading(true);
    let get: any = await studentCourseHoldPayments();
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
            course: item.course.name,
            courseImg: item.course.image,
            amount: item?.amount,
            remark: item?.remark,
            createAt: item?.createAt,
            transaction_id: item?.transaction_id,
            adminPaymentMaster: item?.adminPaymentMaster?.name,
            reject: item?.reject,
            comment: item?.comment,
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  };
  if (props.name === 'studentHoldPayment') {
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'course',
        headerName: 'Course',
        flex: 0.18,
        minWidth: 180,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-2  items-center">
              <img
                src={params.row.courseImg}
                className="w-[50px] h-[50px] rounded-full"
                alt=""
              />
              <p>{params.row.course}</p>
            </div>
          );
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 0.08,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-2  items-center">
              <FaRupeeSign />
              <p>{params.row.amount}</p>
            </div>
          );
        },
      },
      {
        field: 'date',
        headerName: 'Date',
        flex: 0.08,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          return moment(params.row.date).format('YYYY-MM-DD');
        },
      },
      {
        field: 'transaction_id',
        headerName: 'Transaction Id',
        flex: 0.13,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'adminPaymentMaster',
        headerName: 'Method',
        flex: 0.08,
        minWidth: 80,
        sortable: false,
      },

      {
        field: 'status',
        headerName: 'Status',
        flex: 0.14,
        minWidth: 130,
        sortable: false,
        renderCell: (params: any) => {
          const handleView = () => {
            setOpen(!open);
            setModelDate(params.row.comment);
          };
          return params.row.reject ? (
            <div className="flex items-center justify-end gap-2">
              <TableButton
                toottip="view"
                title="watch"
                onClick={handleView}
                icon={true}
              />
              <button
                disabled={true}
                type="button"
                className="px-5 py-3 rounded-xl bg-red-600 text-white"
              >
                Rejected
              </button>
            </div>
          ) : (
            <div className="flex justify-end gap-2">
              <button
                disabled={true}
                type="button"
                className="px-5 py-3 rounded-xl bg-purple-600 text-white"
              >
                Pending
              </button>
            </div>
          );
        },
      },
    ];
  }
  const adminWhatsappCredentials = async()=>{
    setloading(true);
    let get: any = await getAdminWhatsappCredentials();
    let dt: any = [];
    if (get?.status == 'success') {
      if (get?.message) {
        get.message?.map((item: any, index: number) => {
          dt.push({
            id: index + rowid,
            uuid: item.id,
           phone:item.phone_number,
           status: item.status,
           default:item.is_default
          });
        });
      }
    }
    setloading(false);
    settableRow(dt);
  }
  if(props.name === "adminWhatsappCredentials"){
    columns = [
      {
        field: 'id',
        headerName: 'S. No',
        flex: 0.01,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'phone',
        headerName: 'Phone',
        flex: 0.18,
        minWidth: 180,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <div className="flex gap-2  items-center">
              <p>{params.row.phone}</p>
            </div>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.status;
          const handleChange = async () => {
            let status: boolean = params.row.status;
            const update: any = await statusAdminWhatsappCredentials(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.status;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, status: classtatus };
                }
                return item;
              });
              settableRow(newData);
              adminWhatsappCredentials()
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: 'default',
        headerName: 'Default',
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: any) => {
          let classtatus: boolean = params.row.default;
          const handleChange = async () => {
            let status: boolean = params.row.default;
            const update: any = await defaultAdminWhatsappCredentials(
              params.row.uuid,
              !status
            );
            if (update.status === 'success') {
              classtatus = !params.row.default;
              const newData: any = tableRow.map((item: any) => {
                if (item.id === params.row.id) {
                  return { ...item, default: classtatus };
                }
                return item;
              });
              settableRow(newData);
              adminWhatsappCredentials()
              return toast.success(update.message);
            }
          };
          return (
            <div className="flex gap-4 flex-row">
              <SwitchButton onchange={handleChange} checked={classtatus} />
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 0.1,
        minWidth: 120,
        sortable: false,
        renderCell: (params: any) => {
          const handleDelete = async () => {
            Swal.fire({
              title: 'Are you sure want to delete ?',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Submit',
              showLoaderOnConfirm: true,
              preConfirm: async (value) => {
                const del: any = await deleteAdminCouponMasterCourses(
                  params.row.uuid
                );
                if (del?.status === 'success') {
                  const newData = tableRow.filter(
                    (item: any) => item.uuid !== params.row.uuid
                  );
                  settableRow(newData);
                  setTotalPages(totalPages - 1);
                  setTotalRow(totalRow - 1);
                  return toast.success(del.message);
                }
              },
            });
          };
          const handleChange = () => {
            setOpen(!open)
            setModelDate(params.row.uuid)
          }
          return (
            <div className="flex gap-4 flex-row">
               <TableButton
                title="edit"
                icon={true}
                onClick={handleChange}
                toottip="edit credentials"
              />
              <TableButton
                title="delete"
                icon={true}
                onClick={handleDelete}
                toottip="delete credentials"
              />
            </div>
          );
        },
      },
    ];
  }
  useEffect(() => {
    if (props?.dataObject) {
      if (props.dataObject?.length > 0) {
        let newData: any = props.dataObject?.map((e: any, index: number) => {
          return { ...e, rowId: index + 1 };
        });
        settableRow(newData);
      }
    }
  }, [props.dataObject]);
  useEffect(() => {
    if (props.name === 'SchoolCalendar') {
      const newData: any = tableRow.map((e: any) => {
        if (e.uuid === courseUpdateData.id) {
          return {
            ...e,
            name: courseUpdateData.param1,
            date: courseUpdateData.param2,
            holiday: courseUpdateData.param3,
          };
        }
        return e;
      });
      setTotalRow(newData);
    }
  }, [courseUpdateData]);
  useEffect(() => {
    if(props.name === "adminWhatsappCredentials"){
      adminWhatsappCredentials()
    }
    if (props.name === 'studentHoldPayment') {
      studentHoldPayment();
    }
    if (props.name === 'adminCouponMaster') {
      adminCouponMaster();
    }
    if (props.name === 'AdminCourseCategoryTabs') {
      AdminCourseCategoryTabs();
    }
    if (props.name === 'AdminCourseTabCourse') {
      AdminCourseTabCourse();
    }
  }, [contentUpdate]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ height: props.height ? props.height : 450, width: 1 }}
          className={` shadow-md rounded-xl p-2 ${localStorage.getItem('theme') === 'dark' ? 'bg-gray-800' : 'bg-gray-200'} `}
        >
          <DataGrid
            sx={{ border: 0, width: '100%' }}
            rows={tableRow}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={(newRowSelectionModel: any) => {
              if (newRowSelectionModel.length > 0) {
                setRowSelectionModel([...newRowSelectionModel]);
              }
            }}
            rowSelectionModel={rowSelectionModel}
            checkboxSelection={props.checkbox ? props.checkbox : false}
            columns={columns}
            loading={loading}
            // onPaginationModelChange={setPaginationModel}

            // Set the total row count
          />
        </Box>
      </ThemeProvider>
      {props.name === 'SchoolCalendar' ? (
        <Models name="Update  Event" isOpen={open} isClose={setOpen}></Models>
      ) : null}
      {props.name === 'AdminCourseCategoryTabs' ? (
        <Models name="Edit Tab" isOpen={open} isClose={setOpen}>
          <CourseCategoryTabCE type="edit" open={setOpen} data={modelData} />
        </Models>
      ) : null}
      {props.name === 'studentHoldPayment' ? (
        <Models name="Comment" isOpen={open} isClose={setOpen}>
          <p className="text-xl font-semibold">{modelData}</p>
        </Models>
      ) : null}
       {props.name === 'adminWhatsappCredentials' ? (
        <Models name="Edit maytapi Credentails" isOpen={open} isClose={setOpen}>
          <AdminSettingWhatsappEdit open={setOpen} data={modelData} />
        </Models>
      ) : null}
    </>
  );
}
