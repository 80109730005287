import { adminExamAlertsTypes, AdminTypes } from '../../types/AdminTypes';
import instance from '../instace';

export const createAdminExamAlert = async (data: adminExamAlertsTypes) => {
  try {
    const get: any = await instance.post('/api/admin/exam-alert/create', {
      name: data.name,
      exam_date: data.examDate,
      status: data.status,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getAdminExamAlert = async (page?: number, query?: string) => {
  try {
    let pg = 1;
    if (page) {
      pg = page;
    }
    const get: any = await instance.get(
      `/api/admin/exam-alert/get?page=${pg}${query ? `&query=${query}` : ''}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
export const showAdminExamAlert = async (id: string) => {
  try {
    const get: any = await instance.get(`/api/admin/exam-alert/show/${id}`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const editAdminExamAlert = async (
  id: string,
  data: adminExamAlertsTypes
) => {
  try {
    const get: any = await instance.put(`/api/admin/exam-alert/edit/${id}`, {
      name: data.name,
      exam_date: data.examDate,
      status: data.status,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const updateStatusAdminExamAlert = async (
  id: string,
  status: boolean
) => {
  try {
    const get: any = await instance.put(`/api/admin/exam-alert/status/${id}`, {
      status: status,
    });
    return get.data;
  } catch (e) {
    return false;
  }
};
export const deleteAdminExamAlert = async (id: string) => {
  try {
    const get: any = await instance.delete(
      `/api/admin/exam-alert/delete/${id}`
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
