import React, { useEffect, useState } from 'react';
import { getStudentLiveClasses } from '../../../service/student/student.service';
import Loaders from '../../../components/loaders/Loaders';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import { FaBook, FaStopwatch } from 'react-icons/fa';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  BreadCrumRoutes,
  CheckTimeStatus,
  DateIntoWeekDays,
} from '../../../helpers/helpers';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import DataNotFound from '../../../components/errors/DataNotFound';
import { Helmet } from 'react-helmet-async';

export default function StudentLiveClass() {
  const [pageLoad, setPageLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [breadCrumData, setBreadCrumData] = useState<any>();
  const navigate = useNavigate();
  const fetchLiveClass = async () => {
    setPageLoad(true);
    const get: any = await getStudentLiveClasses();
    if (get?.status === 'success') {
      setPageLoad(false);
      setFetchData(get?.message);
    }
    setPageLoad(false);
  };
  useEffect(() => {
    fetchLiveClass();
    setBreadCrumData(
      BreadCrumRoutes(
        'Live Class',
        '/student/live-class',
        'studentLiveClass',
        true
      )
    );
  }, []);
  const handleLiveClassJoin = (type: string, url: string) => {
    if (type === 'zoom') {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
    if (type === 'lms') {
      navigate(`/student/live-class/join/${url}`);
    }
  };
  return pageLoad ? (
    <Loaders type="spinner" />
  ) : (
    <>
      <Helmet>
        <title>{`Live Class | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadCrumb routes={breadCrumData} />
      <div className="my-2 grid grid-cols-12 gap-3">
        {fetchData?.length > 0 ? (
          fetchData.map((item: any, index: number) => (
            <div
              key={index}
              className="lg:col-span-4 col-span-12 sm:col-span-12 md:col-span-6  relative  bg-gray-200 rounded-xl"
            >
              <div className="flex flex-col  gap-2 border-l-4 rounded-lg p-3 border-purple-600">
                <h5 className="text-xl mb-3 font-bold">{item.title}</h5>
                {CheckTimeStatus(item?.datetime, item?.duration) === 0 && (
                  <p className="bg-gray-700 text-white py-1 px-2  rounded-lg absolute  right-2 top-2">
                    Expired
                  </p>
                )}
                {CheckTimeStatus(item?.datetime, item?.duration) === 1 && (
                  <p className="bg-purple-700 text-white py-1 px-2  rounded-lg absolute  right-2 top-2">
                    In Progress
                  </p>
                )}

                <div className="flex  flex-col items-center justify-start">
                  <p className="flex gap-2  items-center text-left rounded-lg  font-bold w-full text-gray-800 py-1">
                    <FaBook />{' '}
                    {
                      item?.liveClassSubmoduleRelation[0]?.liveClassSubmodule
                        ?.liveClassCourseRelation[0]?.course?.name
                    }
                  </p>
                  <p className="flex gap-2  items-center text-left rounded-lg  font-bold w-full text-gray-800 py-1">
                    <FaStopwatch /> {DateIntoWeekDays(item?.datetime)}
                  </p>
                  {CheckTimeStatus(item?.datetime, item?.duration) === 1 ? (
                    <button
                      onClick={() =>
                        handleLiveClassJoin(
                          item?.url ? 'zoom' : 'lms',
                          item?.url ? item?.url : item?.meeting_number
                        )
                      }
                      className="bg-gradient w-full text-white py-2 mt-4 px-4 rounded-lg "
                    >
                      Join Class
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="bg-gray-700 w-full text-white py-2 mt-4 px-4 rounded-lg "
                    >
                      Join Class
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-12">
            <DataNotFound title={'No Live CLass Found'} />
          </div>
        )}
      </div>
    </>
  );
}
