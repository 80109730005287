import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CoursesType } from '../../../types/AdminTypes';
import {
  SearchURLParams,
  statusList,
  toBoolean,
} from '../../../helpers/helpers';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';

import DropZoneUploadImages from '../../../components/dropzone/DropZoneUploadImages';
import SearchableSelect from '../../../components/form/SearchableSelect';
import {
  createAdminCourse,
  editAdminCourse,
  getActiveCourseCategory,
  showAdminCourse,
} from '../../../service/admin/adminCourses.service';
import { getActiveBatchs } from '../../../service/admin/adminBatch.service';
import FormButton from '../../../components/button/FormButton';
import Swal from 'sweetalert2';
import MultiSearchableSelect from '../../../components/form/MultiSearchableSelect';
import CKEditors from '../../../editor/CKEditors';
import { Helmet } from 'react-helmet-async';
export default function AdminCourseCE() {
  const location = useLocation();
  const navigate = useNavigate();
  const courseid: any = SearchURLParams('id');
  let locLength = location.pathname.split('/').length;
  let pageType: any = location.pathname.split('/')[locLength - 1];
  let courseMasterSlug: string = location.pathname.split('/')[locLength - 2];
  const [pageLoad, setPageLoad] = useState(false);
  const [genderData, setGenderData] = useState([]);
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<any>();
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] =
    useState<any>(false);
  const [initalEditor, setInitalEditor] = useState<any>(undefined);
  const [selectedbatch, setSelectedbatch] = useState<any>([]);
  const [courseCategoryList, setCourseCategoryList] = useState<any>([]);
  const [batchList, setBatchList] = useState([]);
  const [pageContent, setPageContent] = useState({
    button: 'Create New Course',
    title: 'Create Course',
  });
  const fetchCourseData = async (id: string) => {
    const get: any = await showAdminCourse(id);
    if (get?.status === 'success') {
      setFetchData(get.message);
      setUrl(get.message?.image);
      setInitalEditor(get.message?.description);
      if (get?.message?.batchCourses.length > 0) {
        let data: any = [];
        get.message?.batchCourses.map((item: any) => {
          data.push(item.fk_batch_id);
        });
        setSelectedbatch(data);
      }
    }
  };
  const fetchAciveBatch = async () => {
    const get: any = await getActiveBatchs();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setBatchList(data);
    }
  };
  const fetchCourseCategory = async (courseMasterId: string) => {
    const get: any = await getActiveCourseCategory(courseMasterId);
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({
          value: item.id,
          label: item.name,
          uuid: item.fk_courseMaster_id,
        });
      });
      setCourseCategoryList(data);
    }
  };
  useEffect(() => {
    if (pageType === 'edit') {
      fetchCourseData(courseid);
      setPageContent({
        button: 'Update Course',
        title: 'Update Course',
      });
    }
    fetchCourseCategory(courseMasterSlug);
    fetchAciveBatch();
  }, []);
  const handleEditor = (output: any) => {
    setInitalEditor(output);
  };
  const { handleSubmit, control } = useForm<CoursesType>({
    values: {
      name: fetchData?.name,
      on_web: `${fetchData?.on_web === true ? `${fetchData?.on_web}` : fetchData?.on_web === false ? fetchData?.on_web : null}`,
      status: `${fetchData?.status === true ? `${fetchData?.status}` : fetchData?.status === false ? `${fetchData?.status}` : null}`,
      fk_courseCategory_id: fetchData?.fk_courseCategory_id,
      fk_courseMaster_id: fetchData?.fk_courseMaster_id,
      fk_batch_id: selectedbatch,
      duration: fetchData?.duration,
      amount: fetchData?.amount,
      description: fetchData?.description,
      image: fetchData?.image,
      order: fetchData?.fk_courseCategory_id,
      popular: `${fetchData?.popular === true ? `${fetchData?.popular}` : fetchData?.popular === false ? fetchData?.popular : null}`,
      required_login: `${fetchData?.required_login === true ? `${fetchData?.required_login}` : fetchData?.required_login === false ? fetchData?.required_login : null}`,
      on_app: `${fetchData?.on_app === true ? `${fetchData?.on_app}` : fetchData?.on_app === false ? fetchData?.on_app : null}`,
    },
  });

  const onSubmit: SubmitHandler<CoursesType> = async (data: any) => {
    console.log('--', initalEditor);
    if (
      initalEditor === undefined ||
      initalEditor === null ||
      initalEditor === ''
    ) {
      return toast.error('description required');
    }
    setLoading(true);
    console.log(data);
    // return false;
    let status_: any = data.status?.value
      ? toBoolean(data.status?.value)
      : fetchData?.status;
    let fetch: any;
    data.status = status_;
    data.description = initalEditor;
    data.on_app = data.on_app?.value
      ? toBoolean(data.on_app?.value)
      : fetchData?.on_app;
    data.on_web = data.on_web?.value
      ? toBoolean(data.on_web?.value)
      : fetchData?.on_web;
    data.popular = false;
    data.required_login = data.required_login?.value
      ? toBoolean(data.required_login?.value)
      : fetchData?.required_login;
    data.image = url;
    data.fk_courseMaster_id = courseCategoryList[0].uuid;
    data.amount = data.amount ? data.amount : 0;
    data.fk_courseCategory_id = data.fk_courseCategory_id
      ? data.fk_courseCategory_id.value
      : fetchData?.fk_courseCategory_id;
    data.order = 0;
    if (pageType === 'create') {
      fetch = await createAdminCourse(data);
    }
    if (pageType === 'edit') {
      fetch = await editAdminCourse(courseid, data);
    }
    if (fetch?.status === 'success') {
      setLoading(false);
      navigate(`/admin/courses/master/${courseMasterSlug}`);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{`${pageType} Course Master  | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`${pageContent.title}`}></BreadcrumbBack>
      <div className="my-2">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
          <Input
            name="name"
            control={control}
            rules={{
              ...formValidationRules.required(),
              ...formValidationRules.minLength(3),
            }}
            label="Name"
            placeholder="Name"
          />
          <div className="grid grid-cols-12 gap-2 ">
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="duration"
                type="number"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Duration(Days)"
                placeholder="duration"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6">
              <Input
                name="amount"
                type="number"
                rules={{
                  ...formValidationRules.min(0),
                }}
                control={control}
                label="Amount"
                placeholder="amount"
              />
            </div>
          </div>

          <div className="my-2">
            <div className=" grid grid-cols-12 gap-2">
              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
                <SearchableSelect
                  name="fk_courseCategory_id"
                  control={control}
                  options={courseCategoryList}
                  label="Select course submenu"
                  placeholder="Search.."
                />
              </div>
              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
                <MultiSearchableSelect
                  name="fk_batch_id"
                  control={control}
                  options={batchList}
                  label="Select Batch"
                  placeholder="Search.."
                  defaultValue={selectedbatch}
                />
              </div>

              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3">
                <SearchableSelect
                  name="required_login"
                  control={control}
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  rules={formValidationRules.required()}
                  label="Login Required"
                  placeholder="select"
                />
              </div>

              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3">
                <SearchableSelect
                  name="on_app"
                  control={control}
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  rules={formValidationRules.required()}
                  label="On App"
                  placeholder="Select"
                />
              </div>
              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3">
                <SearchableSelect
                  name="on_web"
                  control={control}
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  rules={formValidationRules.required()}
                  label="On Web"
                  placeholder="Select"
                />
              </div>
              <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3">
                <SearchableSelect
                  name="status"
                  control={control}
                  options={[
                    { label: 'Active', value: 'true' },
                    { label: 'Inactive', value: 'false' },
                  ]}
                  rules={formValidationRules.required()}
                  label="Status"
                  placeholder="select"
                />
              </div>
            </div>
          </div>
          <div className="my-2">
            <DropZoneUploadImages
              heigth="min-h-[25vh]"
              title="Upload  Image"
              isRequired={true}
              maxFile={1}
              data={setUrl}
              url={url}
              status={setCheckUploadedFilesStatus}
            />
          </div>
          <CKEditors
            title="Description"
            isRequired={true}
            input={initalEditor}
            output={handleEditor}
          />
          {/* <JoditEditors input={initalEditor} output={handleEditor} placeholder={"description"} /> */}
          <div className="my-3">
            <FormButton loading={loading} title={pageContent.button} />
          </div>
        </form>
      </div>
    </>
  );
}
