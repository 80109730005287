import React, { useState } from 'react';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../components/button/CustomButton';
import { useNavigate } from 'react-router-dom';
import PaginationDataGrid from '../../../../components/tables/PaginationDataGrid';
import Models from '../../../../components/models/Models';
import VideoSubModuleCE from './VideoSubModuleCE';
import { Helmet } from 'react-helmet-async';

export default function VideoSubModules() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{` Videos   | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title="Video Sub Modules">
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create New SubModule"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>

      <div className="my-2">
        <PaginationDataGrid name="AdminVideoSubModules" />
      </div>
      <Models name="Create New SubModule" isOpen={open} isClose={setOpen}>
        <VideoSubModuleCE type="create" isOpen={setOpen} />
      </Models>
    </>
  );
}
