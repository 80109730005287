import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AdminLoginService,
  updateAdminPasswordService,
} from '../../service/admin/admin.service';
import { setAuthToken } from '../../service/AuthConfig';
import { logout, schoolLogin } from '../../redux/loginSlice';
import Logo from '../../assets/icons/logo.jpg';
import { inputField } from '../../helpers/Classes';
import LoginButton from '../../components/button/LoginButton';
import formValidationRules from '../../helpers/formvalidationRules';
import Input from '../../components/form/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toBoolean } from '../../helpers/helpers';
import SearchableSelect from '../../components/form/SearchableSelect';
import { StudentTypes } from '../../types/StudentTypes';
import {
  getActiveCenters,
  getActiveGendersService,
} from '../../service/commonService';
import { RegisterStudents } from '../../service/student/student.service';
import { Helmet } from 'react-helmet-async';
export default function StudentRegister() {
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [genderData, setGenderData] = useState([]);
  const [centersData, setCentersData] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const fetchGenders = async () => {
    let get: any = await getActiveGendersService();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setGenderData(data);
    }
  };
  const fetchCenters = async () => {
    let get: any = await getActiveCenters();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setCentersData(data);
    }
  };
  useEffect(() => {
    fetchCenters();
    fetchGenders();
    Cookies.set('role', 'admin');
    document.title = 'Login | LMS';
  }, []);

  const { handleSubmit, control } = useForm<StudentTypes>();

  const onSubmit: SubmitHandler<StudentTypes> = async (data: StudentTypes) => {
    setLoading(true);
    let fetch: any;
    data.gender_id = data.gender_id.value;
    data.center_id = data.center_id.value;
    fetch = await RegisterStudents(data);
    //    if(props.type==="create"){
    //     fetch = await createAdminStudent(data);
    //    }
    //    if(props.type === "edit"){
    //     fetch = await editAdminStudent(props.data.id,data);
    //    }
    if (fetch?.status === 'success') {
      setLoading(false);
      navigate('/');
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{`Registration | ${window.location.hostname}`}</title>
      </Helmet>
      <div className="container-login100">
        <div className=" lg:min-w-[25vw]">
          <div className="bg-white rounded-3xl shadow-lg p-5 sm:p-5 md:p-10 lg:p-10 w-full">
            <div className="flex justify-center mb-4">
              <img src={Logo} alt="chahal Academy Logo" />
            </div>
            <p className=" text-2xl font-bold text-center">
              Student Registration{' '}
            </p>
            {/* <p className=' font-semibold text-gray-500 mt-5 text-center'>Login to continue..</p> */}
            <form className="my-5" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-12">
                  <Input
                    name="name"
                    control={control}
                    rules={{
                      ...formValidationRules.required(),
                      ...formValidationRules.minLength(3),
                    }}
                    label="Name"
                    placeholder="Name"
                  />
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-6">
                  <Input
                    name="email"
                    type="email"
                    control={control}
                    rules={{
                      ...formValidationRules.required(),
                    }}
                    label="Email"
                    placeholder="email"
                  />
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-6">
                  <Input
                    name="phone"
                    control={control}
                    rules={{
                      ...formValidationRules.required(),
                    }}
                    label="Phone"
                    placeholder="phone"
                  />
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-6">
                  <Input
                    name="dob"
                    type="date"
                    control={control}
                    rules={{
                      ...formValidationRules.required(),
                    }}
                    label="Date of birth"
                    placeholder="Name"
                  />
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-6">
                  <SearchableSelect
                    name="gender_id"
                    control={control}
                    options={genderData}
                    rules={formValidationRules.required()}
                    label="Select gender"
                    placeholder="select"
                  />
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-12">
                  <SearchableSelect
                    name="center_id"
                    control={control}
                    options={centersData}
                    rules={formValidationRules.required()}
                    label="Select Center"
                    placeholder="select"
                  />
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-6">
                  <Input
                    name="password"
                    type="password"
                    control={control}
                    rules={{
                      ...formValidationRules.required(),
                    }}
                    label="Password"
                    placeholder="password"
                  />
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-6">
                  <Input
                    name="confirmPassword"
                    type="password"
                    control={control}
                    rules={{
                      ...formValidationRules.required(),
                    }}
                    label="Confirm Password"
                    placeholder="confirm password"
                  />
                </div>
              </div>
              <div className="flex mb-3 flex-row gap-3 ">
                <div className="flex flex-col mb-3 w-[100%] ">
                  <LoginButton icon={true} title="Submit" loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
