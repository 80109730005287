import { createSlice } from '@reduxjs/toolkit';

let initialState: boolean = true;

const commonSlice: any = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    isOpenSideBar: (state: any, action: any) => {
      return action.payload;
    },
    iscourseUpdate: (state: any, action: any) => {
      return action.payload;
    },
  },
});

export const { isOpenSideBar, iscourseUpdate } = commonSlice.actions;
export default commonSlice.reducer;
