import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const endPoint1 = "https://oneserver.chahalacademy.com"
export const mediaEndPoint = 'https://onecontent.chahalacademy.com';
// export const mediaEndPoint = "http://127.0.0.1:8000"
// export const endPoint1 = 'http://127.0.0.1:8001';
export const fileUploadCard = "api/image/upload/card";
export const fileUploadBannerDesktop = "api/image/upload/banner-original";
const instance = axios.create({
  baseURL: endPoint1,
});
const token = Cookies.get('token');
instance.defaults.headers.common['Authorization'] = token;
// instance.defaults.headers.common["Accept"] = "application/json";
// instance.defaults.headers.common["Content-Type"] = "multipart/form-data";

instance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    // console.log(error.response.data.message);
    // If the error status is 401, redirect the user to the login page
    if (error.response.status === 401) {
      Cookies.remove('token');
      Cookies.remove('profile');
      Cookies.remove('user');
      Cookies.remove('login');

      toast.error(error.response.data.message);
    }
    if (error.response.status === 403) {
      // window.location.href = '/?message=' + error.response.data.message;
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    if (error.response.status === 404) {
      // window.location.href = '/?message=' + error.response.data.message;
      console.log(error.response.data.message);
      toast.error('No File/Data Found');
    }
    if (error.response.status === 508) {
      // window.location.href = '/?message=' + error.response.data.message;
      console.log(error.response.data.message);
      // toast.error("No File/Data Found")
    }
    if (error.response.status === 422) {
      console.log(error.response.data.message);
      if (typeof error.response.data.message === 'object') {
        Object.values(error.response.data.message).map(
          (errorMessages: any, index: any) => {
            errorMessages.map((errorMessage: any, innerIndex: any) => {
              toast.error(errorMessage);
            });
          }
        );
      } else {
        toast.error(error.response.data.message);
      }
      return false;
    }
    if (error.response.status === 500) {
      // window.location.href = '/?message=' + error.response.data.message;
      toast.error(error.response.data.message);
    }
    if (error.response.status === 400) {
      // window.location.href = '/?message=' + error.response.data.message;
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    if (error.response.status === 409) {
      window.location.href = '/error/already-login';
      toast.error(error.response.data.message);
    }
    if (error.response.status === 423) {
      if (Array.isArray(error.response.data.message)) {
        console.log('The response is an array!');
      } else {
        console.log('The response is not array!');
      }
      // window.location.href = '/?message=' + error.response.data.message;
      // alert(typeof(error.response.data.message))
      // toast.error(error.response.data.message)
    }
    // Return the error
  }
);

export default instance;
