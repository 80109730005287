import React, { useEffect, useState } from 'react';
import { SuperAdminAllCenters } from '../../../service/superAdmin/superAdminCenters.service';
import { toast } from 'react-toastify';
import BreadcrumbBack from '../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../components/button/CustomButton';
import { useNavigate } from 'react-router-dom';
import PaginationDataGrid from '../../../components/tables/PaginationDataGrid';
import Models from '../../../components/models/Models';
import SuperAdmin_adminCE from './SuperAdmin_adminCE';

export default function SuperAdmin_admin() {
  const [centerList, setCenterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const fetchCenters = async () => {
    setLoading(true);
    const get: any = await SuperAdminAllCenters();
    if (get?.status === 'error') {
      setLoading(false);
      return toast.error(get?.message);
    }
    setLoading(false);
    setCenterList(get?.message);
  };
  useEffect(() => {
    fetchCenters();
  }, []);
  return (
    <>
      <BreadcrumbBack title="Institute Center's Admins">
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create Admin"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <PaginationDataGrid name="superAdmin_admins" />
      </div>
      <Models name="Create New Admin" isOpen={open} isClose={setOpen}>
        <SuperAdmin_adminCE type="create" open={setOpen} />
      </Models>
    </>
  );
}
