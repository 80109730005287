import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

export default function Loaders(props: { type: string }) {
  const themeSlice = useSelector((state: any) => state.themeSlice);
  const theme: any = createTheme({
    palette: {
      mode: themeSlice ? themeSlice : 'light',
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div className="grid place-content-center h-[500px]   z-20  w-full">
        <CircularProgress color="inherit" />
      </div>
    </ThemeProvider>
  );
}
