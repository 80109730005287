// Input.tsx
import React from 'react';
import { Control, useController } from 'react-hook-form';
import {
  inputError,
  inputField,
  inputFieldReadOnly,
} from '../../helpers/Classes';

interface InputProps {
  name: string;
  control: Control<any>;
  rules?: any;
  defaultValue?: string;
  placeholder?: string;
  type?: string;
  label: string;
  onChange?: any;
  onBlur?: any;
  readonly?: boolean;
  rows?:any;
}

const TextArea: React.FC<InputProps> = ({
  name,
  control,
  rules,
  defaultValue,
  rows,
  label,
  readonly = false,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleChange = (e: any) => {
    field.onChange(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };
  const isRequired = rules && rules?.required;
  return (
    <div className="flex flex-col mb-3 ">
      <label
        htmlFor={name}
        className={`${error ? 'text-red-500' : 'text-gray-600 dark:text-gray-400'} ml-1`}
      >
        {label} {isRequired ? <sup className="text-red-500">*</sup> : null}
      </label>
      <textarea
        rows={rows || 4}
        className={`${readonly ? inputFieldReadOnly : inputField} ${error ? inputError : null} w-full  `}
        id={name}
        {...field}
        readOnly={readonly}
        {...props}
        onChange={handleChange}
      />
      
      {error && <small className="text-red-500 ml-2">{error.message}</small>}
    </div>
  );
};

export default TextArea;
