import React from 'react';
import { Helmet } from 'react-helmet-async';
import MUITableGrid from '../../../../components/tables/MUITableGrid';
import { FaPlus } from 'react-icons/fa';
import Models from '../../../../components/models/Models';
import AdminSettingWhatsappCreate from './AdminSettingWhatsappCreate';

export default function AdminSettingWhatsapp() {
  const [open, setOpen] = React.useState(false);
  return (
    <>
     <Helmet>
            <title>{` Whatsapp credentials  | ${window.location.hostname}`}</title>
     </Helmet>
     <div className="my-5">
      <div className="my-3 px-4 flex gap-3 items-center justify-between">
        <div>
          <p className=' text-lg font-semibold'>All Whatsapp Credentials</p>
        </div>
        <div>
          <button onClick={(()=>setOpen(!open))} className=' bg-purple-600 text-white px-4 py-2 rounded-lg flex items-center gap-1'><FaPlus/> Create New Credentials</button>
        </div>
      </div>
       <MUITableGrid name="adminWhatsappCredentials" />
     </div>

      <Models name="Create maytapi Credentails" isOpen={open} isClose={setOpen}>
             <AdminSettingWhatsappCreate open={setOpen} />
           </Models>
    </>
  )
}
