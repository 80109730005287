import React, { useState, useEffect } from 'react';
import { FaStopwatch } from 'react-icons/fa';

interface CountdownTimerProps {
  startTime: number; // Start time in seconds
  interval: number; // Interval in milliseconds
}

export default function CountDownTimer(props: {
  startTime: number;
  interval: number;
  remaining?: any;
}) {
  const [timeLeft, setTimeLeft] = useState(props.startTime);
  const [startTimestamp, setStartTimestamp] = useState<number>(Date.now());
  useEffect(() => {
    const updateTimeLeft = () => {
      const elapsedTime = (Date.now() - startTimestamp) / 1000; // Time passed in seconds
      setTimeLeft(Math.max(props.startTime - elapsedTime, 0));
    };

    const timer = setInterval(updateTimeLeft, props.interval);

    // Update the remaining time if a function is passed as a prop
    if (props.remaining) {
      props.remaining(timeLeft);
    }

    return () => {
      clearInterval(timer);
    };
  }, [startTimestamp, props.startTime, props.interval, props.remaining]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return (
    <div className="bg-dark py-2 px-3 flex items-center  rounded-md gap-4">
      <FaStopwatch className="text-white  text-2xl" />
      <p className="text-white font-bold font-mono text-4xl ">
        {' '}
        {formatTime(timeLeft)}
      </p>
    </div>
  );
}
