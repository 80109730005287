import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

let login: any = Cookies?.get('login') ? Cookies?.get('login') : 'null';
const key = JSON.parse(login);
export const encryptCryptoData = (data: any) => {
  return CryptoJS.AES.encrypt(data, key.email).toString();
};
export const decryptCryptoData = (cipherText: any) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, key.email);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const encryptUUID = (uuid: string) => {
  let base64 = btoa(uuid);
  return base64;
};

// Function to decrypt an encrypted UUID
const decryptUUID = (encryptedUUID: string | any) => {
  try {
    const originalUUID = atob(encryptedUUID);
    return originalUUID;
  } catch (error) {
    console.error('Error decrypting UUID:', error);
    return null;
  }
};
export const generateUniqueId = () => {
  return `id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};
export { encryptUUID, decryptUUID };

