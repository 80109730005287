import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStudentCourseMaster } from '../../../service/student/studentCourses.service';
import { BreadCrumRoutes, truncateString } from '../../../helpers/helpers';
import Loaders from '../../../components/loaders/Loaders';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { Helmet } from 'react-helmet-async';

export default function StuCourseMaster() {
  const [courseMaster, setCouseMaster] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);
  const [breadCrumRoutes, setBreadCrumRoutes] = useState<any>();
  const navigate = useNavigate();

  const fetchCourseMaster = async () => {
    setPageLoad(true);
    let get: any = await getStudentCourseMaster();
    if (get?.status === 'success') {
      setCouseMaster(get?.message);
      setPageLoad(false);
    }
    setPageLoad(false);
  };
  useEffect(() => {
    fetchCourseMaster();
    setBreadCrumRoutes(
      BreadCrumRoutes('Courses', '/student/courses', 'studentcourses', true)
    );
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>{`Courses | ${window.location.hostname}`}</title>
      </Helmet>
      {pageLoad ? (
        <Loaders type="spinner" />
      ) : courseMaster.length > 0 ? (
        <>
          <BreadCrumb routes={breadCrumRoutes} />
          <div className="grid grid-cols-12 gap-3">
            {courseMaster.map((e: any, index: number) => (
              <div
                key={index}
                className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3"
              >
                <button
                  data-tooltip={e.name}
                  data-tooltip-location="bottom"
                  onClick={() => navigate(`/student/courses/${e.slug}`)}
                  className=" rounded-2xl bg-gray-200 hover:border-gray-300 border-gray-200 border-2 hover:shadow-lg hover:border-2 hover:cursor-pointer"
                >
                  <img src={e.image} className=" rounded-2xl  w-full" alt="" />
                  <div className=" text-left p-3 ">
                    <p className=" font-semibold text-xl mb-2">
                      {truncateString(e.name, 15)}
                    </p>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}
