import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from '../../components/form/Input';
import { FaEdit } from 'react-icons/fa';
import { StudentProPasswordUpdate } from '../../service/student/student.service';
import { toast } from 'react-toastify';

export default function StudentProfilePasswordUpdate(props: { open: any }) {
  const [loading, setLoading] = useState(false);

  const { control, reset, handleSubmit } = useForm<any>();
  const onSubmit: SubmitHandler<any> = async (data: any) => {
    setLoading(true);
    if (data.npassword !== data.cpassword) {
      setLoading(false);
      return toast.error('Passwords do not match');
    }
    let update: any = await StudentProPasswordUpdate(
      data.password,
      data.npassword,
      data.cpassword
    );
    if (update?.status === 'success') {
      setLoading(false);
      props.open(false);
      return toast.success(update?.message);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="password"
        type="password"
        control={control}
        label="current password"
        placeholder="current password"
      />
      <Input
        name="npassword"
        type="password"
        control={control}
        label="New Password"
        placeholder="New Password"
      />
      <Input
        name="cpassword"
        type="password"
        control={control}
        label="Confirm Password"
        placeholder="Confirm Password"
      />
      <div className="w-full">
        <button
          disabled={loading}
          type="submit"
          className="flex items-center w-full text-center justify-center bg-purple-700 gap-2 text-white rounded-xl px-3 py-2"
        >
          <FaEdit /> {loading ? 'updating...' : 'Update'}
        </button>
      </div>
    </form>
  );
}
