import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import MultiSearchableSelect from '../../../../components/form/MultiSearchableSelect';
import FormButton from '../../../../components/button/FormButton';
import SearchableSelect from '../../../../components/form/SearchableSelect';
import {
  createImportVimeoVideoImport,
  getAdminVideosSubModuleActive,
  getVideosActiveModule,
  getVideosActiveSubModuleByModule,
} from '../../../../service/admin/adminVideoes.service';
import { getAllCourseActive } from '../../../../service/admin/adminCourses.service';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';

export default function AdminVimeoImport(props: { data?: any; isOpen: any }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState<any>('module');
  const [moduleData, setModuleData] = useState<any>([]);
  const [subModuleData, setSubModuleData] = useState<any>([]);
  const [moduleSubData, setModuleSubData] = useState<any>([]);
  const { handleSubmit, control } = useForm<any>();
  const onSubmit: SubmitHandler<any> = async (data: any) => {
    //   setLoading(true);
    let fetch: any;
    let datam: any = [];
    if (data?.fk_submodule_id?.length > 0) {
      data.fk_submodule_id.map((item: any) => {
        let find: any = moduleSubData.find((i: any) => i.value === item);
        datam.push({
          m: find.module,
          s: find.value,
        });
      });
    }
    // setLoading(false)
    // return false;
    let datas: any = {
      fk_mod_id: 'module',
      fk_module_id: datam,
      videos: props.data,
      restriction: data.restriction,
      account: JSON.parse(localStorage.getItem(`vimoeAccount`)!)?.id,
    };
    fetch = await createImportVimeoVideoImport(datas);
    if (fetch?.status === 'success') {
      setLoading(false);
      props.isOpen(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };
  const fetchModules = async () => {
    const get: any = await getVideosActiveModule();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setModuleData(data);
      //   fetchSubModules();
    }
  };
  const fetchSubModules = async (id: string) => {
    const get: any = await getVideosActiveSubModuleByModule(id);
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({
          value: item.id,
          label: item.name,
          module: item.fk_videoModule_id,
        });
      });
      let newarray = [...moduleSubData, ...data];
      setModuleSubData(newarray);
    }
  };
  const handlefetch = async (data: any) => {
    if (data?.selected.length > 0) {
      if (data?.removed === undefined) {
        fetchSubModules(data?.selected[data?.selected.length - 1]);
      } else {
        let updated = moduleSubData.filter(
          (item: any) => item.module !== data?.removed
        );
        setModuleSubData(updated);
      }
    } else {
      setModuleSubData([]);
    }
  };
  useEffect(() => {
    fetchModules();
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        {/* restriction */}
        {show === 'module' ? (
          <>
            <MultiSearchableSelect
              name="fk_module_id"
              control={control}
              options={moduleData}
              onFetch={handlefetch}
              label="Select module"
            />
            <MultiSearchableSelect
              name="fk_submodule_id"
              control={control}
              options={moduleSubData}
              label="Select sub module"
            />
            <Input
              name="restriction"
              control={control}
              type="number"
              rules={{
                ...formValidationRules.required(),
              }}
              label="Watch Limit"
              placeholder="Watch Limit"
            />
          </>
        ) : null}
        <div className="mt-10">
          <FormButton loading={loading} title={'submit'} />
        </div>
      </form>
    </>
  );
}
