import React, { useEffect, useState } from 'react';
import { studentInstallmentsmakePaymentStatus, studentmakePaymentStatus } from '../../service/student/studentCourses.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Loaders from '../../components/loaders/Loaders';

export default function PaymentInstallmentConfirmation() {
  const [errorData, setErrorData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const checkPayment = async () => {
    let get: any = await studentInstallmentsmakePaymentStatus(
      location.pathname.split('/')[5],
      location.pathname.split('/')[4]
    );
    if (get?.message?.code === 'PAYMENT_SUCCESS') {
      setLoading(true);
      navigate('/student/dashboard');
    } else {
      setErrorData(get?.message);
    }
  };
  useEffect(() => {
    checkPayment();
  }, []);
  return loading ? (
    <>
      <div className="w-[100vw] h-[100vh]  flex items-center justify-center flex-col gap-3">
        <FaTimes className="text-[10rem] border-red-500 border-2  rounded-full p-2 text-red-500" />
        <p className=" font-bold text-red-600  text-2xl">
          Your payment failed!
        </p>
        <button
          onClick={() => navigate('/student/dashboard')}
          className="bg-purple-600 text-white px-5 py-3 rounded-xl text-xl"
        >
          Dashboard
        </button>
      </div>
    </>
  ) : (
    <>
      <div className="w-[100vw] h-[100vh] bg-gray-300 flex items-center justify-center flex-col gap-3">
        <FaCheck className="text-[10rem] border-green-500 border-2  rounded-full p-2 text-green-500" />
        <p className=" font-bold text-green-600  text-2xl">
          Payment has been paid successfully
        </p>
        <p className="text-red-600 text-lg">Redirecting to Dashboard....</p>
        <button
          onClick={() => navigate('/student/dashboard')}
          className="bg-purple-600 text-white px-5 py-3 rounded-xl text-xl"
        >
          Dashboard
        </button>
      </div>
    </>
  );
}
