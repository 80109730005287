import React, { useEffect, useState } from 'react';
import BreadcrumbBack from '../../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../../components/button/CustomButton';
import { useLocation, useNavigate } from 'react-router-dom';
import PaginationDataGrid from '../../../../../components/tables/PaginationDataGrid';
import Models from '../../../../../components/models/Models';
import VideoSubModuleCE from '../VideoSubModuleCE';
import { randomNumber } from '../../../../../helpers/helpers';
import { Helmet } from 'react-helmet-async';

export default function VideoNSubModules() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [mId, setMId] = useState();
  const [sId, setSId] = useState();
  const location = useLocation();
  let moduleId =
    location.pathname.split('/')[location.pathname.split('/').length - 2];
  let subModulesId =
    location.pathname.split('/')[location.pathname.split('/').length - 1];
  const [change, setChange] = useState<any>(null);
  let mod = {
    module: moduleId,
    fk_videosubmodule_id: subModulesId,
  };
  console.log(location.pathname);
  console.log(change);
  useEffect(() => {
    setChange(randomNumber(100));
  }, [setChange, location]);

  return (
    <>
      <Helmet>
        <title>{` Videos   | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title="Video Sub Modules">
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Create New SubModule"
          icon={true}
          iconName="create"
        />
      </BreadcrumbBack>

      <div className="my-2">
        <PaginationDataGrid
          name="AdminVideoNSubModules"
          refresh={change}
          id={subModulesId}
          dataId={moduleId}
        />
      </div>
      <Models name="Create New SubModule" isOpen={open} isClose={setOpen}>
        <VideoSubModuleCE type="create" isOpen={setOpen} data={mod} />
      </Models>
    </>
  );
}
