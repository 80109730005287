import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { adminQuestionsTypes } from '../../../../types/AdminTypes';
import { toast } from 'react-toastify';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import SearchableSelect from '../../../../components/form/SearchableSelect';
import TinyMiceEditor from '../../../../editor/TinyMiceEditor';
import FormButton from '../../../../components/button/FormButton';
import TinyMiceLiteEditor from '../../../../editor/TinyMiceLiteEditor';
import {
  createAdminTestSeriesQuestion,
  editAdminTestSeriesQuestion,
  getTestTypes,
  showAdminTestSeriesSubQuestion,
} from '../../../../service/admin/adminTestSeries.service';
import { statusList, toBoolean } from '../../../../helpers/helpers';
import { Helmet } from 'react-helmet-async';

export default function AdminQuestionCE() {
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [testType, setTestType] = useState([]);
  const [pageContent, setPageContent] = useState({
    button: 'Create Question',
    title: 'Create Question',
  });
  const [initalEditor, setInitalEditor] = useState<any>(undefined);

  const [question, setQuestion] = useState<any>(undefined);
  const [opt1, setOpt1] = useState<any>(undefined);
  const [opt2, setOpt2] = useState<any>(undefined);
  const [opt3, setOpt3] = useState<any>(undefined);
  const [opt4, setOpt4] = useState<any>(undefined);
  const [description, setDescription] = useState<any>(undefined);
  const [explaination, setExplaination] = useState<any>(undefined);
  const location = useLocation();
  const locationLength = location.pathname.split('/').length;
  let urlParams: any = new URLSearchParams(location.search);
  let pageType = location.pathname.split('/')[locationLength - 2];
  let moduleId = location.pathname.split('/')[locationLength - 1];
  let questionid: any = '';
  if (locationLength === 8) {
    pageType = location.pathname.split('/')[locationLength - 3];
    moduleId = location.pathname.split('/')[locationLength - 2];
    questionid = location.pathname.split('/')[locationLength - 1];
  }
  const navigate = useNavigate();

  const fetchTestTypes = async () => {
    const get: any = await getTestTypes();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setTestType(data);
    }
  };
  const fetchQuestionDetail = async (id: string) => {
    const get: any = await showAdminTestSeriesSubQuestion(id);
    if (get?.status === 'success') {
      setFetchData(get?.message);
      setQuestion(get.message.question);
      setOpt1(get?.message?.testSeriesOptions[0].option);
      setOpt2(get?.message?.testSeriesOptions[1].option);
      setOpt3(get?.message?.testSeriesOptions[2].option);
      setOpt4(get?.message?.testSeriesOptions[3].option);
      setExplaination(get?.message?.explaination);
      setDescription(get?.message?.description);
    }
  };
  useEffect(() => {
    fetchTestTypes();
    if (pageType === 'edit') {
      fetchQuestionDetail(questionid);
      setPageContent({
        button: 'Edit Question',
        title: 'Edit Question',
      });
    }
  }, []);
  const { handleSubmit, control } = useForm<adminQuestionsTypes>({
    values: {
      fk_testType_id: fetchData?.testtype?.id,
      fk_testSubModules_id: moduleId,
      chapter: fetchData?.chapter && fetchData.chapter,
      answer: `${fetchData?.answer}`,
      subject: fetchData?.subject && fetchData?.subject,
      description: fetchData?.description,
      video_link: fetchData?.video_link && fetchData?.video_link,
      explaination: fetchData?.explaination,
      status: fetchData?.status === true ? 'true' : 'false',
    },
  });
  const onSubmit: SubmitHandler<adminQuestionsTypes> = async (data: any) => {
    console.log(data?.status);
    setLoading(true);
    if (question === undefined || question === null) {
      return toast.error('question required');
    }
    if (opt1 === undefined || opt1 === null) {
      return toast.error('Option 1 required');
    }
    if (opt2 === undefined || opt2 === null) {
      return toast.error('Option 2 required');
    }
    if (opt3 === undefined || opt3 === null) {
      return toast.error('Option 3 required');
    }
    if (opt4 === undefined || question === null) {
      return toast.error('Option 4 required');
    }
    let sendingData: any = {
      fk_testType_id: data.fk_testType_id.value,
      fk_testSubModules_id: moduleId,
      chapter: data.chapter,
      question: question,
      answer: data.answer.value,
      subject: data.subject,
      description: description,
      video_link: data.video_link,
      explaination: explaination,
      status: toBoolean(data.status?.value),
      option: [opt1, opt2, opt3, opt4],
    };
    if (pageType === 'edit') {
      sendingData.fk_testType_id = data.fk_testType_id.value
        ? data.fk_testType_id.value
        : fetchData.testtype?.id;
      sendingData.fk_testSubModules_id = moduleId
        ? moduleId
        : fetchData?.testSubModules?.id;
      sendingData.answer = data.answer.value
        ? data.answer.value
        : fetchData?.answer;
      sendingData.status = true;
    }
    let fetch: any;
    if (pageType === 'create') {
      fetch = await createAdminTestSeriesQuestion(sendingData);
    }
    if (pageType === 'edit') {
      fetch = await editAdminTestSeriesQuestion(fetchData?.id, sendingData);
    }
    if (fetch?.status === 'success') {
      setLoading(false);
      navigate(`/admin/test-series/submodules/questions/${moduleId}`);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{` Test Series   | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack
        title={`${pageContent.title} for ${urlParams.get('name')} `}
      ></BreadcrumbBack>
      <div className="my-4">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <SearchableSelect
                name="fk_testType_id"
                control={control}
                options={testType}
                rules={formValidationRules.required()}
                label="Select Question Type"
                placeholder="select question type"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <Input
                name="chapter"
                control={control}
                label="Chapter"
                placeholder="chapter"
              />
            </div>
            {/* <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <Input
                name="subject"
                control={control}
                label="Subject"
                placeholder="Subject"
              />
            </div> */}
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <label className={`  'text-gray-600 dark:text-gray-400'} ml-1`}>
                Question <sup className="text-red-500">*</sup>
              </label>
              <TinyMiceEditor input={question} output={setQuestion} />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <label className={`  'text-gray-600 dark:text-gray-400'} ml-1`}>
                Option 1 <sup className="text-red-500">*</sup>
              </label>
              <TinyMiceLiteEditor input={opt1} output={setOpt1} />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <label className={`  'text-gray-600 dark:text-gray-400'} ml-1`}>
                Option 2 <sup className="text-red-500">*</sup>
              </label>
              <TinyMiceLiteEditor input={opt2} output={setOpt2} />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <label className={`  'text-gray-600 dark:text-gray-400'} ml-1`}>
                Option 3 <sup className="text-red-500">*</sup>
              </label>
              <TinyMiceLiteEditor input={opt3} output={setOpt3} />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <label className={`  'text-gray-600 dark:text-gray-400'} ml-1`}>
                Option 4 <sup className="text-red-500">*</sup>
              </label>
              <TinyMiceLiteEditor input={opt4} output={setOpt4} />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <SearchableSelect
                name="answer"
                control={control}
                options={[
                  { label: 'Option 1', value: '1' },
                  { label: 'Option 2', value: '2' },
                  { label: 'Option 3', value: '3' },
                  { label: 'Option 4', value: '4' },
                ]}
                rules={formValidationRules.required()}
                label="Select Correct Answer"
                placeholder="select correct answer"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <Input
                name="video_link"
                control={control}
                label="Video URL"
                placeholder="url"
              />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <label className={`  'text-gray-600 dark:text-gray-400'} ml-1`}>
                Question Explanation
              </label>
              <TinyMiceEditor input={explaination} output={setExplaination} />
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <label className={`  'text-gray-600 dark:text-gray-400'} ml-1`}>
                Question Description
              </label>
              <TinyMiceEditor input={description} output={setDescription} />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-12">
            <SearchableSelect
              name="status"
              control={control}
              options={statusList}
              rules={formValidationRules.required()}
              label="Select status"
              placeholder="select status"
            />
          </div>
          <div className="my-2">
            <FormButton loading={loading} title={pageContent.button} />
          </div>
        </form>
      </div>
    </>
  );
}
