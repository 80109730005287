import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { FaSignInAlt } from 'react-icons/fa';

export default function LoginButton(props: {
  icon: boolean;
  title: string;
  loading: boolean;
}) {
  return (
    <button
      disabled={props.loading}
      className={`${props.loading ? 'hover-purple-gradient' : 'purple-gradient'} px-3 py-2 rounded-xl  text-white flex gap-2 items-center  justify-center`}
    >
      {props.loading ? (
        <CircularProgress
          sx={{
            color: 'white',
          }}
          size={16}
        />
      ) : props.icon ? (
        props.title === 'Login' ? (
          <FaSignInAlt />
        ) : null
      ) : null}
      {props.title}
    </button>
  );
}
