import instance from './instace';

export const logoutService = async () => {
  try {
    const get: any = await instance.post('/api/logout');
    return get.data;
  } catch (e) {
    return false;
  }
};
export const StateService = async () => {
  try {
    const get: any = await instance.get('/api/location/states');
    return get.data;
  } catch (e) {
    return false;
  }
};
export const CityService = async (stateId: string) => {
  try {
    const get: any = await instance.get(`/api/location/cities/${stateId}`);
    return get.data;
  } catch (e) {
    return false;
  }
};

export const getActiveGendersService = async () => {
  try {
    const get: any = await instance.get(`/api/public/genders`);
    return get.data;
  } catch (e) {
    return false;
  }
};
export const getActiveCenters = async () => {
  try {
    const get: any = await instance.get(
      '/api/public/centers/abd22025-a680-466b-9b4e-8fc2eb16ad3a'
    );
    return get.data;
  } catch (e) {
    return false;
  }
};
