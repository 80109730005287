import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  BatchStuSubsciptionTypes,
  studentPaymentTypes,
} from '../../../types/AdminTypes';
import { getCourseExcludeBatch } from '../../../service/admin/adminBatch.service';
import MultiSearchableSelect from '../../../components/form/MultiSearchableSelect';
import formValidationRules from '../../../helpers/formvalidationRules';
import FormButton from '../../../components/button/FormButton';
import Input from '../../../components/form/Input';
import SearchableSelect from '../../../components/form/SearchableSelect';
import moment from 'moment';
import SimpleTable from '../../../components/tables/SimpleTable';
import {
  createInvocePayments,
  getpaymentsHistory,
  getStudentCourses,
  payStudentCourseInstallments,
} from '../../../service/admin/adminStudent.service';
import { toast } from 'react-toastify';
import { toBoolean } from '../../../helpers/helpers';
import { FaClock, FaRubleSign, FaRupeeSign } from 'react-icons/fa';
import { getUserCourseInstallments } from '../../../service/admin/adminCourses.service';
import Models from '../../../components/models/Models';
import TextArea from '../../../components/form/TextArea';
import Loaders from '../../../components/loaders/Loaders';

export default function AdminPaymentInstallments(props: {
  data?: any;
  open: any;
}) {
  const [loading, setLoading] = useState(false);
  const [installmentLoading, setInstallationLoading] =useState(false)
  const [courseList, setCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState<any>(null);
  const [instAmount, setInstAmount] = useState<any>(0);
  const [pendingInstAmount, setPendingInstAmount] = useState(0);
  const [admissionDate, setAdmissionDate] = useState<any>();
  const [lastPayment, setLastPayment] = useState<any>();
  const [tableRow, setTableRow] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
   const [nextPayment, setNextPayment] = useState<any>();
  const [activetab, setActiveTab] = useState({
    id: 1,
    name: 'History',
    title: 'history',
  });
  const paymentModes= [
    {label:"CASH",value:"CASH"},
    {label:"UPI",value:"UPI"},
    {label:"CHEQUE",value:"CHEQUE"},
    {label:"CREDIT_CARD",value:"CREDIT_CARD"},
    {label:"DEBIT_CARD",value:"DEBIT_CARD"},
  ]
  const [historyList, setHistoryList] = useState<any>([]);

  const fetchBatches = async (stu: string) => {
    const get: any = await getStudentCourses(stu);
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({
          value: item.course.id,
          label: item.course.name,
          subId: item.id,
        });
      });
      setCourseList(data);
    }
  };
  const fetchpaymentHistory = async (courseId: string, studentId: string) => {
    const get: any = await getpaymentsHistory(courseId, studentId);
    if (get?.status === 'success') {
      setHistoryList(get?.message);
      if (get?.message?.stundetPayemts?.length > 0) {
        let tbdata: any = [];
        get?.message?.stundetPayemts.map((item: any, index: number) => {
          if (index === 0) {
            setAdmissionDate(moment(item.date).format('YYYY-MM-DD'));
          }
          if (get?.message?.stundetPayemts?.length === index + 1) {
            setLastPayment({
              paid: item.paid,
              remaining: item.remaining,
            });
          }
          tbdata.push([
            index + 1,
            item?.inv_number,
            item.paid,
            moment(item.date).format('YYYY-MM-DD'),
            item?.adminPaymentMaster ? item?.adminPaymentMaster.name : item?.payment_method,
            item?.complete ? 'Yes' : 'No',
            item.remark ? item.remark : '-',

          ]);
        });
        setTableRow(tbdata);
      }
    }
  };
  useEffect(() => {
    fetchBatches(props.data?.stu);
    userCourseInstallmets()
  }, []);
  useEffect(() => { 
    if (selectedCourse?.value) {
      if(selectedCourse?.value){
        fetchpaymentHistory(selectedCourse?.value, props.data?.stu);
      }
    }
  }, [setSelectedCourse, selectedCourse]);
  const { handleSubmit, control } = useForm<any>();
  const onSubmit: SubmitHandler<any> = async (
    data: any
  ) => {
    setLoading(true);
        let cr:any = await payStudentCourseInstallments(props.data?.stu,nextPayment,data.mode?.value,data.remark)
          if(cr?.status === "success"){
             fetchpaymentHistory(selectedCourse?.value, props.data?.stu);
             userCourseInstallmets()
            setLoading(false);
            setModelOpen(false)
            // props.open(false)
            return toast.success(cr.message)
          }
    setLoading(false);
  };
  const tablist = [
    { id: 1, name: 'History', title: 'history' },
    {id:2,name:"Installments", title: 'installments'}
  ];

  const userCourseInstallmets = async()=>{
    setInstallationLoading(true)
    if(selectedCourse?.value) {
      
      const get:any = await getUserCourseInstallments(props.data?.stu,selectedCourse?.value);
      if(get?.status==="success"){
        setInstallationLoading(false)
        setInstallments(get?.message?.user_course_installments);
        let fl:any = get?.message?.user_course_installments.filter((i:any)=>i.paid === false)
        if(fl.length > 0){
          setNextPayment(fl[0].id)
        }
      }
      setInstallationLoading(false)
    }
    setInstallationLoading(false)
  }
  
  const handleInstallmentsClick =()=>{
     userCourseInstallmets()
     
  }
  const historCols = [
    'id',
    'Invoice Number',
    'Paid Amount',
    'Date',
    'Method',
    'complete',
    'Remark'
  ];
  const handleInstallmentsClicked = (e:any)=>{
    if(e.id === 2){
      handleInstallmentsClick()
    }
    setActiveTab(e)
  }
  const handleCourseChange = async(e:any)=>{
    setSelectedCourse(e)
    fetchpaymentHistory(e?.value, props.data?.stu);
    userCourseInstallmets()
  }
  return (
    <>
      <div className="mb-2 min-h-[180px]">
        <div className="grid grid-cols-12 gap-2 place-content-center ">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 ">
            <SearchableSelect
              name="fk_course_id"
              control={control}
              options={courseList}
              onFetch={handleCourseChange}
              rules={formValidationRules.required()}
              label="Select Courses"
              placeholder="Search.."
            />
          </div>

          <div className="col-span-12">
            {historyList && selectedCourse ? (
              <>
                <hr />
                <div className="bg-white p-3 rounded-lg my-2">
                  <div className="flex flex-row gap-2 items-center justify-between">
                    <div className="flex flex-row items-center gap-2">
                      <b>Admission Date :</b>{' '}
                      <p className="flex flex-row items-center">
                        {admissionDate}
                      </p>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <b> Expiry Date :</b>{' '}
                      {moment(historyList?.expiry_date).format('YYYY-MM-DD')}
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <b> Total Amount :</b>{' '}
                      <p className="flex flex-row items-center">
                        {historyList?.course_amount}
                      </p>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <b> Installments :</b>{' '}
                      <p className="flex flex-row items-center">
                        {historyList?.is_emi ? 'Yes' : 'No'}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div>
          {selectedCourse ? (
            tablist.length > 0 ? (
              <>
                <div className="flex flex-row gap-4 items-center bg-gray-300 p-2 ">
                  {tablist.map((e: any) => (
                     
                    <>
                      <button
                        onClick={() => handleInstallmentsClicked(e)}
                        className={`${
                          activetab.id === e.id
                            ? 'hover-purple-gradient text-white'
                            : 'bg-gray-200 dark:bg-secondary'
                        } p-2 font-semibold rounded-md hover:shadow-xl`}
                      >
                        {e.name}
                      </button>
                    </>
                  ))}
                </div>
              </>
            ) : null
          ) : null}
          {selectedCourse ? (
            activetab.id === 1 ? (
              tablist.length > 0 ? (
                <div className="bg-gray-300 p-2">
                  <ul>
                    <SimpleTable rows={tableRow} cols={historCols} />
                  </ul>
                </div>
              ) : null
            ) : null
          ) : null}
         
          {
            activetab.id === 2 ? 
            historyList?.is_emi  ? 
            installmentLoading ? 
            <Loaders type='spinner' />
            :
            <>
            <div className="relative overflow-x-auto rounded-lg  max-h-[300px] min-h-[100px]">
          <table className="w-full   text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs w-full rounded-lg text-gray-200 uppercase  bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
            <tr>
            <th scope="col" className="py-2 px-2 text-center">
                        Installment Number
                      </th>
                      <th scope="col" className="py-2 px-2 text-center">
                        Installment Amount
                      </th>
                      <th scope="col" className="py-2 px-2 text-center">
                        Expiry Date
                      </th>
                      <th scope="col" className="py-2 px-2 text-center">
                        Paid
                      </th>
            </tr>
            </thead>
            <tbody>
               {installments.map(
                                           (item: any, index: number) => (
                                             <tr
                                               key={index}
                                               className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 text-center even:dark:bg-gray-800 border-b dark:border-gray-700"
                                             >
                                               <td className={`px-2 py-2 text-center ${item?.paid ? 'bg-green-600 text-white' : ''}`}>
                                                 {item.installment_number}
                                               </td>
                                               <td className={`px-2 py-2 text-center ${item?.paid ? 'bg-green-600 text-white' : ''}`}>
                                                 {item.installment_amount}
                                               </td>
                                               <td className={`px-2 py-2 text-center ${item?.paid ? 'bg-green-600 text-white' : ''}`}>
                                                 {moment(item.expiry_date).format('DD MMM YYYY')}
                                               </td>
                                               <td className={`px-2 py-2 text-center ${item?.paid ? 'bg-green-600 text-white' : ''}`}>
                                                 {item.paid
                                                   ? 'Yes'
                                                   : 
                                                     nextPayment === item.id  ? <button onClick={(()=>setModelOpen(!modelOpen))}  className='px-6 py-1 bg-purple2 text-white rounded-lg'>Pay</button> : 'Remaining'
                                                   }
                                               </td>
                                             </tr>
                                           )
                                         )}
            </tbody>
            </table>
            </div>
                </>
            :
            <div className='h-[80px] flex bg-gray-100 justify-center items-center border border-gray-700 border-dashed rounded-2xl'>
              <p className='text-red-500 '>Installments are not available for this course</p>
            </div>
            :null
          }
        </div>
      </div>

       <Models
                width="w-[40vw]"
                name={`Pay Installments`}
                isOpen={modelOpen}
                isClose={setModelOpen}
              >
               <form onSubmit={handleSubmit(onSubmit)}>
               <div className="row">
               <div className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">
            <SearchableSelect
              name="mode"
              control={control}
              options={paymentModes}
              rules={formValidationRules.required()}
              label="Payment Mode"
              placeholder="Search.."
            />
          </div>
          <div className=" col-span-12">
          <TextArea
              name="remark"
              type="text"
              control={control}
              label="Remarks"
              defaultValue=""
              placeholder="remarks"
            />
          </div>
          <div className="mt-3 w-full col-span-12">
            <FormButton loading={loading} title={'Pay'} />
          </div>
          </div>
               </form>
              </Models>
    </>
  );
}
