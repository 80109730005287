import React, { useEffect, useState } from 'react';
import SideBar from '../../components/nav/SideBar';
import { Outlet } from 'react-router-dom';
import Top from '../../components/nav/Top';
import { useSelector } from 'react-redux';

export default function Admins() {
  const sidebarOpen = useSelector((state: any) => state.commonSlice);

  return (
    <>
      <SideBar data="admins" />
      <Top />
      <div
        className={`${sidebarOpen ? 'pl-[0%] sm:pl-[0%]  md:pl-[25%] lg:pl-[20%] ' : ' pl-[0%] sm:pl-[0%] md:pl-[10%] lg:pl-[8%]'}  pt-[8vh] sm:pt-[11vh] md:pt-[12vh]  text-dark dark:text-white mx-3 bg-gray-100 dark:bg-dark-purple  min-h-[100vh] `}
      >
        <Outlet />
      </div>
    </>
  );
}
