import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function BreadCrumb(props: { routes: any }) {
  const [routes, setRoutes] = useState<any>([{ name: 'home', link: '/' }]);
  useEffect(() => {
    if (props.routes) {
      setRoutes(props.routes);
    }
  }, [props.routes]);
  const navigate = useNavigate();
  return (
    <div className="flex flex-row gap-2 mb-5 items-center">
      {routes.length > 0 && (
        <>
          <FaHome
            onClick={() => navigate('/student/dashboard')}
            className={`text-gray-500  hover:cursor-pointer`}
          />
          {routes.map((e: any, index: number) => (
            <div key={index}>
              <p
                onClick={() =>
                  index + 1 !== routes.length ? navigate(e.link) : null
                }
                className={`${
                  index + 1 === routes.length
                    ? 'text-gray-900 font-bold text-lg'
                    : 'text-gray-500  hover:cursor-pointer'
                }`}
              >
                {' '}
                / {e.name}
              </p>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
