import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  AdminVimeoModulesTypes,
  AdminVimeoSubModulesTypes,
} from '../../../../types/AdminTypes';
import { setContentUpdate } from '../../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import Input from '../../../../components/form/Input';
import formValidationRules from '../../../../helpers/formvalidationRules';
import RadioButton from '../../../../components/form/RadioButton';
import { statusList, toBoolean } from '../../../../helpers/helpers';
import FormButton from '../../../../components/button/FormButton';
import {
  createAdminVideosModule,
  createAdminVideosSubModule,
  editAdminVideosModule,
  editAdminVideosSubModule,
  getVideosActiveModule,
  showAdminVideosModule,
  showAdminVideosSubModule,
} from '../../../../service/admin/adminVideoes.service';
import SearchableSelect from '../../../../components/form/SearchableSelect';

export default function VideoSubModuleCE(props: {
  type: string;
  isOpen: any;
  data?: any;
}) {
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [moduleList, setModuleList] = useState([]);

  const [subModuleType, setSubModuleType] = useState(false);
  const [pageContent, setPageContent] = useState({
    button: 'Create New SubModule',
    title: 'Create SubModule',
  });
  const dispatch = useDispatch();
  const fetchDa = async (id: string) => {
    let get: any = await showAdminVideosSubModule(id);
    if (get?.status === 'success') {
      setFetchData(get?.message);
    }
  };
  // const fetchModule = async()=>{
  //     const get:any = await getVideosActiveModule();
  //     if(get?.status === "success"){
  //         let data:any = [];
  //   get.message.map((item:any)=>{
  //     data.push({value:item.id,label:item.name})
  //   })
  //   setModuleList(data)
  //       }
  // }
  useEffect(() => {
    // fetchModule();
    if (props.type === 'edit') {
      fetchDa(props.data.id);
      setPageContent({
        button: 'Update  SubModule',
        title: 'Update SubModule',
      });
    }
  }, []);
  const { handleSubmit, control } = useForm<AdminVimeoSubModulesTypes>({
    values: {
      name: fetchData?.name,
      status: fetchData?.status
        ? fetchData?.status === true
          ? 'true'
          : 'false'
        : 'true',
      restriction: fetchData?.attempt,
      types: `${fetchData?.types === 'file' ? 'file' : null}`,
      // fk_module_id:fetchData?.attempt
    },
  });

  const onSubmit: SubmitHandler<AdminVimeoSubModulesTypes> = async (
    data: any
  ) => {
    setLoading(true);
    let fetch: any;
    data.status = data.status ? toBoolean(data.status) : fetchData?.status;

    data.types = data.types?.value;
    data.restriction = 99;
    if (props.type === 'create') {
      console.log(props.data);
      data.fk_videosubmodule_id = props.data?.fk_videosubmodule_id;
      data.fk_module_id = props.data?.module;
      fetch = await createAdminVideosSubModule(data);
    }
    if (props.type === 'edit') {
      data.fk_submodule_id = props.data?.id;
      fetch = await editAdminVideosSubModule(props.data.id, data);
    }
    if (fetch?.status === 'success') {
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      setLoading(false);
      props.isOpen(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };
  const handleFilesType = (data: any) => {
    if (data?.value === 'dir') {
      setSubModuleType(false);
    } else {
      setSubModuleType(true);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <Input
          name="name"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Sub Module Name"
          placeholder="sub module name"
        />
        {/* <SearchableSelect
        name="fk_module_id"
        isClear={true}
        control={control}
        options={moduleList}
        label="Select Module"
        placeholder="Search.."
      /> */}

        <SearchableSelect
          name="types"
          control={control}
          options={[
            { value: 'dir', label: 'Folder' },
            { value: 'file', label: 'File' },
          ]}
          onFetch={handleFilesType}
          rules={formValidationRules.required()}
          label="Select Submodule Type"
          placeholder="Search.."
        />
        <RadioButton
          name="status"
          control={control}
          rules={{ ...formValidationRules.required() }}
          options={statusList}
          label="status"
        />
        <br />
        <FormButton loading={loading} title={pageContent.button} />
      </form>
    </>
  );
}
