import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mediaEndPoint } from '../service/instace';
const TinyMiceLiteEditor = (props: { input: any; output: any }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={props.input}
      config={{
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
          '|',
          'insertTable',
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          '|',
          'undo',
          'redo',
          '|',
          'superscript',
          'subscript',
          '|',
          'imageUpload',
          'mediaEmbed',
          'imageResize',
        ],
        extraPlugins: [MyCustomUploadAdapterPlugin],
        initialData: '',
      }}
      onReady={(editor: any) => {
        // Apply additional styling
        editor.ui.view.editable.element.style.height = '400px';
      }}
      onChange={(event: any, editor: any) => {
        const data = editor.getData();
        props.output(data);
      }}
    />
  );
};
function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new MyUploadAdapter(loader);
  };
}

// Custom Upload Adapter
class MyUploadAdapter {
  loader: any;
  url: string;

  constructor(loader: any) {
    this.loader = loader;
    this.url = `${mediaEndPoint}/api/image/upload/tinymice`; // Your backend upload URL
  }

  upload(): Promise<{ default: string }> {
    return this.loader.file.then(
      (file: File) =>
        new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append('upload', file);

          fetch(this.url, {
            method: 'POST',
            body: formData,
          })
            .then((response) => response.json())
            .then((result) => {
              if (result && result.url) {
                resolve({ default: result.url }); // Pass the image URL to CKEditor
              } else {
                reject(
                  result && result.message ? result.message : 'Upload failed'
                );
              }
            })
            .catch((error) => reject(error));
        })
    );
  }

  abort() {
    // Optional: handle upload abort
  }
}
export default TinyMiceLiteEditor;
