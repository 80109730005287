import React, { useEffect, useState } from 'react';
import {
  createAdminBatch,
  editAdminBatch,
  showAdminBatch,
} from '../../../service/admin/adminBatch.service';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  adminExamAlertsTypes,
  adminPromotionalImageTypes,
  BatchTypes,
} from '../../../types/AdminTypes';
import { statusList, toBoolean } from '../../../helpers/helpers';
import { setContentUpdate } from '../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Input from '../../../components/form/Input';
import formValidationRules from '../../../helpers/formvalidationRules';
import RadioButton from '../../../components/form/RadioButton';
import FormButton from '../../../components/button/FormButton';
import {
  createAdminExamAlert,
  editAdminExamAlert,
  showAdminExamAlert,
} from '../../../service/admin/adminExamAlert.service';
import DropZoneUploadImages from '../../../components/dropzone/DropZoneUploadImages';
import {
  createAdminFeed,
  editAdminFeed,
  getAdminFeedTypes,
  showAdminFeed,
} from '../../../service/admin/adminFeed.service';
import SearchableSelect from '../../../components/form/SearchableSelect';
import { createAdminpromotional } from '../../../service/admin/adminPromotional.service';
export default function AdminPromotionalCE(props: {
  type: string;
  data?: any;
  open: any;
}) {
  const [initalEditor, setInitalEditor] = useState<any>(undefined);
  const [pageLoad, setPageLoad] = useState(false);
  const [url, setUrl] = useState<any>();
  const [checkUploadedFilesStatus, setCheckUploadedFilesStatus] =
    useState<any>(false);
  const [fetchData, setFetchData] = useState<any>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedType, setFeedType] = useState([]);
  const [selectedFeedType, setSelectedFeedType] = useState('0');
  const [pageContent, setPageContent] = useState({
    button: 'Create Feed',
  });

  const dispatch = useDispatch();
  const fetchFeedTypes = async () => {
    const get: any = await getAdminFeedTypes();
    if (get?.status === 'success') {
      let data: any = [];
      get.message.map((item: any) => {
        data.push({ value: item.id, label: item.name });
      });
      setFeedType(data);
    }
  };
  const fetchBatchDetail = async (id: string) => {
    const get: any = await showAdminFeed(id);
    if (get?.status === 'success') {
      // setUrl(get.message?.image)
      setFetchData(get.message);
      setSelectedFeedType(get?.message.feedsType?.name);
      setUrl(get.message?.url);
      setInitalEditor(get.message?.name);
    }
  };
  useEffect(() => {
    setPageLoad(true);
    fetchFeedTypes();
    if (props.type === 'edit') {
      fetchBatchDetail(props.data.id);
      setPageContent({
        button: 'Update Feed',
      });
    }
    // fetchInstituteCenter()
    setPageLoad(false);
  }, []);

  const { handleSubmit, control, reset } = useForm<adminPromotionalImageTypes>({
    values: {
      type: fetchData?.type ? fetchData?.type : '0',
      url: fetchData?.url,
      status: `${fetchData?.status ? fetchData?.status : true}`,
    },
  });
  const onSubmit: SubmitHandler<adminPromotionalImageTypes> = async (
    data: any
  ) => {
    setLoading(true);
    let status_: any = toBoolean(data.status);
    let fetch: any;
    data.status = status_;
    data.type = data.fk_feedsType_id.value
      ? data.fk_feedsType_id.value
      : fetchData?.type;
    if (url) {
      data['url'] = url;
      console.log(url);
    }
    if (data.urls) {
      data['url'] = data.urls;
    }
    let sendingData: any = {
      url: data.url,
      type: data.type,
      status: status_,
    };
    if (props.type === 'create') {
      fetch = await createAdminpromotional(sendingData);
    }
    if (props.type === 'edit') {
      fetch = await editAdminFeed(fetchData?.id, sendingData);
    }
    if (fetch?.status === 'success') {
      setLoading(false);
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      props.open(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
    reset();
  };
  const handleFeedType = (e: any) => {
    setSelectedFeedType(e.value);
  };

  return (
    <>
      <div className="my-2">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
          <SearchableSelect
            name="fk_feedsType_id"
            onFetch={handleFeedType}
            control={control}
            options={[
              { label: 'Image', value: '0' },
              { label: 'Video', value: '1' },
            ]}
            rules={formValidationRules.required()}
            label="Feed Type"
            placeholder="select"
          />
          <div className="my-2"></div>
          {selectedFeedType === '0' && (
            <div className="my-2">
              <DropZoneUploadImages
                heigth="min-h-[20vh]"
                title="Upload  Image"
                maxFile={1}
                data={setUrl}
                url={url}
                status={setCheckUploadedFilesStatus}
              />
            </div>
          )}
          {selectedFeedType === '1' && (
            <div className="my-2">
              <Input
                name="urls"
                control={control}
                rules={{
                  ...formValidationRules.required(),
                }}
                label="Url"
                placeholder="url"
              />
            </div>
          )}
          <RadioButton
            name="status"
            control={control}
            rules={{ ...formValidationRules.required() }}
            options={statusList}
            label="status"
          />
          <br />
          <FormButton loading={loading} title={pageContent.button} />
        </form>
      </div>
    </>
  );
}
