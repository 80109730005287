import React, { useEffect, useState } from 'react';
import { AdminVimeoAccountTypes } from '../../../../../types/AdminTypes';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from '../../../../../components/form/Input';
import formValidationRules from '../../../../../helpers/formvalidationRules';
import FormButton from '../../../../../components/button/FormButton';
import {
  createAdminVimoeAccount,
  editAdminVimoeAccount,
  showAdminVimoeAccount,
} from '../../../../../service/admin/adminVimeo.service';
import { setContentUpdate } from '../../../../../redux/contentUpdateSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

export default function AdminVimeoAccountCE(props: {
  type: string;
  data?: any;
  isOpen: any;
}) {
  const [pageLoad, setPageLoad] = useState(false);
  const [fetchData, setFetchData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState({
    button: 'Create New Account',
    title: 'Create Account',
  });
  const dispatch = useDispatch();
  const fetchDa = async (id: string) => {
    let get: any = await showAdminVimoeAccount(id);
    if (get?.status === 'success') {
      setFetchData(get?.message);
    }
  };
  useEffect(() => {
    if (props.type === 'edit') {
      fetchDa(props.data.id);
      setPageContent({
        button: 'Update  Account',
        title: 'Update Account',
      });
    }
  }, []);

  const { handleSubmit, control } = useForm<AdminVimeoAccountTypes>({
    values: {
      name: fetchData?.name,
      token: fetchData?.token,
      identifier: fetchData?.identifier,
      secret: fetchData?.secret,
    },
  });

  const onSubmit: SubmitHandler<AdminVimeoAccountTypes> = async (data) => {
    setLoading(true);
    let fetch: any;

    if (props.type === 'create') {
      fetch = await createAdminVimoeAccount(data);
    }
    if (props.type === 'edit') {
      fetch = await editAdminVimoeAccount(props.data.id, data);
    }
    if (fetch?.status === 'success') {
      dispatch(
        setContentUpdate({
          id: Math.random() * (100000 - 111111),
        })
      );
      setLoading(false);
      props.isOpen(false);
      return toast.success(fetch.message);
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <Input
          name="name"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Account Name"
          placeholder="account name"
        />
        <Input
          name="token"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Account Token"
          placeholder="account token"
        />
        <Input
          name="identifier"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Account Identifier"
          placeholder="account identifier"
        />
        <Input
          name="secret"
          control={control}
          rules={{
            ...formValidationRules.required(),
            ...formValidationRules.minLength(3),
          }}
          label="Account Secret"
          placeholder="account secret"
        />
        <FormButton loading={loading} title={pageContent.button} />
      </form>
    </>
  );
}
