import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import BreadcrumbBack from '../../../../components/breadcrumb/BreadcrumbBack';
import CustomButton from '../../../../components/button/CustomButton';
import MUITableGrid from '../../../../components/tables/MUITableGrid';
import Models from '../../../../components/models/Models';
import CourseCategoryTab_coursesOrder from './CourseCategoryTab_coursesOrder';
import CourseCategoryTab_courses_add from './CourseCategoryTab_courses_add';

export default function CourseCategoryTab_Courses() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [slugData, setSlugData] = useState<any>();
  const fetchSlugData = async () => {
    // const get =  await getAdminCourseCategoryTab()
    // if(get?.status === "success"){
    //     setSlugData(get.message)
    // }
  };
  useEffect(() => {
    fetchSlugData();
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Course Tabs | ${window.location.hostname}`}</title>
      </Helmet>
      <BreadcrumbBack title={`${query.get('name')}`}>
        <CustomButton
          onClick={() => setOpen(!open)}
          title="Add Courses"
          icon={true}
          iconName="create"
        />
        <CustomButton
          onClick={() => setOpen1(!open1)}
          title="Order"
          bg="bg-purple-900"
          icon={true}
          iconName="star"
        />
      </BreadcrumbBack>
      <div className="my-2">
        <MUITableGrid
          name="AdminCourseTabCourse"
          id={location.pathname.split('/')[5]}
        />
      </div>
      <Models name="Create New Tab" isOpen={open} isClose={setOpen}>
        <CourseCategoryTab_courses_add
          type="create"
          open={setOpen}
          data={{ id: location.pathname.split('/')[5] }}
        />
      </Models>
      <Models
        name="Tabs Order"
        width="md:w-[50vw]"
        isOpen={open1}
        isClose={setOpen1}
      >
        <CourseCategoryTab_coursesOrder id={location.pathname.split('/')[5]} />
      </Models>
    </>
  );
}
